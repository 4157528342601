import React, { useState, useEffect, useRef } from "react";
import { useModal } from "../../contexts/modal";
import serviceMethods from '../../service';
import isEqual from 'lodash.isequal';
import PhoneInputBackend from "../PhoneInputBackend";

const NewEmployeeModal = ({ 
  selectedEmployee, listOfForeman, isLocationGunite, getEmployeesByBranch,
  setNeedToUpdateEmployees
}) => {
  const { modalMethods, modal } = useModal();
  const { isEditing, setIsEditing } = modal; 
  // isEditing == true is really used to signify that a NEW employee is being added.
  // isEditing == false means that an existing employee is being edited.

  const [newEmployee, setNewEmployee] = useState({});
  const [newEmployeeFName, setNewEmployeeFName] = useState('');
  const [newEmployeeLName, setNewEmployeeLName] = useState('');
  const [newEmployeeEmail, setNewEmployeeEmail] = useState('');
  const [newEmployeeRole, setNewEmployeeRole] = useState('');
  const [newEmployeeCrewId, setNewEmployeeCrewId] = useState(null);
  const [newEmployeeGuniteRate, setNewEmployeeGuniteRate] = useState(null);
  const [newEmployeePlasterRate, setNewEmployeePlasterRate] = useState(null);
  const [newEmployeeQuartzRate, setNewEmployeeQuartzRate] = useState(null);
  const [newEmployeePebbleRate, setNewEmployeePebbleRate] = useState(null);
  const [newDriverPoolRate, setNewDriverPoolRate] = useState(null);
  const [newSchedulerPhone, setNewSchedulerPhone] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const isDeletingRef = useRef(isDeleting);
  const [initialSelectedEmployee, setInitialSelectedEmployee] = useState({});
  const [disableEmailField, setDisableEmailField] = useState(false);
  const [employeeEmailAlreadyTaken, setEmployeeEmailAlreadyTaken] = useState(false);
  const [employeeHasAnotherRole, setEmployeeHasAnotherRole] = useState(false);
  const [noForemenOptions, setNoForemenOptions] = useState(false);

  useEffect(()=>{
    const isEmpty = listOfForeman.filter((foreman) => foreman.email !== selectedEmployee.email).length === 0;
    if (isEmpty){
      setNewEmployeeCrewId(null)
    }
    setNoForemenOptions(isEmpty);
  },[selectedEmployee, listOfForeman])

  useEffect(()=>{
    setInitialSelectedEmployee({
      ...selectedEmployee
    })
  },[selectedEmployee])

  useEffect(() => {
    isDeletingRef.current = isDeleting;
  }, [isDeleting]);

  useEffect(()=>{
    const onEnterKeyPress = (event) => {
      if (event.key === 'Enter' && isDeletingRef.current){
        handleDeleteEmployee(event)
      }
    }
    document.addEventListener('keydown', onEnterKeyPress);
    return () => {
      document.removeEventListener('keydown', onEnterKeyPress);
    }
  },[])

  const saveButtonDisabled = () => {
    const employeeObjForComparison = ({
      ...initialSelectedEmployee,
      name: newEmployeeFName + ' ' + newEmployeeLName,
      email: newEmployeeEmail,
      role: newEmployeeRole,
      phone: newSchedulerPhone || null,
      crewId: newEmployeeCrewId,
      gunitePoolRate: newEmployeeGuniteRate ? Number(newEmployeeGuniteRate) : null,
      plasterPoolRate: newEmployeePlasterRate ? Number(newEmployeePlasterRate) : null,
      quartzPoolRate: newEmployeeQuartzRate ? Number(newEmployeeQuartzRate) : null,
      pebblePoolRate: newEmployeePebbleRate ? Number(newEmployeePebbleRate) : null,
      driverRate: newDriverPoolRate ? Number(newDriverPoolRate) : null,
    })
    if (newEmployeeRole === 'Crew Member'){
      if (isLocationGunite()){
        return isEqual(initialSelectedEmployee, employeeObjForComparison)
        || !newEmployeeFName || !newEmployeeLName
        || newEmployeeCrewId === null || !newEmployeeGuniteRate;
      } else {
        return isEqual(initialSelectedEmployee, employeeObjForComparison)
        || newEmployeeCrewId === null || !newEmployeePlasterRate || !newEmployeeQuartzRate
        || !newEmployeePebbleRate || !newEmployeeFName || !newEmployeeLName;
      }
    } else if (newEmployeeRole === 'Scheduler') {
      return isEqual(initialSelectedEmployee, employeeObjForComparison)
      || newSchedulerPhone.length !== 14 || !newEmployeeFName || !newEmployeeLName;
    } else if (newEmployeeRole === 'Driver') {
      return isEqual(initialSelectedEmployee, employeeObjForComparison)
      || !newDriverPoolRate || !newEmployeeFName || !newEmployeeLName;
    } else if (newEmployeeRole === 'Foreman'){
      if (isLocationGunite()){
        return isEqual(initialSelectedEmployee, employeeObjForComparison)
        || !newEmployeeFName || !newEmployeeLName
        || !newEmployeeGuniteRate;
      } else {
        return isEqual(initialSelectedEmployee, employeeObjForComparison)
        || !newEmployeeFName || !newEmployeeLName
        || !newEmployeeQuartzRate
        || !newEmployeePlasterRate || !newEmployeePebbleRate;
      }
    } else {
      return isEqual(initialSelectedEmployee, employeeObjForComparison)
    }
  }

  const addButtonDisabled = () => {
    if (newEmployeeRole === 'Scheduler') {
      return !newEmployeeFName || !newEmployeeLName
      || !newEmployeeEmail || !newEmployeeRole
      || newSchedulerPhone.length !== 14;
    } else if (newEmployeeRole === 'Crew Member' && isLocationGunite()) {
      return !newEmployeeFName || !newEmployeeLName 
      ||!newEmployeeEmail || !newEmployeeRole
      ||!newEmployeeCrewId ||!newEmployeeGuniteRate;
    } else if (newEmployeeRole === 'Crew Member' && !isLocationGunite()) {
      return !newEmployeeFName || !newEmployeeLName 
      ||!newEmployeeEmail || !newEmployeeRole
      ||!newEmployeeCrewId ||!newEmployeePlasterRate
      ||!newEmployeePlasterRate ||!newEmployeeQuartzRate
      ||!newEmployeePebbleRate;
    } else if (newEmployeeRole === 'Driver'){
      return !newEmployeeFName || !newEmployeeLName
      ||!newEmployeeEmail || !newEmployeeRole
      ||!newDriverPoolRate;
    } else if (newEmployeeRole === 'Foreman'){
      if (isLocationGunite()){
        return !newEmployeeFName ||!newEmployeeLName
        ||!newEmployeeGuniteRate||!newEmployeeEmail
      } else {
        return !newEmployeeFName ||!newEmployeeLName
        ||!newEmployeeEmail || !newEmployeeQuartzRate
        ||!newEmployeePlasterRate ||!newEmployeePebbleRate;
      }
    } else {
      return true;
    }
  }

  useEffect(()=>{
    if (isEditing){
      const nameToSplit = selectedEmployee?.name?.split(' ') || '';
      const firstName = nameToSplit[0];
      const lastName = nameToSplit[1];
      setNewEmployeeFName(firstName)
      setNewEmployeeLName(lastName)
      setNewEmployeeEmail(selectedEmployee?.email)
      setNewEmployeeRole(selectedEmployee?.role)
      if (noForemenOptions){
        setNewEmployeeCrewId(null)
      } else {
        setNewEmployeeCrewId(selectedEmployee?.crewId || null)
      }
      setNewEmployeeGuniteRate(selectedEmployee?.gunitePoolRate)
      setNewEmployeePlasterRate(selectedEmployee?.plasterPoolRate)
      setNewEmployeeQuartzRate(selectedEmployee?.quartzPoolRate)
      setNewEmployeePebbleRate(selectedEmployee?.pebblePoolRate)
      setNewDriverPoolRate(selectedEmployee?.driverRate)
      setNewSchedulerPhone(selectedEmployee?.phone || '')
      setDisableEmailField(selectedEmployee?.email.length)
    } else {
      setNewEmployeeFName('')
      setNewEmployeeLName('')
      setNewEmployeeEmail('')
      setNewEmployeeRole('')
      setNewEmployeeCrewId(null)
      setNewEmployeeGuniteRate(null)
      setNewEmployeePlasterRate(null)
      setNewEmployeeQuartzRate(null)
      setNewEmployeePebbleRate(null)
      setNewDriverPoolRate(null)
      setNewSchedulerPhone('')
      setDisableEmailField(false)
    }
  }, [isEditing])

  const handleNewEmployeeFormSubmit = async (e) => {
    e.preventDefault()
    const combinedNames = newEmployeeFName + ' ' + newEmployeeLName;
    let formattedRole = newEmployeeRole.toUpperCase();
    if (formattedRole === 'CREW MEMBER'){
      formattedRole = 'CREWMEMBER'
    }
    setNewEmployee({
      ...newEmployee,
      name: combinedNames,
      email: newEmployeeEmail,
      id: selectedEmployee?.id ? selectedEmployee.id : null,
      locationId: selectedEmployee?.locationId,
      role: formattedRole,
      crewId: newEmployeeCrewId ? newEmployeeCrewId : null,
      guniteRate: 
        (newEmployeeRole === 'Foreman' || newEmployeeRole === 'Crew Member') && newEmployeeGuniteRate
        ? Number(newEmployeeGuniteRate)
        : null,
      plasterRate:
        (newEmployeeRole === 'Foreman' || newEmployeeRole === 'Crew Member') && newEmployeePlasterRate
        ? Number(newEmployeePlasterRate)
        : null,
      quartzRate:
        (newEmployeeRole === 'Foreman' || newEmployeeRole === 'Crew Member') && newEmployeeQuartzRate
        ? Number(newEmployeeQuartzRate)
        : null,
      pebbleRate:
        (newEmployeeRole === 'Foreman' || newEmployeeRole === 'Crew Member') && newEmployeePebbleRate
        ? Number(newEmployeePebbleRate)
        : null,
      driverRate:
        (newEmployeeRole === 'Driver') ? Number(newDriverPoolRate) : null,
      phone:
        newSchedulerPhone ? newSchedulerPhone : null
    })
    const payload = {
      ...newEmployee,
      name: combinedNames,
      email: newEmployeeEmail,
      id: selectedEmployee?.id ? selectedEmployee.id : null,
      locationId: selectedEmployee?.locationId,
      role: formattedRole,
      crewId: newEmployeeCrewId ? newEmployeeCrewId : null,
      guniteRate: 
        (newEmployeeRole === 'Foreman' || newEmployeeRole === 'Crew Member') && newEmployeeGuniteRate
        ? Number(newEmployeeGuniteRate)
        : null,
      plasterRate:
        (newEmployeeRole === 'Foreman' || newEmployeeRole === 'Crew Member') && newEmployeePlasterRate
        ? Number(newEmployeePlasterRate)
        : null,
      quartzRate:
        (newEmployeeRole === 'Foreman' || newEmployeeRole === 'Crew Member') && newEmployeeQuartzRate
        ? Number(newEmployeeQuartzRate)
        : null,
      pebbleRate:
        (newEmployeeRole === 'Foreman' || newEmployeeRole === 'Crew Member') && newEmployeePebbleRate
        ? Number(newEmployeePebbleRate)
        : null,
      driverRate:
        (newEmployeeRole === 'Driver') ? Number(newDriverPoolRate) : null,
      phone:
      newSchedulerPhone ? newSchedulerPhone : null
    };
    if (!isEditing){
      const res = await serviceMethods.createEmployee(payload);
      if (res.ok){
        const data = await res.json();
        getEmployeesByBranch(selectedEmployee.locationId);
        setNeedToUpdateEmployees(true);
        setIsDeleting(false);
        setIsEditing(false);
        modalMethods.close();
      } else {
        const resNotOk = await res.json();
        if (resNotOk.message.includes('User already exists at this location')){
          setEmployeeEmailAlreadyTaken(true)
        } else if (resNotOk.message.includes('This user belongs to another location as a different role.')
        ||resNotOk.message.includes('User cannot be added because they belong to another location as a different role.')) {
          setEmployeeHasAnotherRole(true)
        }
      }
    } else { 
      const res = await serviceMethods.updateEmployee(payload)
      if (res.ok){
        const data = await res.json();
        getEmployeesByBranch(selectedEmployee.locationId);
        setNeedToUpdateEmployees(true);
        setIsDeleting(false);
        setIsEditing(false);
        modalMethods.close();
      } else {
        const resNotOk = await res.json();
        if (resNotOk.message.includes('User already exists at this location')){
          setEmployeeEmailAlreadyTaken(true)
        } else if (resNotOk.message.includes('This user belongs to another location as a different role.')
        ||resNotOk.message.includes('User cannot be added because they belong to another location as a different role.')) {
          setEmployeeHasAnotherRole(true)
        }
      }
    }
  }

  const handleDeleteEmployee = async (e) => {
    e.preventDefault();
    let roleForBackend;
    if (selectedEmployee.role){
      if (selectedEmployee.role === 'Driver'){
        roleForBackend = 'DRIVER'
      } else if (selectedEmployee.role === 'Crew Member'){
        roleForBackend = 'CREWMEMBER'
      } else if (selectedEmployee.role === 'Scheduler'){
        roleForBackend = 'SCHEDULER'
      } else if (selectedEmployee.role === 'Foreman'){
        roleForBackend = 'FOREMAN'
      }

      const payload = {
        ...newEmployee,
        id: selectedEmployee?.id,
        role: roleForBackend,
        locationId: selectedEmployee?.locationId
      }
      const res = await serviceMethods.deleteEmployee(payload)
      if (res.ok){
        const data = await res.json();
        getEmployeesByBranch(selectedEmployee.locationId);
        setNeedToUpdateEmployees(true);
        setIsDeleting(false);
        setIsEditing(false);
        modalMethods.close();
      }
    }
  }

  return (
    <div className='absolute z-10 bg-navy-bright10 w-full sm:w-3/4 lg:w-1/2 items-center border rounded-2xl py-4 px-6 font-roboto'>
      <div className='flex justify-between'>
        <div className='text-dark-blue font-bold mb-3 text-[21px]'>
          {`${isEditing ? 'Edit Employee' : 'Add Employee'}`}
        </div>
        <button>
          <img src='/close_dark.svg' onClick={()=>{
            modalMethods.close();
            setIsEditing(false);
          }}/>
        </button>
      </div>

      <form className='py-3 flex-row' onSubmit={handleNewEmployeeFormSubmit}>
        <div className='w-full flex'>
          <div className='w-1/2'>
            <div className='text-dark-blue font-normal text-xs mb-1 font-roboto'>
              First name *
            </div>
            <input
              className='w-full mb-3 border rounded border-navy-light'
              value={newEmployeeFName}
              onChange={(e)=>{
                setNewEmployeeFName(e.target.value)
              }}
            />

            <div className='text-dark-blue font-normal text-xs mb-1 font-roboto'>
              Last name *
            </div>
            <input
              className='w-full mb-3 border rounded border-navy-light'
              value={newEmployeeLName}
              onChange={(e)=>{
                setNewEmployeeLName(e.target.value)
              }}
            />

            <div className='text-dark-blue font-normal text-xs mb-1 font-roboto'>
              Email *
            </div>
            <input
              className={`w-full mb-6 border rounded border-navy-light ${disableEmailField && 'bg-navy-light/50'}`}
              value={newEmployeeEmail}
              onChange={(e)=>{
                setNewEmployeeEmail(e.target.value)
              }}
              disabled={disableEmailField}
            />
          </div>

          <div className='pl-6 w-1/2'>
            <div className='text-dark-blue font-normal text-xs mb-1 font-roboto'>
              Role *
            </div>
            <select
              className='w-full border rounded border-navy-light'
              value={newEmployeeRole}
              onChange={(e)=>{
                if (selectedEmployee.role === 'Foreman' && e.target.value === 'Crew Member'){
                  setNewEmployeeCrewId(null)
                }
                setNewEmployeeRole(e.target.value)
                setNewSchedulerPhone('')
              }}
            >
              <option value="" className='text-dark-blue/60' disabled>Select role</option>
              <option value="Crew Member">Crew Member</option>
              <option value="Foreman">Foreman</option>
              <option value="Driver">Driver</option>
              <option value="Scheduler">Scheduler</option>
            </select>

            {newEmployeeRole === 'Scheduler' &&
              <>
                <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                  Phone *
                </div>
                <div className='w-full'>
                  <PhoneInputBackend
                    value={newSchedulerPhone}
                    onChange={(e) => {
                      setNewSchedulerPhone(e.target.value)
                    }}
                  />
                </div>
              </>
            }
            
            {newEmployeeRole === 'Crew Member' &&
              <>
                <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                  Crew
                </div>
                <div className='relative flex items-center'>
                  <select
                    className='w-full border rounded border-navy-light'
                    value={newEmployeeCrewId}
                    onChange={(e)=>{
                      setNewEmployeeCrewId(Number(e.target.value))
                    }}
                    disabled={listOfForeman.filter((foreman) => foreman.email !== selectedEmployee.email).length === 0}
                  >
                    <option value="" className='text-dark-blue/60' disabled={newEmployeeCrewId}>Select Crew</option>
                    {listOfForeman
                      .filter((foreman) => foreman.email !== selectedEmployee.email)
                      .map((foreman) => ( 
                      <option value={foreman.crewId} key={foreman.crewId} className='text-dark-blue'>{foreman.foremanName}</option>
                    ))}
                  </select>
                </div>

                {isLocationGunite() ?
                  <>
                    <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                      Pool rate
                    </div>
                    <div className='relative flex items-center'>
                      <div className='absolute pl-3 text-dark-blue font-normal font-roboto'>$</div>
                      <input
                        className='w-full lg:w-1/2 border rounded border-navy-light pl-6'
                        value={newEmployeeGuniteRate || ''}
                        onChange={(e)=>{
                          setNewEmployeeGuniteRate(e.target.value)
                        }}
                      />
                    </div>
                  </>
                  :
                  <>
                    <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                      Plaster pool rate
                    </div>
                    <div className='relative flex items-center'>
                      <div className='absolute pl-3 text-dark-blue font-normal font-roboto'>$</div>
                      <input
                        className='w-full lg:w-1/2 border rounded border-navy-light pl-6'
                        value={newEmployeePlasterRate || ''}
                        onChange={(e)=>{
                          setNewEmployeePlasterRate(e.target.value)
                        }}
                      />
                    </div>
                    
                    <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                      Quartz pool rate
                    </div>
                    <div className='relative flex items-center'>
                      <div className='absolute pl-3 text-dark-blue font-normal font-roboto'>$</div>
                      <input
                        className='w-full lg:w-1/2 border rounded border-navy-light pl-6'
                        value={newEmployeeQuartzRate || ''}
                        onChange={(e)=>{
                          setNewEmployeeQuartzRate(e.target.value)
                        }}
                      />
                    </div>
                    
                    <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                      Pebble pool rate
                    </div>
                    <div className='relative flex items-center'>
                      <div className='absolute pl-3 text-dark-blue font-normal font-roboto'>$</div>
                      <input
                        className='w-full lg:w-1/2 border rounded border-navy-light pl-6'
                        value={newEmployeePebbleRate || ''}
                        onChange={(e)=>{
                          setNewEmployeePebbleRate(e.target.value)
                        }}
                      />
                    </div>
                  </>
                }
              </>
            }

            {newEmployeeRole === 'Foreman' &&
              <>
                {isLocationGunite() ? 
                  <>
                    <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                      Gunite pool rate
                    </div>
                    <div className='relative flex items-center'>
                      <div className='absolute pl-3 text-dark-blue font-normal font-roboto'>$</div>
                      <input
                        className='w-full lg:w-1/2 border rounded border-navy-light pl-6'
                        value={newEmployeeGuniteRate || ''}
                        onChange={(e)=>{
                          setNewEmployeeGuniteRate(e.target.value)
                        }}
                      />
                    </div>
                  </>
                :
                  <>
                    <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                      Plaster pool rate
                    </div>
                    <div className='relative flex items-center'>
                      <div className='absolute pl-3 text-dark-blue font-normal font-roboto'>$</div>
                      <input
                        className='w-full lg:w-1/2 border rounded border-navy-light pl-6'
                        value={newEmployeePlasterRate || ''}
                        onChange={(e)=>{
                          setNewEmployeePlasterRate(e.target.value)
                        }}
                      />
                    </div>

                    <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                      Quartz pool rate
                    </div>
                    <div className='relative flex items-center'>
                      <div className='absolute pl-3 text-dark-blue font-normal font-roboto'>$</div>
                      <input
                        className='w-full lg:w-1/2 border rounded border-navy-light pl-6'
                        value={newEmployeeQuartzRate || ''}
                        onChange={(e)=>{
                          setNewEmployeeQuartzRate(e.target.value)
                        }}
                      />
                    </div>
                    <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                      Pebble pool rate
                    </div>
                    <div className='relative flex items-center'>
                      <div className='absolute pl-3 text-dark-blue font-normal font-roboto'>$</div>
                      <input
                        className='w-full lg:w-1/2 border rounded border-navy-light pl-6'
                        value={newEmployeePebbleRate || ''}
                        onChange={(e)=>{
                          setNewEmployeePebbleRate(e.target.value)
                        }}
                      />
                    </div>
                  </>
                }
              </>
            }

            {newEmployeeRole === 'Driver' &&
              <>
                <div className='text-dark-blue font-normal text-xs mb-1 mt-3 font-roboto'>
                  Pool rate
                </div>
                <div className='relative flex items-center'>
                  <div className='absolute pl-3 text-dark-blue font-normal font-roboto'>$</div>
                  <input
                    className='w-full lg:w-1/2 border rounded border-navy-light pl-6'
                    value={newDriverPoolRate || ''}
                    onChange={(e)=>{
                      setNewDriverPoolRate(e.target.value)
                    }}
                  />
                </div>
              </>
            }
          </div>
        </div>

        {!isEditing ?
          <div className='pt-6 w-full flex-row'>
            {employeeEmailAlreadyTaken &&
              <div className='pb-3 lg:pb-0 text-gunite-red font-semibold'>
                An account with this email already exists!
              </div>
            }
            {employeeHasAnotherRole &&
              <div className='pb-3 lg:pb-0 text-gunite-red font-semibold'>
                This employee has a different role at another location!
              </div>
            }
            <div className='flex justify-end'>
              <button
                type="button"
                className='border rounded border-navy-light bg-white py-2 px-3 font-semibold text-base mr-4'
                onClick={()=>modalMethods.close()}>
                Cancel
              </button>

              <button
                type="submit"
                className={`border rounded border-jet-black/10 py-2 px-3
                  font-semibold text-base
                  ${addButtonDisabled() ? 'bg-jet-black/10 text-jet-black/40' : 'text-white bg-dark-blue'}`}
                disabled={addButtonDisabled()}
              >
                Add
              </button>
            </div>
          </div>
        :
          <div className='pt-6 w-full flex-row'>
            {employeeEmailAlreadyTaken &&
              <div className='pb-3 text-gunite-red font-semibold'>
                An account with this email already exists!
              </div>
            }
            {employeeHasAnotherRole &&
              <div className='pb-3 text-gunite-red font-semibold'>
                This employee has a different role at another location!
              </div>
            }
            <div className='flex justify-between'>
              {!isDeleting ?
                <button
                  type="button"
                  className='border rounded border-gunite-red py-2 px-3 text-gunite-red bg-white'
                  onClick={()=>setIsDeleting((prevIsDeleting) => {
                    const newIsDeleting = !prevIsDeleting;
                    isDeletingRef.current = newIsDeleting;
                    return newIsDeleting;
                  })}>
                  Delete
                </button>
                :
                <>
                  <div>
                    Confirm Delete?
                  </div>

                  <div className='flex justify-end'>
                    <button
                      type="button"
                      className='border rounded border-gunite-red py-2 px-3 text-gunite-red bg-white mr-4'
                      onClick={()=>setIsDeleting((prevIsDeleting) => {
                        const newIsDeleting = !prevIsDeleting;
                        isDeletingRef.current = newIsDeleting;
                        return newIsDeleting;
                      })}>
                      Cancel
                    </button>

                    <button
                      type="button"
                      className='border rounded border-jet-black/10 py-2 px-3 text-white bg-dark-blue font-semibold text-base'
                      onClick={(e)=>handleDeleteEmployee(e)}>
                      Confirm
                    </button>
                  </div>
                </>
              }
              {!isDeleting &&
                <div>
                  <button
                    type="button"
                    className='border rounded border-navy-light bg-white py-2 px-3 font-semibold text-base mr-4'
                    onClick={()=>{modalMethods.close(); setIsEditing(false);}}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`border rounded border-jet-black/10 bg-dark-blue py-2 px-3 
                      font-semibold text-base text-white 
                      ${saveButtonDisabled() ? 'bg-jet-black/10 text-jet-black/40' : 'text-white bg-dark-blue'}`}
                    disabled={saveButtonDisabled()}
                  >
                    Save
                  </button>
                </div>
              }
            </div>
          </div>
        }
      </form>
    </div>
  )
}

export default NewEmployeeModal;
