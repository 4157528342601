import React, { useRef, useState } from 'react'
import Input from '../Input'
import serviceMethods from '../../service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const PoolDetailsForm3b = ({ pool, i, setPoolDetails, poolDetails, jobDetails, isGunite }) => {
  const hiddenFileInput = useRef(null)
  const [isUploading, setIsUploading] = useState(false)

  const squareFtError = !pool.squareFt
  const depthError = !pool.depth
  const deepEndFtError = !pool.deepEndFt
  const deepEndInchesError = !pool.deepEndInches
  const shallowEndFtError = !pool.shallowEndFt
  const shallowEndInchesError = !pool.shallowEndInches
  const lengthFtError = !pool.lengthFt
  const lengthInchesError = !pool.lengthInches
  const widthFtError = !pool.widthFt
  const widthInchesError = !pool.widthInches
  const tanningLedgeFtError = !pool.tanningLedgeFt
  const tanningLedgeInchesError = !pool.tanningLedgeInches
  const benchFtError = !pool.benchFt
  const benchInchesError = !pool.benchInches
  const stepsError = !pool.steps
  const waterfallError = !pool.waterfall
  const barstoolsError = !pool.barstools
  const perimeterFtError = !pool.perimeterFt
  const perimeterInchesError = !pool.perimeterInches
  const drainCoversError = !pool.drainCovers
  const fittingsError = !pool.fittings

  const handleUpload = async (event) => {
    setIsUploading(true)
    const fileUploaded = event.target.files[0]
    const formData = new FormData()
    formData.append('plan', fileUploaded)
    //block for if a user is uploading a new file for the pool plan to a preexisting pool
    if (pool.planUri) {
      formData.append('poolId', pool.id)
      const res = await serviceMethods.updatePoolPlan(formData)
      if (res.ok) {
        const savedPool = await res.json()
        let newPoolDetails = [...poolDetails]
        newPoolDetails[i] = savedPool
        setPoolDetails(newPoolDetails)
      }
    } else {
      //block for if a user is uploading a new pool plan
      formData.append('jobId', jobDetails[i].id)
      const res = await serviceMethods.uploadPoolPlan(formData)
      if (res.ok) {
        const savedPool = await res.json()
        let newPoolDetails = [...poolDetails]
        newPoolDetails[i] = savedPool
        setPoolDetails(newPoolDetails)
      }
    }
    setIsUploading(false)
  }

  return (
    <div key={`pool${i}`} className='flex flex-col gap-6'>
      <p className='font-semibold text-secondary-blue/60'>Pool plan</p>
      <form encType="multipart/form-data" className='w-auto'>
        <input type='file' className='hidden' onChange={handleUpload} ref={hiddenFileInput} />
        <button
          type="button"
          className={` rounded text-xl lg:text-2xl lg:font-bold font-medium lg:w-full flex flex-row justify-center gap-2 ${pool.planUri ? 'bg-tertiary-blue/60 text-white border-tertiary-blue border-4 py-2 px-3' : 'bg-tertiary-blue text-white py-3 px-4'}`}
          onClick={() => {
            hiddenFileInput.current.click()
          }}
        >
          <p>{pool.planUri ? 'Upload new plan' : 'Upload plan'}</p>
          <FontAwesomeIcon icon={faCircleNotch} style={{ color: '#FFFFFF' }} spin size='xl' className={`${isUploading ? 'visible' : 'invisible'}`} />
        </button>
      </form>
      {pool.id != null ? `uploaded file: ${pool.planUri}` : null}
      {pool.planUri ?
        <form className='flex flex-col gap-6'>
          {!isGunite ? (
            <>
              <div className='flex gap-6'>
                <div className="w-full">
                  <div className='flex gap-6'>
                    <div className='flex items-center w-1/3'>
                      Deep End Depth*
                    </div>
                    <div className="w-1/3">
                      <Input
                        numbersOnly
                        placeholder={'ft'}
                        value={pool.deepEndFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            deepEndFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={deepEndFtError}
                      />
                    </div>
                    <div className="w-1/3">
                      <Input
                        numbersAndDecimalsOnly
                        placeholder={'inches'}
                        value={pool.deepEndInches || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            deepEndInches: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={deepEndInchesError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex gap-6'>
                <div className="w-full">
                  <div className='flex gap-6'>
                    <div className='flex items-center w-1/3'>
                      Shallow End Depth*
                    </div>
                    <div className="w-1/3">
                      <Input
                        numbersOnly
                        placeholder={'ft'}
                        value={pool.shallowEndFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            shallowEndFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={shallowEndFtError}
                      />
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersAndDecimalsOnly
                        placeholder={'inches'}
                        value={pool.shallowEndInches || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            shallowEndInches: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={shallowEndInchesError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex gap-6'>
                <div className="w-full">
                  <div className='flex gap-6'>
                    <div className='flex items-center w-1/3'>
                      Length*
                    </div>
                    <div className="w-1/3">
                      <Input
                        numbersOnly
                        placeholder={'ft'}
                        value={pool.lengthFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            lengthFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={lengthFtError}
                      />
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersAndDecimalsOnly
                        placeholder={'inches'}
                        value={pool.lengthInches || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            lengthInches: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={lengthInchesError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex gap-6'>
                <div className="w-full">
                  <div className='flex gap-6'>
                    <div className='flex items-center w-1/3'>
                      Width*
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersOnly
                        placeholder={'ft'}
                        value={pool.widthFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            widthFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={widthFtError}
                      />
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersAndDecimalsOnly
                        placeholder={'inches'}
                        value={pool.widthInches || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            widthInches: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={widthInchesError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex gap-6'>
                <div className="w-full">
                  <div className='flex gap-6'>
                    <div className='flex items-center w-1/3'>
                      Tanning Ledge Size*
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersOnly
                        placeholder={'ft'}
                        value={pool.tanningLedgeFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            tanningLedgeFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={tanningLedgeFtError}
                      />
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersAndDecimalsOnly
                        placeholder={'inches'}
                        value={pool.tanningLedgeInches || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            tanningLedgeInches: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={tanningLedgeInchesError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex gap-6'>
                <div className="w-full">
                  <div className='flex gap-6'>
                    <div className='flex items-center w-1/3'>
                      Bench length*
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersOnly
                        placeholder={'ft'}
                        value={pool.benchFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            benchFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={benchFtError}
                      />
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersAndDecimalsOnly
                        placeholder={'inches'}
                        value={pool.benchInches || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            benchInches: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={benchInchesError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex gap-6'>
                <div className="w-full">
                  <div className='flex gap-6'>
                    <div className='flex items-center w-1/3'>
                      Perimeter*
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersOnly
                        placeholder={'ft'}
                        value={pool.perimeterFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            perimeterFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={perimeterFtError}
                      />
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersAndDecimalsOnly
                        placeholder={'inches'}
                        value={pool.perimeterInches || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            perimeterInches: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={perimeterInchesError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex gap-6'>
                <div className='w-full'>
                  <div className='flex gap-2'>
                    <div className='flex items-center w-1/3'>
                      Square Ft*
                    </div>
                    <div className='w-2/3'>
                      <Input
                        placeholder={'ft'}
                        value={pool.squareFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            squareFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1)
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={squareFtError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex flex-col gap-6 lg:flex-row'>
                <div className="lg:w-1/2">
                  <Input
                    numbersOnly
                    placeholder={'Steps *'}
                    value={pool.steps || ''}
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        steps: e.target.value
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1),
                      ]
                      setPoolDetails(newPoolDetails);
                    }}
                    isError={stepsError}
                  />
                </div>
                <div className="lg:w-1/2">
                  <Input
                    numbersOnly
                    placeholder={'Waterfall pad *'}
                    value={pool.waterfall || ''}
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        waterfall: e.target.value
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1),
                      ]
                      setPoolDetails(newPoolDetails);
                    }}
                    isError={waterfallError}
                  />
                </div>
              </div>
              <div className='flex flex-col gap-6 lg:flex-row'>
                <div className="lg:w-1/2">
                  <Input
                    numbersOnly
                    placeholder={'Barstools *'}
                    value={pool.barstools || ''}
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        barstools: e.target.value
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1),
                      ]
                      setPoolDetails(newPoolDetails);
                    }}
                    isError={barstoolsError}
                  />
                </div>
                <div className="lg:w-1/2">
                  <Input
                    numbersOnly
                    placeholder={'Drain Covers *'}
                    value={pool.drainCovers || ''}
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        drainCovers: e.target.value
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1),
                      ]
                      setPoolDetails(newPoolDetails);
                    }}
                    isError={drainCoversError}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-6 lg:flex-row'>
                <div className="lg:w-full">
                  <Input
                    numbersOnly
                    placeholder={'Fittings *'}
                    value={pool.fittings || ''}
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        fittings: e.target.value
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1),
                      ]
                      setPoolDetails(newPoolDetails);
                    }}
                    isError={fittingsError}
                  />
                </div>
              </div>
              <div className='flex gap-2 w-1/2 items-center'>
                <input
                  type='checkbox'
                  id={`basin${i}`}
                  className='text-secondary-blue'
                  value={pool.basin || false}
                  onChange={(e) => {
                    const newPool = {
                      ...pool,
                      basin: e.target.checked
                    }
                    const newPoolDetails = [
                      ...poolDetails.slice(0, i),
                      newPool,
                      ...poolDetails.slice(i + 1),
                    ]
                    setPoolDetails(newPoolDetails);
                  }}
                  checked={pool.basin || false}>
                </input>
                <label className='text-tertiary-blue/60 font-semibold' htmlFor={`basin${i}`}>
                  Basin
                </label>
              </div>
              <div className='flex gap-2 w-1/2 items-center'>
                <input
                  type='checkbox'
                  id={`spa${i}`}
                  value={pool.spa || false}
                  className='text-secondary-blue'
                  onChange={(e) => {
                    const newPool = {
                      ...pool,
                      spa: e.target.checked
                    }
                    const newPoolDetails = [
                      ...poolDetails.slice(0, i),
                      newPool,
                      ...poolDetails.slice(i + 1),
                    ]
                    setPoolDetails(newPoolDetails);
                  }}
                  checked={pool.spa || false}>
                </input>
                <label className='text-tertiary-blue/60 font-semibold' htmlFor={`spa${i}`}>
                  Spa
                </label>
              </div>
            </>
          ) : (
            <>
              <div className='flex gap-6'>
                <div className='w-full'>
                  <div className='flex gap-6'>
                    <div className='flex items-center w-1/3'>
                      Perimeter*
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersOnly
                        placeholder={'ft'}
                        value={pool.perimeterFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            perimeterFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={perimeterFtError}
                      />
                    </div>
                    <div className='w-1/3'>
                      <Input
                        numbersAndDecimalsOnly
                        placeholder={'inches'}
                        value={pool.perimeterInches || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            perimeterInches: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1),
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={perimeterInchesError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex gap-6'>
                <div className='w-full'>
                  <div className='flex gap-2'>
                    <div className='flex items-center w-1/3'>
                      Square Ft*
                    </div>
                    <div className='w-2/3'>
                      <Input
                        placeholder={'ft'}
                        value={pool.squareFt || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            squareFt: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1)
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={squareFtError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex gap-6'>
                <div className='w-full'>
                  <div className='flex gap-2'>
                    <div className='flex items-center w-1/3'>
                      Depth*
                    </div>
                    <div className='flex items-center w-2/3'>
                      <Input
                        placeholder={'ft'}
                        value={pool.depth || ''}
                        onChange={(e) => {
                          const newPool = {
                            ...pool,
                            depth: e.target.value
                          }
                          const newPoolDetails = [
                            ...poolDetails.slice(0, i),
                            newPool,
                            ...poolDetails.slice(i + 1)
                          ]
                          setPoolDetails(newPoolDetails);
                        }}
                        isError={depthError}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex'>
                <div className='flex gap-2 w-1/2 items-center'>
                  <input
                    type='checkbox'
                    id={`spa${i}`}
                    value={pool.spa || ''}
                    className='text-secondary-blue'
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        spa: e.target.checked
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1),
                      ]
                      setPoolDetails(newPoolDetails);
                    }}
                    checked={pool.spa}>
                  </input>
                  <label className='text-tertiary-blue/60 font-semibold' htmlFor={`spa${i}`}>
                    Spa
                  </label>
                </div>
                {/*NEGATIVE EDGE*/}
                <div className='flex gap-2 w-1/2 items-center'>
                  <input
                    type='checkbox'
                    id={`negEdge${i}`}
                    value={pool.negativeEdge || ''}
                    className='text-secondary-blue'
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        negativeEdge: e.target.checked
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1),
                      ]
                      setPoolDetails(newPoolDetails);
                    }}
                    checked={pool.negativeEdge || false}>
                  </input>
                  <label className='text-tertiary-blue/60 font-semibold' htmlFor={`negEdge${i}`}>
                    Negative Edge
                  </label>
                </div>
              </div>
              <div className='flex'>
                <div className='flex gap-2 w-1/2 items-center'>
                  <input
                    type='checkbox'
                    id={`raisedBeam${i}`}
                    value={pool.raisedBeam || ''}
                    className='text-secondary-blue'
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        raisedBeam: e.target.checked
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1)
                      ]
                      setPoolDetails(newPoolDetails)
                    }}
                    checked={pool.raisedBeam}
                  />
                  <label className='text-tertiary-blue/60 font-semibold' htmlFor={`raisedBeam${i}`}>
                    Raised Beam
                  </label>
                </div>
                <div className='flex gap-2 w-1/2 items-center'>
                  <input
                    type='checkbox'
                    id={`retainingWalls${i}`}
                    value={pool.retainingWalls || ''}
                    className='text-secondary-blue'
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        retainingWalls: e.target.checked
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1)
                      ]
                      setPoolDetails(newPoolDetails)
                    }}
                    checked={pool.retainingWalls}
                  />
                  <label className='text-tertiary-blue/60 font-semibold' htmlFor={`retainingWalls${i}`}>
                    Retaining Walls
                  </label>
                </div>
              </div>
              <div className='flex'>
                <div className='flex gap-2 w-1/2 items-center'>
                  <input
                    type='checkbox'
                    id={`notch${i}`}
                    value={pool.notch || ''}
                    className='text-secondary-blue'
                    onChange={(e) => {
                      const newPool = {
                        ...pool,
                        notch: e.target.checked
                      }
                      const newPoolDetails = [
                        ...poolDetails.slice(0, i),
                        newPool,
                        ...poolDetails.slice(i + 1)
                      ]
                      setPoolDetails(newPoolDetails)
                    }}
                    checked={pool.notch}
                  />
                  <label className='text-tertiary-blue/60 font-semibold' htmlFor={`notch${i}`}>
                    Notch
                  </label>
                </div>                
              </div>
            </>
          )}
        </form>
        :
        null
      }
    </div>

  )
};

export default PoolDetailsForm3b
