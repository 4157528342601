import React, { useState, useEffect, useRef } from 'react'
import { useScheduler } from "../../contexts/scheduler";
// import serviceMethods from '../../service'
import LoadingSpinner from '../LoadingSpinner'
import { useSchedulerCalendar } from '../../contexts/schedulerCalendar'

const DailyReportDownloadModal = () => {
  const { jobLogInstance, breakdownInstance, showDownloadModal, setShowDownloadModal, selectedDay, setSelectedDay } = useSchedulerCalendar()
  const { selectedScheduler } = useScheduler()
  const [breakdownDisabled, setBreakdownDisabled] = useState(true)
  const [jobLogDisabled, setJobLogDisabled] = useState(true)
  const [breakdownUploading, setBreakdownUploading] = useState(true)
  const [jobLogUploading, setJobLogUploading] = useState(true)
  const [showBreakdownMessage, setShowBreakdownMessage] = useState(false)
  const [showJobLogMessage, setShowJobLogMessage] = useState(false)

  let locationString = selectedScheduler?.location.label
  locationString = locationString.replace(/\s/g, '-'); // replaces spaces with 1 dash
  locationString = locationString.replace(/-+/g, '-'); // replaces consecutive dashes with 1 dash
  locationString = locationString.replace(/,+/g, ''); // removes commas
  const breakdownName = `Breakdown_${(selectedDay?.toISOString())?.substring(0,10)}_${locationString}`
  const jobLogName = `JobLog_${(selectedDay?.toISOString())?.substring(0,10)}_${locationString}`

  // disables the button / shows 'file downloaded' message
  const handleDownloadDailyReport = async (isBreakdown) => {

    if (isBreakdown) {
      setBreakdownDisabled(true)
    } else {
      setJobLogDisabled(true)
    }

    if (isBreakdown) {
      setShowBreakdownMessage(true)
    } else {
      setShowJobLogMessage(true)
    }
  }

  const handleCloseModal = () => {
    setSelectedDay(null)
    setShowDownloadModal(false)

    // disables buttons
    setBreakdownDisabled(true)
    setJobLogDisabled(true)

    // hides success/fail messages
    setShowBreakdownMessage(false)
    setShowJobLogMessage(false)

    // turn on loading spinners for the next time you open the modal
    setBreakdownUploading(true)
    setJobLogUploading(true)
  }

  useEffect(() => {
    if (showDownloadModal) {
      if (!breakdownInstance.loading) {
        setBreakdownDisabled(false)
        setBreakdownUploading(false)
      }
      if (!jobLogInstance.loading) {
        setJobLogDisabled(false)
        setJobLogUploading(false)
      }
    }
  }, [breakdownInstance.loading, jobLogInstance.loading, showDownloadModal])

  return (
    <div>
      {!showDownloadModal ? <></> : (
        <div
          className='flex font-normal text-base justify-center items-center fixed w-screen h-screen top-0 left-0 bg-navy-light bg-opacity-60 z-10'
          onClick={(e) => {
            e.stopPropagation()
            handleCloseModal()
          }}
        >
          <div
            className="w-[500px] rounded-[4px] bg-white border border-navy-light p-4 flex flex-col justify-center items-center"
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <h2 className="text-lg font-bold text-dark-blue">Download</h2>
            <p className="text-gray-blue my-4">Daily Reports for: {selectedDay.getUTCMonth() + 1}/{selectedDay.getUTCDate()}/{selectedDay.getUTCFullYear()}</p>
            <div className="flex justify-center gap-4 px-8">

              <button
                onClick={() => {
                  handleCloseModal()
                }}
                className="bg-white hover:bg-gunite-red text-gunite-red hover:text-white rounded-md border border-gunite-red py-2 px-4 mr-2 h-fit"
              >
                Cancel
              </button>

              <div className='flex flex-col'>

                <a href={jobLogInstance.url} download={jobLogName}>
                  <button
                    className={`flex justify-center border rounded-md py-2 px-4 w-[140px] ${jobLogDisabled ? 'text-opaque-job-details-gray bg-disabled-gray border-opaque-job-details-gray' : 'bg-white text-dark-blue border-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue'}`}
                    onClick={async () => {
                      handleDownloadDailyReport(false)
                    }}
                    disabled={jobLogDisabled}
                  >{jobLogUploading ? <LoadingSpinner /> : 'Job Logs'}</button>
                </a>

                <div
                  className={`flex justify-center w-[94%] pt-4 ${showJobLogMessage ? '' : 'hidden'}`}
                >
                  <p
                    className='text-lime-500'
                  >File Downloaded</p>

                </div>

              </div>

              <div className='flex flex-col'>
                <a href={breakdownInstance.url} download={breakdownName}>
                  <button
                    className={`flex justify-center border rounded-md py-2 px-4 w-[140px] ${breakdownDisabled ? 'text-opaque-job-details-gray bg-disabled-gray border-opaque-job-details-gray' : 'bg-white text-dark-blue border-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue'}`}
                    onClick={async () => {
                      handleDownloadDailyReport(true)
                    }}
                    disabled={breakdownDisabled}
                  >{breakdownUploading ? <LoadingSpinner /> : 'Breakdowns'}</button>
                </a>

                <div
                  className={`flex justify-center w-[94%] pt-4 ${showBreakdownMessage ? '' : 'hidden'}`}
                >
                  <p
                    className='text-lime-500'
                  >File Downloaded</p>
                </div>

              </div>


            </div>
          </div>
        </div>
      )}


    </div>
  );
};

export default DailyReportDownloadModal;
