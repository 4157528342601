import React, { useEffect } from 'react'
import { useIntakeForm } from "../../contexts/intakeForm"
import Selector from '../Selector'
import Input from "../Input"

const GuniteForm3c = ({ pool, i, setPoolDetails, poolDetails }) => {
  const {
    materialDropDownList, setColorList, colorMap, retrievedLocationsPlaster, jobDetails
  } = useIntakeForm();
  const materialAddInsError = !pool.materialAddIns

  useEffect(() => {
    if (poolDetails[i].material) {
      const productNameWords = poolDetails[i].material.split(' -- ')
      const productName = productNameWords[productNameWords.length - 1]
      const colorChoicesArray = colorMap[productName]
      setColorList(colorChoicesArray)
    }
  }, [])

  const filteredColorMapOptions = (colorMap, searchString) => {
    let flattenedValues;
    if (searchString) {
      const productNameWords = searchString.split(' -- ')
      const productName = productNameWords[productNameWords.length - 1]
      const filteredEntries = Object.entries(colorMap).filter(([key]) => key.includes(productName));
      const filteredValues = filteredEntries.map(([_, value]) => value);
      flattenedValues = filteredValues.flat();
      flattenedValues.push('Other')
    } else {
      flattenedValues = [];
    }
    return flattenedValues;
  }

  return (
    <>
      <div className="flex flex-col gap-1">
        <p className="text-secondary-blue/60 text-base font-semibold mt-[2px]">
          {poolDetails.length > 1 ?
            `Plaster for Job #${i + 1}: ${jobDetails[i].jobName}`
            :
            null
          }
        </p>
        <p className="text-secondary-blue/60 text-base font-normal">Would you like to add plaster service as well?</p>
        <div className="flex flex-col mt-6 mb-6">
          <div>
            <input
              type="radio"
              id={`noPlaster${i}`}
              className="mt-[-2px]"
              value={poolDetails[i].guniteWPlaster}
              name={`plasterService${i}`}
              onChange={(e) => {
                const newPool = {
                  ...poolDetails[i],
                  guniteWPlaster: false,
                  newOrReplaster: null,
                  material: null,
                  materialAddIns: null,
                  color: null,
                  estimateNeeded: false,
                }
                const newPoolDetails = [
                  ...poolDetails.slice(0, i),
                  newPool,
                  ...poolDetails.slice(i + 1),
                ]
                setPoolDetails(newPoolDetails)
              }}
              checked={!poolDetails[i].guniteWPlaster}
            />
            <label htmlFor={`noPlaster${i}`} className="text-secondary-blue/60 text-base font-semibold ml-[10px]">
              Do not add plaster service
            </label>
          </div>
          <div>
            <input
              type="radio"
              id={`addPlaster${i}`}
              className="mt-[-2px]"
              value={poolDetails[i].guniteWPlaster}
              name={`plasterService${i}`}
              onChange={(e) => {
                const newPool = {
                  ...poolDetails[i],
                  guniteWPlaster: true
                }
                const newPoolDetails = [
                  ...poolDetails.slice(0, i),
                  newPool,
                  ...poolDetails.slice(i + 1),
                ]
                setPoolDetails(newPoolDetails)
              }}
              checked={poolDetails[i].guniteWPlaster}
            />
            <label htmlFor={`addPlaster${i}`} className="text-secondary-blue/60 text-base font-semibold ml-[10px]">Add Plaster Service</label>
          </div>
        </div>

      </div>
      {(poolDetails[i].guniteWPlaster)
        ?
        <form className='flex flex-col gap-6'>
          {/* Is this a new pool or re-plaster select field */}
          <Selector
            value={poolDetails[i].newOrReplaster || ''}
            placeholder={'Is this a New Pool or Re-Plaster?'}
            options={['New Pool', 'Re-Plaster']}
            onChange={(e) => {
              const newPool = {
                ...pool,
                newOrReplaster: e.target.value
              }
              const newPoolDetails = [
                ...poolDetails.slice(0, i),
                newPool,
                ...poolDetails.slice(i + 1),
              ]
              setPoolDetails(newPoolDetails);
            }}
          />
          {/* Material select field */}
          <Selector
            value={poolDetails[i].material || ''}
            placeholder={"Material *"}
            options={materialDropDownList}
            onChange={(e) => {
              const newPool = {
                ...pool,
                material: e.target.value
              }
              const newPoolDetails = [
                ...poolDetails.slice(0, i),
                newPool,
                ...poolDetails.slice(i + 1),
              ]
              setPoolDetails(newPoolDetails);
              const productNameWords = e.target.value.split(' -- ')
              const productName = productNameWords[productNameWords.length - 1]
              const colorChoicesArray = colorMap[productName]
              setColorList(colorChoicesArray)
              if (!e.target.value) {
                const newPool = {
                  ...pool,
                  material: null,
                  color: null
                }
                const newPoolDetails = [
                  ...poolDetails.slice(0, i),
                  newPool,
                  ...poolDetails.slice(i + 1),
                ]
                setPoolDetails(newPoolDetails);
                setColorList([])
              }
            }}
          />

          {/* Color select field */}
          <Selector
            value={poolDetails[i].color || ''}
            placeholder={'Color *'}
            options={filteredColorMapOptions(colorMap, poolDetails[i].material)}
            onChange={(e) => {
              const newPool = {
                ...pool,
                color: e.target.value
              }
              const newPoolDetails = [
                ...poolDetails.slice(0, i),
                newPool,
                ...poolDetails.slice(i + 1),
              ]
              setPoolDetails(newPoolDetails);

            }}
            disabled={!poolDetails[i].material}
          />

          {/* Material add-ins field */}
          <Input
            value={poolDetails[i].materialAddIns || ''}
            placeholder={'Material add-ins'}
            onChange={(e) => {
              const newPool = {
                ...pool,
                materialAddIns: e.target.value
              }
              const newPoolDetails = [
                ...poolDetails.slice(0, i),
                newPool,
                ...poolDetails.slice(i + 1),
              ]
              setPoolDetails(newPoolDetails);
            }}
            isError={materialAddInsError}
          />
          <div className='flex gap-2 w-full items-center'>
            <input
              type='checkbox'
              id={`estimate${i}`}
              value={poolDetails[i].estimateNeeded || false}
              className='text-tertiary-blue'
              onChange={(e) => {
                const newPool = {
                  ...pool,
                  estimateNeeded: e.target.checked
                }
                const newPoolDetails = [
                  ...poolDetails.slice(0, i),
                  newPool,
                  ...poolDetails.slice(i + 1),
                ]
                setPoolDetails(newPoolDetails);
              }}
              checked={pool.estimateNeeded}>
            </input>
            <label className='text-secondary-blue/60 font-semibold' htmlFor={`estimate${i}`}>
              Do you need an estimate?
            </label>
          </div>
        </form>
        :
        null
      }
      <div className='mt-3 mb-3'>
        <input
          type="checkbox"
          id={`plumbingCheckbox${i}`}
          className='mb-[2px] text-tertiary-blue'
          value={poolDetails[i].plumbingIsDone}
          name={`plumbingCheckbox${i}`}
          onChange={(e) => {
            const newPool = {
              ...poolDetails[i],
              plumbingIsDone: e.target.checked
            }
            const newPoolDetails = [
              ...poolDetails.slice(0, i),
              newPool,
              ...poolDetails.slice(i + 1)
            ]
            setPoolDetails(newPoolDetails)
          }}
          checked={poolDetails[i].plumbingIsDone}
        />
        <label
          htmlFor={`plumbingCheckbox${i}`}
          className="text-secondary-blue/60 text-base font-semibold ml-[10px]"
        >
          I confirm that the plumbing is done
        </label>
      </div>

      <div className='mb-6'>
        <input
          type="checkbox"
          className='mb-[2px] text-tertiary-blue'
          value={poolDetails[i].poolIsDugOut}
          name={`poolIsDugOutCheckbox${i}`}
          id={`poolIsDugOutCheckbox${i}`}
          onChange={(e) => {
            const newPool = {
              ...poolDetails[i],
              poolIsDugOut: e.target.checked
            }
            const newPoolDetails = [
              ...poolDetails.slice(0, i),
              newPool,
              ...poolDetails.slice(i + 1)
            ]
            setPoolDetails(newPoolDetails)
          }}
          checked={poolDetails[i].poolIsDugOut}
        />
        <label
          htmlFor={`poolIsDugOutCheckbox${i}`}
          className="text-secondary-blue/60 text-base font-semibold ml-[10px]"
        >
          I confirm that the pool is dug out
        </label>
      </div>
    </>
  )
}

export default GuniteForm3c
