import React, { createContext, useContext, useState, useEffect } from 'react'
import cookies from 'browser-cookies';
import { useAuth } from './auth'
import serviceMethods from '../service';

export const IntakeFormContext = createContext()

//note: Selector component depends on builderInfo and jobDetails not having any two properties with the same name.  Do not add new properties with the same name.
const DEFAULT_BUILDER = {
  firstName: '',
  lastName: '',
  bizName: '',
  bizAddress: '',
  city: '',
  state: '',
  zipCode: '',
  phone: '',
  email: '',
  confirmEmail: '',
  password: '',
  chosenLocationId: '',
  chosenLocationZip: '',
}

const DEFAULT_JOB = {
  jobName: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  city: '',
  state: '',
  zipCode: '',
  gateCode: '',
  notes: '',
  material: '',
  color: '',
  guniteWPlaster: false,
  needsEstimate: false,
  requestedDate: null,
  supervisorFName: '',
  supervisorLName: '',
  supervisorPhone: '',
  supervisorEmail: '',
  notificationMethod: '',
  materialAddIns: '',
  residentialOrCommercial: '',
  isHudJob: false,
  electricityOnSite: false,
  waterOnSite: false,
  useSameSupervisor: false,
  waterproofing: false
}

const DEFAULT_POOL = {
  id: null,
  planUri: '',
  squareFt: '',
  depth: '',
  deepEndFt: '',
  deepEndInches: '',
  shallowEndFt: '',
  shallowEndInches: '',
  lengthFt: '',
  lengthInches: '',
  widthFt: '',
  widthInches: '',
  tanningLedgeFt: '',
  tanningLedgeInches: '',
  benchFt: '',
  benchInches: '',
  steps: '',
  waterfall: '',
  barstools: '',
  perimeterFt: '',
  perimeterInches: '',
  drainCovers: '',
  fittings: '',
  basin: false,
  spa: false,
  negativeEdge: false,
  newOrReplaster:'',
  material: '',
  color: '',
  materialAddIns:'',
  estimateNeeded: false,
  weWillPrepPool: false,
  plumbingIsDone: false,
  poolIsDugOut: false,
  raisedBeam: false,
  retainingWalls: false,
  notch: false,
}

const IntakeFormProvider = props => {
  const [builderInfo, setBuilderInfo] = useState(DEFAULT_BUILDER);
  const [jobDetails, setJobDetails] = useState([DEFAULT_JOB]);
  const [poolDetails, setPoolDetails] = useState([DEFAULT_POOL]);
  const [retrievedLocationsGunite, setRetrievedLocationsGunite] = useState([]);
  const [retrievedLocationsPlaster, setRetrievedLocationsPlaster] = useState([]);
  const [materialDropDownList, setMaterialDropDownList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [colorMap, setColorMap] = useState({});
  const [materialObjects, setMaterialObjects] = useState({});

  const { me, isLoggedIn } = useAuth()

  const destroyJobSpecificCookies = () => {
    cookies.erase('intakeFlowId');
    cookies.erase('currentPosition');
    setBuilderInfo(DEFAULT_BUILDER)
    setJobDetails([DEFAULT_JOB])
    setPoolDetails([DEFAULT_POOL])
  }

  useEffect(()=>{
    const currentFlow = cookies.get('intakeFlowId')
    if (me?.id && currentFlow) {
      const jobs = me.jobs.filter(job => job.intakeFlowId === parseInt(currentFlow))
      if (jobs.length > 0) {
        const jobsWithOwner = jobs.map(job =>({ ...job, ownerId: me.id }))
        setJobDetails(jobsWithOwner)
        const newPoolDetails = jobs.map(job => job.pools[0] ? job.pools[0] : { ...DEFAULT_POOL, jobId: job.id })
        setPoolDetails(newPoolDetails)
      }
    }
    if (me?.id){
      setBuilderInfo({
        ...me.customer,
        email: me.email,
      })
    }
  },[me, builderInfo.firstName])

  const getMaterialsForDropdowns = async () => {
    const res = await serviceMethods.getMaterials();
    const data = await res.json();

    setMaterialObjects(data);

    let materialList = [];
    let newColorMap = {};

    for (let i = 0; i < data.length; i++){ //go into data
      for (let j = 0; j < data[i].product.length; j++){ //go into product at [i]
        materialList.push(`${data[i].name} -- ${data[i].product[j]?.name}`) //concatenate manufacturer name and product name
        const productName = data[i]?.product[j]?.name; //retrieve matching productName
        newColorMap[productName] = [];
        for (let k = 0; k < data[i].product[j].tier.length; k++){ //go into tier
          for (let l = 0; l < data[i].product[j].tier[k].color.length; l++){ //go into color
            const colorName = data[i]?.product[j]?.tier[k]?.color[l]?.name; //retrieve first iteration colorName
            colorList.push(`${data[i]?.product[j]?.tier[k]?.color[l]?.name}`) //list of all colors
            newColorMap[productName].push(colorName);
          }
        }
      }
    }
    materialList.push('Other'); //Add an option of 'other' to every product/manufacturer item
    setMaterialDropDownList(materialList);
    setColorMap(newColorMap);
  };

  useEffect(()=>{
    if(isLoggedIn) getMaterialsForDropdowns()
  }, [isLoggedIn])

  const getLocationsForMap = async () => {
    const res = await serviceMethods.getLocationsForMap()
    const data = await res.json()
    
    const guniteLocations = data.filter(locationsGunite => locationsGunite.service === "GUNITE");
    setRetrievedLocationsGunite(guniteLocations);
    const plasterLocations = data.filter(locationsPlaster => locationsPlaster.service === "PLASTER");
    setRetrievedLocationsPlaster(plasterLocations);
  }

  useEffect(()=>{
    getLocationsForMap();
  },[])

  return (
    <IntakeFormContext.Provider value={{
      builderInfo, setBuilderInfo, jobDetails, setJobDetails,
      poolDetails, setPoolDetails, DEFAULT_POOL,
      destroyJobSpecificCookies, DEFAULT_JOB,
      materialDropDownList, colorMap, colorList, setColorList,
      retrievedLocationsGunite, retrievedLocationsPlaster,
      materialObjects, getLocationsForMap
    }}>
      {props.children}
    </IntakeFormContext.Provider>
  )
}

const useIntakeForm = () => useContext(IntakeFormContext)
export { useIntakeForm }
export default IntakeFormProvider
