import React, { useState, useEffect } from "react";
import { useScheduler } from "../../contexts/scheduler";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";

const MiniCalendarShiftSelector = ({ day, scheduleInfo, oldScheduleInfo, oldFlashScheduleInfo, toggle, setToggle, availability, isFlash }) => {
  const { selectedJobId, crews, allDrivers } = useScheduler()
  const { selectedCalendarJobId } = useJobDetailsModal()
  const [shiftArray, setShiftArray] = useState([])
  const [isAvailable, setIsAvailable] = useState({})

  const handleShiftSelection = (shift) => {
    if (!scheduleInfo?.current[day]) {
      scheduleInfo.current[day] = {}
    }
    const shiftIndex = shiftArray.indexOf(shift)
    if (shiftIndex !== -1) {
      const shiftRemoved = [...shiftArray.slice(0, shiftIndex), ...shiftArray.slice(shiftIndex + 1)]

      setShiftArray(shiftRemoved)
      scheduleInfo.current[day].shift = shiftRemoved
    } else {
      const shiftAdded = [...shiftArray, shift]
      setShiftArray(shiftAdded)
      scheduleInfo.current[day].shift = shiftAdded
    }

    // triggers useEffect in miniCalendarModal
    setToggle(!toggle)
  }

  const checkShiftAvailability = () => {
    if (availability.current[day]) {
      const updates = {}
      const allShoots = [1, 2, 3]

      // set defaults
      allShoots.forEach((shoot) => {
        if (updates[shoot] === undefined) {
          const defaultAvailability = {
            active: true
          }
          updates[shoot] = defaultAvailability
        }
      })

      const shoot1Crews = []
      const shoot2Crews = []
      const shoot3Crews = []

      const shoot1Drivers = []
      const shoot2Drivers = []
      const shoot3Drivers = []

      // collect + organize all ids that have a scheduled shift on the given day
      Object.keys(availability.current[day]).forEach((jobId) => {
        // exclude availability details / ids from job being rescheduled
        if (Number(jobId) === selectedJobId) return
        Object.keys(availability.current[day][jobId]).forEach((calJobId) => {
          // if (isFlash && Number(calJobId) === selectedCalendarJobId) return

          shoot1Crews.push(...availability.current[day][jobId][calJobId].crews.shoot1)
          shoot2Crews.push(...availability.current[day][jobId][calJobId].crews.shoot2)
          shoot3Crews.push(...availability.current[day][jobId][calJobId].crews.shoot3)

          shoot1Drivers.push(...availability.current[day][jobId][calJobId].drivers.shoot1)
          shoot2Drivers.push(...availability.current[day][jobId][calJobId].drivers.shoot2)
          shoot3Drivers.push(...availability.current[day][jobId][calJobId].drivers.shoot3)
        })
      })

      // check if current crew selection blocks / disables a shift
      scheduleInfo.current[day]?.crew?.forEach((id) => {
        if (shoot1Crews.includes(id)) {
          updates[1].active = false
        }
        if (shoot2Crews.includes(id)) {
          updates[2].active = false
        }
        if (shoot3Crews.includes(id)) {
          updates[3].active = false
        }
      })

      // check if current driver selection blocks / disables a shift
      scheduleInfo.current[day]?.drivers?.forEach((id) => {
        if (shoot1Drivers.includes(id)) {
          updates[1].active = false
        }
        if (shoot2Drivers.includes(id)) {
          updates[2].active = false
        }
        if (shoot3Drivers.includes(id)) {
          updates[3].active = false
        }
      })

      // check if shift has no crews available
      let shift1CrewsAvailable = false
      let shift2CrewsAvailable = false
      let shift3CrewsAvailable = false
      crews.forEach((crew) => {
        if (!shoot1Crews.includes(crew.id)){
          shift1CrewsAvailable = true
        }
        if (!shoot2Crews.includes(crew.id)) {
          shift2CrewsAvailable = true
        }
        if (!shoot3Crews.includes(crew.id)) {
          shift3CrewsAvailable = true
        }
      })
      if (!shift1CrewsAvailable) updates[1].active = shift1CrewsAvailable
      if (!shift2CrewsAvailable) updates[2].active = shift2CrewsAvailable
      if (!shift3CrewsAvailable) updates[3].active = shift3CrewsAvailable

      if (allDrivers.length) {
        // check if shift has no drivers available
        let shift1DriversAvailable = false
        let shift2DriversAvailable = false
        let shift3DriversAvailable = false

        allDrivers.forEach((driver) => {
          if (!shoot1Drivers.includes(driver.id)){
            shift1DriversAvailable = true
          }
          if (!shoot2Drivers.includes(driver.id)) {
            shift2DriversAvailable = true
          }
          if (!shoot3Drivers.includes(driver.id)) {
            shift3DriversAvailable = true
          }
        })
        if (!shift1DriversAvailable) updates[1].active = shift1DriversAvailable
        if (!shift2DriversAvailable) updates[2].active = shift2DriversAvailable
        if (!shift3DriversAvailable) updates[3].active = shift3DriversAvailable
      }

      setIsAvailable({ ...isAvailable, ...updates })
    }
  }

  useEffect(() => {
    if (!isFlash) {
      if (oldScheduleInfo.current?.days[day]) {
        if (!scheduleInfo?.current[day]) {
          scheduleInfo.current[day] = {}
        }
        setShiftArray(oldScheduleInfo.current?.days[day].shift)
        scheduleInfo.current[day].shift = oldScheduleInfo.current.days[day].shift
      }
    } else {
      if (oldFlashScheduleInfo.current?.days[day]) {
        if (!scheduleInfo?.current[day]) {
          scheduleInfo.current[day] = {}
        }
        setShiftArray(oldFlashScheduleInfo.current?.days[day].shift)
        scheduleInfo.current[day].shift = oldFlashScheduleInfo.current.days[day].shift
      }
    }
  }, [oldScheduleInfo.current.days?.length, oldFlashScheduleInfo.current.days?.length, isFlash, oldScheduleInfo.current.calendarJobs])


  useEffect(() => {
    checkShiftAvailability()
  }, [toggle])

  return (
    <div className='flex flex-col justifty-between w-full px-2' >
      <div className='flex justify-between border p-3 pt-0 mt-1 rounded shadow-sm bg-white'>
        <form className='flex w-full justify-around items-center'>

          {['Shoot1', 'Shoot2', 'Shoot3'].map((shift, i) => {
            return (
              <div className='flex flex-col items-center pt-3' key={`shift${i}-${day}`}>
                <label
                  className={`flex justify-center text-[12px] text-dark-blue py-[2px] px-3 rounded-2xl border ${shiftArray.includes(shift.replace('Shoot', '')) ? 'bg-dark-blue text-white' : 'bg-white'} ${(isAvailable[(i + 1)] && !isAvailable[(i + 1)].active) ? 'text-mini-calendar-light-gray' : ''}`}
                  htmlFor={`${shift}-${day}`}
                >
                  {shift}
                </label>
                <input
                  type='checkbox'
                  id={`${shift}-${day}`}
                  name='shiftSelector'
                  value={`${i + 1}`}
                  className='text-secondary-blue rounded h-[16px] w-[16px] border-navy-light hidden'
                  onChange={(e) => {
                    handleShiftSelection(`${i + 1}`)
                  }}
                  checked={shiftArray.includes(shift.replace('Shoot', ''))}
                  disabled={isAvailable[(i + 1)] && !isAvailable[(i + 1)].active}
                >
                </input>
              </div>
            )
          })}

        </form>
      </div>
    </div>
  )
}

export default MiniCalendarShiftSelector;
