/* External Imports */
import React, { useState, useRef } from 'react';
/* Internal Imports */
import { useAdmin } from '../../contexts/admin';
import Selector from "../Selector";
import { SERVICES, FIFTY_STATES } from '../../constants';
import service from '../../service';
import { useAuth } from '../../contexts/auth';
import PhoneInputBackend from '../PhoneInputBackend';
import { useBigSpinnerIcon } from '../../contexts/bigSpinnerIcon';

const AdminAddBranchModal = () => {
  const { setShowAddBranchModal, previewImage, setPreviewImage, fetchLocationsAndSetSelected } = useAdmin();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errors, setErrors] = useState({branchName: false, service: false});
  const hiddenFileInputBranchDetails = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { getProfile } = useAuth()
  const { setIsBigSpinnerShowing } = useBigSpinnerIcon()
  const [formData, setFormData] = useState({
    label: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    latitude: 0,
    longitude: 0,
    email: '',
    phone: '',
    service: '',
    image: null,
    isMapActive: true,
    isPortalActive: true
  })

  const handleAddBranch = async (e, branch) => {
    e.preventDefault()

    // validation logic
    const newErrors = {
      branchName: !branch.label,
      service: !branch.service,
    };
    setErrors(newErrors);

    if (!branch.label || !branch.service) { return }

    let updatedBranch = branch
    updatedBranch = {...branch, serviceString: branch.service.toUpperCase()}

    const formDataObj = new FormData();
    const file = hiddenFileInputBranchDetails.current.files[0]
    if (file) {
      formDataObj.append('attachment', file);
    }

    formDataObj.append('id', updatedBranch.id);
    formDataObj.append('label', updatedBranch.label || '');
    formDataObj.append('address', updatedBranch.address || '');
    formDataObj.append('city', updatedBranch.city || '');
    formDataObj.append('state', updatedBranch.state || '');
    formDataObj.append('latitude', Number(updatedBranch.latitude) || 0);
    formDataObj.append('longitude', Number(updatedBranch.longitude) || 0);
    formDataObj.append('zipCode', updatedBranch.zipCode || '');
    formDataObj.append('email', updatedBranch.email || '');
    formDataObj.append('phone', updatedBranch.phone || '');
    formDataObj.append('serviceString', updatedBranch.serviceString || '');
    formDataObj.append('isMapActive', String(updatedBranch.isMapActive));
    formDataObj.append('isPortalActive', String(updatedBranch.isPortalActive));

    await service.createLocation(formDataObj)
    
    // refresh after rounning service method
    await getProfile()
    setIsBigSpinnerShowing(false)
    await fetchLocationsAndSetSelected(formDataObj)
    setShowAddBranchModal(false)
  }

  const hideRemoveOption = formData?.image === null

  const handleFileChange = () => {
    const file = hiddenFileInputBranchDetails.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFormData({
        ...formData,
        image: file
      });
    }
  };

  const removeCurrentLogo = () => {
    setPreviewImage(null);
    setFormData({
      ...formData,
      image: null
    })
    hiddenFileInputBranchDetails.current.value = "";
  }

  return (
    <div className='fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center'>
      <div className={`absolute z-10 flex flex-col h-[80vh] sm:h-[700px] w-full sm:w-[600px] rounded-2xl border border-navy-light p-[20px] text-[14px] bg-navy-bright10 shadow-md justify-between items-center overflow-y-auto`}>
        <div className='w-full flex flex-col items-center'>
          <div className='w-full flex row justify-between items-center'>
            <h1 className='text-[20px] font-[600] text-left w-full'>
              Add branch
            </h1>
            <div className='p-1 cursor-pointer'
              onClick={() => setShowAddBranchModal(false)}
            >
              <img src='/JobDetails/close.svg' alt='close' className='h-[12px]' />
            </div>
          </div>
          <form className='w-full h-full' onSubmit={(e) => handleAddBranch(e, formData)}>
            <div className='pt-8 gap-4 lg:flex w-full'>
              <div className='w-full lg:w-1/2 flex-col sm:mr-[16px]'>
                <div className={`font-normal text-xs ${errors.branchName ? 'text-gunite-red' : ''}`}>
                  Branch name *
                </div>
                <input 
                  className='rounded border-navy-light text-dark-blue text-base w-full'
                  type='text'
                  id='adminBranchSetupLabel'
                  value={formData?.label || ''}
                  onChange={(e)=>{
                    const updatedLocation = {
                      ...formData,
                      label: e.target.value
                    }
                    setFormData(updatedLocation)
                    if (errors.branchName) {
                      const newErrors = {
                        ...errors,
                        branchName: false
                      }
                      setErrors(newErrors)
                    }
                  }}
                />
              </div>
              <div className='w-full lg:w-1/2 flex-col'>
                <div className='pt-8 lg:pt-0 font-normal text-xs'>
                  Address
                </div>
                <input 
                  className='rounded border-navy-light text-dark-blue text-base w-full'
                  value={formData?.address}
                  onChange={(e)=>{
                    const updatedLocation = {
                      ...formData,
                      address: e.target.value
                    }
                    setFormData(updatedLocation)
                  }}
                />
              </div>
            </div>

            <div className='pt-8 gap-4 lg:flex w-full'>
              <div className='w-full lg:w-1/2 flex-col'>
                <div className='font-normal text-xs'>
                  City
                </div>
                <input
                  className='rounded border-navy-light text-dark-blue text-base w-full'
                  value={formData?.city || ''}
                  onChange={(e)=>{
                    const updatedLocation = {
                      ...formData,
                      city: e.target.value
                    }
                    setFormData(updatedLocation)
                  }}
                />
              </div>
              <div className='w-full lg:w-1/4 flex-col pl-0 lg:pl-[16px]'>
                <div className='pt-8 lg:pt-0 font-normal text-xs'>
                  State
                </div>
                <Selector
                    value={formData?.state}
                    required={false}
                    placeholder={'State'}
                    options={FIFTY_STATES}
                    reducedSize={true}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        state: e.target.value
                      })
                    }}
                  />
              </div>
              <div className='w-full lg:w-1/4 flex-col'>
                <div className='pt-8 lg:pt-0 font-normal text-xs'>
                  Zip Code
                </div>
                <input
                  className='rounded border-navy-light text-dark-blue text-base w-full'
                  value={formData?.zipCode || ''}
                  onChange={(e)=>{
                    const value = e.target.value;
                    const isValidNumber = /^[0-9]{0,5}$/.test(value);
                    if (isValidNumber){
                      const updatedLocation = {
                        ...formData,
                        zipCode: e.target.value
                      }
                      setFormData(updatedLocation)
                    }
                  }}
                />
              </div>
            </div>

            <div className='pt-8 gap-4 lg:flex w-full'>
              <div className='w-full lg:w-1/2 flex-col sm:mr-[16px]'>
                <div className='font-normal text-xs'>
                  Latitude
                </div>
                <input
                  className='rounded border-navy-light text-dark-blue text-base w-full'
                  value={formData?.latitude || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow empty string, negative sign, or a valid float number
                    const isValidFloat = /^-?\d*\.?\d*$/.test(value);
                    if (isValidFloat || value === '' || value === '-') {
                      const updatedLocation = {
                        ...formData,
                        latitude: value
                      };
                      setFormData(updatedLocation);
                    }
                  }}
                  onBlur={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      const updatedLocation = {
                        ...formData,
                        latitude: value 
                      };
                      setFormData(updatedLocation);
                    }
                  }}
                />
              </div>
              <div className='w-full lg:w-1/2 flex-col'>
                <div className='pt-8 lg:pt-0 font-normal text-xs'>
                  Longitude
                </div>
                <input
                  className='rounded border-navy-light text-dark-blue text-base w-full'
                  value={formData?.longitude || ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow empty string, negative sign, or a valid float number
                    const isValidFloat = /^-?\d*\.?\d*$/.test(value);
                    if (isValidFloat || value === '' || value === '-') {
                      const updatedLocation = {
                        ...formData,
                        longitude: value
                      };
                      setFormData(updatedLocation);
                    }
                  }}
                  onBlur={(e) => {
                    const value = parseFloat(e.target.value);
                    if (!isNaN(value)) {
                      const updatedLocation = {
                        ...formData,
                        longitude: value 
                      };
                      setFormData(updatedLocation);
                    }
                  }}
                />
              </div>
            </div>
              
            <div className='pt-8 gap-4 lg:flex w-full'>
              <div className='w-full lg:w-1/2 flex-col sm:mr-[16px]'>
                <div className='font-normal text-xs'>
                  Email
                </div>
                <input 
                  className='rounded border-navy-light text-dark-blue text-base w-full'
                  value={formData?.email || ''}
                  onChange={(e)=>{
                    const updatedLocation = {
                      ...formData,
                      email: e.target.value
                    }
                    setFormData(updatedLocation)
                  }}
                />
              </div>
              <div className='w-full lg:w-1/2 flex-col'>
                <div className='pt-8 lg:pt-0 font-normal text-xs'>
                  Phone
                </div>
                <PhoneInputBackend
                    placeholder={''}
                    value={formData?.phone || ''}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        phone: e.target.value
                      })
                    }}
                  />
              </div>
            </div>
            
            <div className='pt-8 lg:flex w-full'>
              <div className='w-full lg:w-1/2 flex-col pr-0 lg:pr-[16px]'>
                <div className={`font-normal text-xs ${errors.service ? 'text-gunite-red' : ''}`}>
                  Service *
                </div>
                <Selector
                    value={formData?.service}
                    placeholder={'Service *'}
                    options={SERVICES}
                    reducedSize={true}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        service: e.target.value
                      })
                      if (errors.service) {
                        const newErrors = {
                          ...errors,
                          service: false
                        }
                        setErrors(newErrors)
                      }
                    }}
                  />
              </div>

              <div className='flex w-full pt-8 lg:pt-0 pl-0 lg:pl-[16px] lg:w-1/2 gap-4 items-center'>
                <div className='max-h-[68px] max-w-[68px]'>
                  <img crossOrigin='anonymous' src={previewImage || `${formData?.image ? `${apiUrl}/${formData?.image}` : '/main-logo-dark.png'}`} />
                </div>
                <input 
                  type='file'
                  className='hidden'
                  accept='image/*'
                  ref={hiddenFileInputBranchDetails} 
                  onChange={()=>handleFileChange()}
                />
                <button
                  type="button"
                  className='text-dark-blue text-base font-medium'
                  onClick={()=>{hiddenFileInputBranchDetails.current.click();}}
                >
                  Change logo
                </button>
                <button
                  type="button"
                  className={`text-gunite-red text-base font-medium ${hideRemoveOption ? 'hidden' : ''}`}
                  onClick={()=>removeCurrentLogo()}
                >
                  Remove
                </button>
              </div>
            </div>

            <div className='pt-8 gap-4 lg:flex w-full'>
              <div className='w-full lg:w-1/2 flex flex-row'>
                <input
                  type="checkbox"
                  className="mr-2 rounded checked:bg-dark-blue"
                  checked={formData.isMapActive === true}
                  onChange={(e)=>{
                  const updatedLocation = {
                    ...formData,
                    isMapActive: e.target.checked
                  }
                  setFormData(updatedLocation)
                }}
                />
                <div className={`font-normal text-xs`}>
                  Show on map
                </div>
              </div>
              <div className='flex flex-row w-full lg:w-1/2 pt-6 lg:pt-0 pl-0 lg:pl-[16px]'>
                <input
                  type="checkbox"
                  className="mr-2 rounded checked:bg-dark-blue"
                  checked={formData.isPortalActive}
                  onChange={(e)=>{
                    const updatedLocation = {
                      ...formData,
                      isPortalActive: e.target.checked
                    }
                    setFormData(updatedLocation)
                  }}
                />
                <div className={`font-normal text-xs`}>
                  Portal active
                </div>
              </div>
            </div>
            
            <div className='flex pt-8 justify-between'>
              <button 
                type="button"
                className={`text-sm font-semibold text-white py-[9px] px-3 bg-gunite-red rounded ${disableSubmit && 'opacity-40'}`}
                onClick={() => setShowAddBranchModal(false)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`text-sm font-semibold text-white py-[9px] px-3 bg-dark-blue rounded ${disableSubmit && 'opacity-40'}`}
              >
                Submit
              </button>
            </div>
          </form>
          
        </div> 

      </div>
    </div>
  )}

export default AdminAddBranchModal;
