import React, { useState } from 'react'
import _ from 'underscore';

const SelectorBackend = ({value, placeholder, options, onChange, disabled, jobDetails = false, required=true}) => {
  const [touched, setTouched] = useState(false)
  let isError = touched && !value
  if (!required) {
    isError = false
  }

  const uniqueId = `selector-${_.uniqueId()}`

  return (
    <div className='group relative w-full'>
      <select
        id={uniqueId}
        value={value}
        className={`w-full border border-[1px] text-dark-blue border-navy-light bg-white bg-opacity-90  px-2 py-2 ${jobDetails ? "focus:border-navy-light focus:ring-0 rounded-lg" : 'rounded'}`}
        onChange={(e) => {
          setTouched(false)
          onChange(e)}}
        onBlur={() => {
          setTouched(true)
        }}
        disabled={disabled}
      >
        {placeholder &&
          <option className="text-black/60" value={placeholder.value}>{placeholder.text}</option>
        }
        
        {options.map((option, i) => (
          <option key={i} className='text-black/60' value={option.value}>{option.text}</option>
        ))}
      </select>
    </div>
  )
}

export default SelectorBackend
