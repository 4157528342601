import React from 'react';
import cookies from 'browser-cookies'
import './index.scss';
import { useProgress } from '../../../contexts/progress';

const RequestSubmitted = () => {
  const { navigateToStart } = useProgress()
  return (
    <div className='flex flex-col items-center req_sub_outer_container p-16'>
      <p className='w-[450px] md:w-[800px] font-bold text-secondary-blue text-[34px] md:text-[60px] req_sub_header_top text-center'>
        Schedule Request Submitted
      </p>
      <img src='/green_check_circle.svg' />


      <p className='text-lg font-medium mt-5 w-80 text-center'>
        Your schedule request has been submitted, and someone will be in touch with you shortly.
      </p>
      <p className='mt-6 text-secondary-blue font-medium'>
        Thank you!
      </p>

      <div className='mt-[60px] py-3 px-9 bg-gunite-red text-white rounded req_sub_schedule_button_container'>
        <button
          className='text-2xl font-bold '
          onClick={() => {
            cookies.erase('currentPosition')
            navigateToStart()
          }}>
          Schedule New Job
        </button>
      </div>
    </div>
  )
}


export default RequestSubmitted;
