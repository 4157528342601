import React from "react";

function WarningMessage() {
    return (
      <div className="py-[6px] px-4 min-h-0 sm:min-h-[96px] md:min-h-0 bg-warning-yellow rounded flex">
        <img src='/vectors/warning.svg' className="my-[7px] mr-3 h-[22px]"/>
        <div className="flex flex-col gap-1 py-2">
          <h4 className="font-semibold text-warning-yellow-text">Important</h4>
          <p className="text-sm text-warning-yellow-text">The selected date is not a confirmed scheduled date — it still needs to be approved by our staff.</p>
        </div>
      </div>
    )
}

export default WarningMessage;
