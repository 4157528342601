import React, { useState, useEffect, useRef } from "react"
import { useSchedulerPlanner } from '../../contexts/schedulerPlanner'
import DropdownRadio from "../DropdownRadio"
import SearchColumn from "../SearchColumn"
import PlannerColumnMiniCalendarDropdown from "../PlannerColumnMiniCalendarDropdown"
import JobCard from "../JobCard"
import { DateTime } from 'luxon';
import './index.scss'

const PlannerColumn = ({ column, i, isPlaster }) => {
  const { toggle } = useSchedulerPlanner()
  const [sortBy, setSortBy] = useState('submit-date')
  const [query, setQuery] = useState('')
  const [hideHeader, setHideHeader] = useState(false)
  const [visibleJobs, setVisibleJobs] = useState([])
  const DEFAULT_SELECTED_DAYS = {
    day1: 0,
    month1: null,
    year1: 0,
    day2: 0,
    month2: null,
    year2: 0,
  }
  const [selectedDays, setSelectedDays] = useState(DEFAULT_SELECTED_DAYS)
  const jobColumnWrapper = useRef()

  const getDatesInRange = (selectedDays) => {
    const { day1, month1, year1, day2, month2, year2 } = selectedDays;

    //if only one day is selected
    if (day1 == 0) {
      return [
        new Date(year2, month2, day2).toISOString().split('T')[0]
      ]
    } else if (day2 == 0) {
      return [
        new Date(year1, month1, day1).toISOString().split('T')[0]
      ]
    }

    const startDate = new Date(year1, month1, day1);
    const endDate = new Date(year2, month2, day2);

    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dateArray.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  };

  const setTags = (job) => {
    const serviceTags = []

    let hasFlash = false
    job.calendarJobs.forEach((shift) => {
      if (shift.calendarJobType === 'FLASHING') {
        hasFlash = true
      }
    })
    if (hasFlash) {
      serviceTags.push({ letters: 'F', title: 'Flash' })
    }
    if (job?.isHudJob) {
      serviceTags.push({ letters: 'H', title: 'HUD Job' })
    }
    if (isPlaster) {
      if (job.pools) {
        if (job?.pools[0]?.newOrReplaster === 'Re-Plaster') {
          serviceTags.push({ letters: 'R', title: 'Re-Plaster' })
        }
        if (job?.pools[0]?.spa) {
          serviceTags.push({ letters: 'S', title: 'Spa' })
        }
        if (job?.pools[0]?.basin) {
          serviceTags.push({ letters: 'B', title: 'Basin' })
        }
      }
    } else {
      if (job?.guniteType === 'ASR Certification') {
        serviceTags.push({ letters: 'A', title: 'ASR' })
      }
      if (job?.waterproofing === true) {
        serviceTags.push({ letters: 'W', title: 'Waterproofing'})
      }

      if (job.pools) {
        if (job?.pools[0]?.newOrReplaster === 'Re-Plaster') {
          serviceTags.push({ letters: 'R', title: 'Re-Plaster' })
        }
        if (job?.pools[0]?.spa) {
          serviceTags.push({ letters: 'S', title: 'Spa' })
        }
        if (job?.pools[0]?.notch) {
          serviceTags.push({ letters: 'N', title: 'Notch' })
        }
        if (job?.pools[0]?.negativeEdge) {
          serviceTags.push({ letters: 'NE', title: 'Negative Edge' })
        }
        if (job?.pools[0]?.raisedBeam) {
          serviceTags.push({ letters: 'RB', title: 'Raised Beam' })
        }
        if (job?.pools[0]?.retainingWalls) {
          serviceTags.push({ letters: 'RW', title: 'Retaining Wall' })
        }
      }
    }

    return serviceTags
  }

  const handleHideHeader = (val) => {
    setHideHeader(val)
  }

  const handleQuery = (q = '') => {
    setQuery(q)

    const matches = []
    const searchQuery = q.toLowerCase()
    const jobs = column.jobList.current
    jobs.forEach((job) => {
      if (job.jobName?.toLowerCase().includes(searchQuery) ||
        job.address?.toLowerCase().includes(searchQuery) ||
        job.owner.customer.bizName?.toLowerCase().includes(searchQuery)) {
        matches.push(job)
      }
    })
    setVisibleJobs(matches)
    sortJobs(sortBy, matches)
  }

  //NOTE: I think we should compare jobs in the completed column based on completedAt, and jobs in the archive column based on submittedAt.  The issue with the archive column is that jobs may arrive in that column for many different reasons and in many states of completion.  They will all have a submittedAt value, though.
  const sortJobs = (sortOption, currentJobs = visibleJobs) => {
    const sortedJobs = [...currentJobs]
    if (sortOption === 'submit-date' && column.title === 'Archive') {
      sortedJobs.sort((a, b) => !a.submittedAt?.localeCompare(b.submittedAt)) //most recent first
    } else if (sortOption === 'submit-date' && column.title === 'Completed') {
      sortedJobs.sort((a, b) => !a.completedAt?.localeCompare(b.completedAt)) //most recent first
    } else if (sortOption === 'submit-date') {
      sortedJobs.sort((a, b) => a.submittedAt?.localeCompare(b.submittedAt))
    } else if (sortOption === 'request-date') {
      sortedJobs.sort((a, b) => a.requestedDate.localeCompare(b.requestedDate))
    } else if (sortOption === 'job-name') {
      sortedJobs.sort((a, b) => a.jobName.localeCompare(b.jobName))
    } else if (sortOption === 'builder-name') {
      sortedJobs.sort((a, b) => a.owner.customer.bizName.localeCompare(b.owner.customer.bizName))
    }
    setVisibleJobs(sortedJobs)
  }

  const updateJobList = () => {
    sortJobs(sortBy, column.jobList.current)
  }

  useEffect(() => {
    updateJobList()
  }, [column.jobList.current, toggle])

  useEffect(() => {
    sortJobs(sortBy)
  }, [sortBy])

  useEffect(() => {
    // search
    const matches = []
    const jobs = column.jobList.current
    jobs.forEach((job) => {
      if (job.jobName?.toLowerCase().includes(query) ||
        job.address?.toLowerCase().includes(query) ||
        job.owner.customer.bizName?.toLowerCase().includes(query)) {
        matches.push(job)
      }
    })

    // date range
    let dateField;
    switch (column.title) {
      case 'Intake':
        dateField = 'submittedAt'
        break
      case 'Backlog':
        dateField = 'backloggedAt'
        break
      case 'Ready for calendar':
        dateField = 'readyAt'
        break
      case 'Scheduled':
        dateField = 'scheduledAt'
        break
      case 'Gunite complete':
        dateField = 'workCompletedAt'
        break
      case 'Plaster complete':
        dateField = 'workCompletedAt'
        break
      case 'Completed':
        dateField = 'completedAt'
        break
      case 'Archive':
        dateField = 'archivedAt'
        break
      default:
        dateField = 'createdAt'
        break
    }

    const selectedDates =
      (selectedDays.day1 == 0 && selectedDays.day2 == 0) ?
        [] : getDatesInRange(selectedDays)

    const jobsFilteredByDate = selectedDates.length > 0
      ?
      matches.filter(job => {
        const jobISO = DateTime.fromJSDate(new Date(job[dateField])).toISODate();
        return selectedDates.some(d => {
          const dayISO = d

          return dayISO === jobISO;
        });
      })
      :
      matches;

    setVisibleJobs(jobsFilteredByDate)
    // sort
    sortJobs(sortBy, jobsFilteredByDate)
  }, [selectedDays])

  useEffect(() => {
    updateJobList()
  }, [])

  return (
    <div id={`${column.id$}Wrapper`} className={`h-full min-w-[320px] bg-white rounded-[8px] shadow-md px-[8px] pb-[8px] pt-[4px] ${column.hide ? 'hidden' : ''}`}>

      <div className='h-[32px] flex flex-row items-center justify-between select-none py-[8px] mb-[6px]'>
        <div className={`flex pl-[4px] ${hideHeader ? 'hidden' : ''}`}>
          <img src={column.icon} alt={`${column.title} icon`} className='p-[2px]' />

          <p className='text-[16px] font-[700] pl-[8px] '>
            {column.title}
          </p>
        </div>

        <div className={`relative flex items-center pr-[8px] ${hideHeader ? 'w-full' : ''}`}>
          <SearchColumn handleQuery={handleQuery} query={query} handleHideHeader={handleHideHeader} i={i} />
          <PlannerColumnMiniCalendarDropdown selectedDays={selectedDays} setSelectedDays={setSelectedDays} />
          <DropdownRadio sortBy={sortBy} setSortBy={setSortBy} i={i} />
        </div>
      </div>

      <div data-name={column.id} id={column.id} ref={jobColumnWrapper} className='column h-[calc(100%-32px)] overflow-y-auto droppable flex flex-col gap-[8px]'>

        {visibleJobs.map((job, i) => {
          const tags = setTags(job)
          return (
            <JobCard key={`job${job.id}`} job={job} tags={tags} />
          )
        })}

      </div>

    </div>
  )
}

export default PlannerColumn;
