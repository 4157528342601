import React, { useEffect } from 'react'
import { useIntakeForm } from "../../contexts/intakeForm"
import Selector from '../Selector'
import Input from "../Input"

const PlasterForm3c = ({ pool, i, setPoolDetails, poolDetails }) => {
  const {
    materialDropDownList, setColorList, colorMap, retrievedLocationsPlaster, jobDetails
  } = useIntakeForm();
  const materialAddInsError = !pool.materialAddIns

  useEffect(() => {
    if (poolDetails[i].material) {
      const productNameWords = poolDetails[i].material.split(' -- ')
      const productName = productNameWords[productNameWords.length - 1]
      const colorChoicesArray = colorMap[productName]
      setColorList(colorChoicesArray)
    }
  }, [])

  const filteredColorMapOptions = (colorMap, searchString) => {
    let flattenedValues;
    if (searchString) {
      const productNameWords = searchString.split(' -- ')
      const productName = productNameWords[productNameWords.length - 1]
      const filteredEntries = Object.entries(colorMap).filter(([key]) => key.includes(productName));
      const filteredValues = filteredEntries.map(([_, value]) => value);
      flattenedValues = filteredValues.flat();
      flattenedValues.push('Other')
    } else {
      flattenedValues = [];
    }
    return flattenedValues;
  }
  const locationLabel = retrievedLocationsPlaster.filter((location) => location?.id === jobDetails[0]?.locationId);

  return (
    <>
      <div className="flex flex-col gap-1">
        <p className="text-secondary-blue/60 text-base font-semibold">
          {poolDetails.length > 1 ?
            `Plaster selection for Job #${i + 1}: ${jobDetails[i].jobName}`
            :
            'Plaster selection'
          }
        </p>
      </div>
      <form className='flex flex-col gap-6'>
        {/* Is this a new pool or re-plaster select field */}
        <Selector
          value={poolDetails[i].newOrReplaster || ''}
          placeholder={'Is this a New Pool or Re-Plaster?'}
          options={['New Pool', 'Re-Plaster']}
          onChange={(e) => {
            const newPool = {
              ...pool,
              newOrReplaster: e.target.value
            }
            const newPoolDetails = [
              ...poolDetails.slice(0, i),
              newPool,
              ...poolDetails.slice(i + 1),
            ]
            setPoolDetails(newPoolDetails);
          }}
        />
        {/* Material select field */}
        <Selector
          value={poolDetails[i].material || ''}
          placeholder={"Material *"}
          options={materialDropDownList}
          onChange={(e) => {
            const newPool = {
              ...pool,
              material: e.target.value
            }
            const newPoolDetails = [
              ...poolDetails.slice(0, i),
              newPool,
              ...poolDetails.slice(i + 1),
            ]
            setPoolDetails(newPoolDetails);
            const productNameWords = e.target.value.split(' -- ')
            const productName = productNameWords[productNameWords.length - 1]
            const colorChoicesArray = colorMap[productName]
            setColorList(colorChoicesArray)
            if (!e.target.value) {
              const newPool = {
                ...pool,
                material: null,
                color: null
              }
              const newPoolDetails = [
                ...poolDetails.slice(0, i),
                newPool,
                ...poolDetails.slice(i + 1),
              ]
              setPoolDetails(newPoolDetails);
              setColorList([])
            }
          }}
        />

        {/* Color select field */}
        <Selector
          value={poolDetails[i].color || ''}
          placeholder={'Color *'}
          options={filteredColorMapOptions(colorMap, poolDetails[i].material)}
          onChange={(e) => {
            const newPool = {
              ...pool,
              color: e.target.value
            }
            const newPoolDetails = [
              ...poolDetails.slice(0, i),
              newPool,
              ...poolDetails.slice(i + 1),
            ]
            setPoolDetails(newPoolDetails);

          }}
          disabled={!poolDetails[i].material}
        />

        {/* Material add-ins field */}
        <Input
          value={poolDetails[i].materialAddIns || ''}
          placeholder={'Material add-ins'}
          onChange={(e) => {
            const newPool = {
              ...pool,
              materialAddIns: e.target.value
            }
            const newPoolDetails = [
              ...poolDetails.slice(0, i),
              newPool,
              ...poolDetails.slice(i + 1),
            ]
            setPoolDetails(newPoolDetails);
          }}
          isError={materialAddInsError}
        />
        <div className='flex gap-2 w-full items-center'>
          <input
            type='checkbox'
            id={`weWillPrep${i}`}
            value={poolDetails[i].weWillPrepPool || false}
            className='text-tertiary-blue'
            onChange={(e) => {
              const newPool = {
                ...pool,
                weWillPrepPool: e.target.checked
              }
              const newPoolDetails = [
                ...poolDetails.slice(0, i),
                newPool,
                ...poolDetails.slice(i + 1),
              ]
              setPoolDetails(newPoolDetails);
            }}
            checked={pool.weWillPrepPool}>
          </input>
          <label className='text-secondary-blue/60 font-semibold' htmlFor={`weWillPrep${i}`}>
            {`${locationLabel[0]?.label} will Clean/Prep this pool`}
          </label>
        </div>
        <div className='flex gap-2 w-full items-center'>
          <input
            type='checkbox'
            id={`estimate${i}`}
            value={poolDetails[i].estimateNeeded || false}
            className='text-tertiary-blue'
            onChange={(e) => {
              const newPool = {
                ...pool,
                estimateNeeded: e.target.checked
              }
              const newPoolDetails = [
                ...poolDetails.slice(0, i),
                newPool,
                ...poolDetails.slice(i + 1),
              ]
              setPoolDetails(newPoolDetails);
            }}
            checked={pool.estimateNeeded || false}>
          </input>
          <label className='text-secondary-blue/60 font-semibold' htmlFor={`estimate${i}`}>
            Do you need an estimate?
          </label>
        </div>
      </form>
    </>
  )
}

export default PlasterForm3c
