/* External Imports */
import React, { useState } from 'react';
/* Internal Imports */
import { useAdminMaterials } from '../../contexts/adminMaterials';

const AdminMaterialsAddOrEditProductModal = () => {
  const { addOrEditItemModal, setAddOrEditItemModal, handleAddOrEditItem, breadcrumbs, prevPageSelectedItem} = useAdminMaterials();

  const initialValues = addOrEditItemModal.editMode ? prevPageSelectedItem : {
    name: '',
    minimumTotalIA: 0,
    minimumPrice: 0,
    notes: '',
    totalIASmall: 0,
    totalIALarge: 0,
    spaAdd: 0
  }
  const [tempFormValues, setTempFormValues] = useState(initialValues)

  return (
    <div className='fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 flex justify-center items-center'>
      <div className={`absolute z-10 flex flex-col h-auto sm:h-[600px] w-full sm:w-[600px] rounded-2xl border border-navy-light p-[20px] text-[14px] bg-navy-bright10 shadow-md justify-between items-center`}>
        <div className='w-full flex flex-row justify-between items-center'>
          <h1 className='text-[20px] font-[600]'>
            {breadcrumbs?.currentPage === 'Products' ? 'Add product' :
              breadcrumbs?.currentPage === 'Tiers' ? 'Edit product' : ""
            }
          </h1>
          <div className='p-1 cursor-pointer'
            onClick={() => setAddOrEditItemModal({ ...addOrEditItemModal, open: false})}
          >
            <img src='/JobDetails/close.svg' alt='close' className='h-[12px]' />
          </div>
        </div>
        <div className='w-full'>
          
        <div className='w-full flex flex-col flex-start'>
          <div
            className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}
          >
            <span className="text-gray-blue py-1">Name *</span>
            <input
              className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
              type="text"
              disabled={false}
              value={tempFormValues.name}
              onChange={e => setTempFormValues({ ...tempFormValues, name: e.target.value })}
            />
          </div>
          <div className='flex flex-row justify-between'>
            <div
              className={`flex flex-col flex-grow w-[200px] items-start pb-[10px] pt-[3px]`}
            >
              <span className="text-gray-blue py-1">Minimum total IA</span>
              <input
                className="w-11/12 pr-3 focus:border-navy-light focus:ring-0 border border-[1px] text-dark-blue border-navy-light bg-white bg-opacity-90 rounded-lg py-2"
                type="number"
                disabled={false}
                value={tempFormValues.minimumTotalIA}
                onChange={e => setTempFormValues({ ...tempFormValues, minimumTotalIA: e.target.value })}
              />
            </div>
            <div
              className={`flex flex-col flex-grow w-[200px] items-end pb-[10px] pt-[3px]`}
            >
              <div className='flex flex-col justify-end w-11/12'>
                <span className="text-gray-blue py-1">Minimum price</span>
                <div className="relative w-11/12">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    className="w-full focus:border-navy-light pl-6 focus:ring-0 border border-[1px] text-dark-blue border-navy-light bg-white bg-opacity-90 rounded-lg py-2"
                    type="number"
                    disabled={false}
                    value={tempFormValues.minimumPrice}
                    onChange={e => setTempFormValues({ ...tempFormValues, minimumPrice: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}
          >
            <span className="text-gray-blue py-1">Notes</span>
            <input
              className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
              type="text"
              disabled={false}
              value={tempFormValues.notes}
              onChange={e => setTempFormValues({ ...tempFormValues, notes: e.target.value })}
            />
          </div>
          
          <h2 className="pt-5 pb-2 text-dark-blue text-xl font-semibold">Replaster</h2>
          <div className='flex flex-row justify-between'>
            <div
              className={`flex flex-col flex-grow w-[200px] items-start pb-[10px] pt-[3px]`}
            >
              <span className="text-gray-blue py-1">0'-1200' total IA add</span>
              <div className="relative w-11/12">
                <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                <input
                  className="w-11/12 pl-6 pr-3 focus:border-navy-light focus:ring-0 border border-[1px] text-dark-blue border-navy-light bg-white bg-opacity-90 rounded-lg py-2"
                  type="number"
                  disabled={false}
                  value={tempFormValues.totalIASmall}
                  onChange={e => setTempFormValues({ ...tempFormValues, totalIASmall: e.target.value })}
                />
              </div>
            </div>
            <div
              className={`flex flex-col flex-grow w-[200px] items-end pb-[10px] pt-[3px]`}
            >
              <div className='flex flex-col justify-end w-11/12'>
                <span className="text-gray-blue py-1">1200'-2000' total IA add</span>
                <div className="relative w-11/12">
                  <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
                  <input
                    className="pl-6 w-full focus:border-navy-light focus:ring-0 border border-[1px] text-dark-blue border-navy-light bg-white bg-opacity-90 rounded-lg py-2"
                    type="number"
                    disabled={false}
                    value={tempFormValues.totalIALarge}
                    onChange={e => setTempFormValues({ ...tempFormValues, totalIALarge: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}
          >
            <span className="text-gray-blue py-1">SPA add</span>
            <div className="relative w-11/12">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-dark-blue">$</span>
              <input
                className="pl-6 focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
                type="number"
                disabled={false}
                value={tempFormValues.spaAdd}
                onChange={e => setTempFormValues({ ...tempFormValues, spaAdd: e.target.value })}
              />
            </div>
          </div>

          <div className="flex flex-row mt-4 gap-3 justify-end items-center">
            <button
              onClick={() => setAddOrEditItemModal({ ...addOrEditItemModal, open: false})}
              className="py-[5px] rounded-[4px] bg-white border border-navy-light flex flex-row justify-center items-center px-2"
            >
              <p className="text-[13px] font-[450] text-dark-blue select-none">
                Cancel
              </p>
            </button>
            <button
              onClick={() => handleAddOrEditItem(addOrEditItemModal.editMode, tempFormValues)}
              className={`bg-dark-blue py-[5px] rounded-[4px] border border-navy-light flex flex-row justify-center items-center px-2 ${!tempFormValues.name ? 'cursor-not-allowed' : ''}`}
              disabled={!tempFormValues.name}
            >
              <p className="text-[13px] font-[450] text-white select-none" >
                {addOrEditItemModal.editMode ? 'Update' : 'Add'}
              </p>
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}

export default AdminMaterialsAddOrEditProductModal;
