import React, { useState, useEffect } from "react";
import DateSelector from "../DateSelector";
import { useScheduler } from "../../contexts/scheduler";
import serviceMethods from '../../service'
import { usePDF, Document, Page } from '@react-pdf/renderer';
import BulkBreakdownPDFTemplate from '../BulkBreakdownPDFTemplate'
import BulkJobLogPDFTemplate from '../BulkJobLogPDFTemplate'
import NoJobsPDFTemplate from '../NoJobsPDFTemplate'
import LoadingSpinner from '../LoadingSpinner'
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";

const AdminExports = () => {
  const { locationDropdownOptions } = useScheduler()
  const [locationOptions, setLocationOptions] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedLocations, setSelectedLocations] = useState([])
  const [isBreakdownPDF, setIsBreakdownPDF] = useState(true)
  const [loadingStarted, setLoadingStarted] = useState(false)
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false)
  const { setCurrentDay } = useJobDetailsModal()

  const breakdownName = `Bulk-Daily-Breakdowns_${(new Date(selectedDate)?.toISOString())?.substring(0,10)}`
  const jobLogName = `Bulk-Daily-JobLogs_${(new Date(selectedDate)?.toISOString())?.substring(0,10)}`

  const [jobLogInstance, updateJobLogInstance] = usePDF({
    document: (
      <Document title='default'>
        <Page size='A4'>
        </Page>
      </Document>
  )})
  const [breakdownInstance, updateBreakdownInstance] = usePDF({
    document: (
      <Document title='default'>
        <Page size='A4'>
        </Page>
      </Document>
  )})

  const handleDownload = async () => {
    if (selectedLocations.length === 0) return

    //
    const bulkPDFDataRes = await serviceMethods.getBulkPDFData({
      date: `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${(selectedDate.getDate()).toString().padStart(2, '0')}`,
      locationIds: selectedLocations
    })
    if (!bulkPDFDataRes) return

    const bulkPDFData = await bulkPDFDataRes.json()

    const dayJobs = []
    for (const location of bulkPDFData.locationData) {
      dayJobs.push(...location.jobs)
    }
    await generatePDF(selectedDate, dayJobs, bulkPDFData.locationData)

    setIsDownloadDisabled(true)
  }

  // generates the bulk daily report pdfs for the selected day
  const generatePDF = async (day, dayJobs, locationData) => {
    let breakdownDoc
    let jobLogDoc

    const calJobs = []
    for (const location of locationData) {
      for (const job of location.jobs) {
        for (const calJob of job.calendarJobs) {
          calJob.locationLabel = location.label
          calJobs.push(calJob)
        }
      }
    }

    if (calJobs.length) {
      breakdownDoc =(
        <BulkBreakdownPDFTemplate
          day={day}
          dayJobs={dayJobs}
          locationData={locationData}
        />
      )

      jobLogDoc = (
        <BulkJobLogPDFTemplate
          day={day}
          calJobs={calJobs}
        />
      )
    } else {
      breakdownDoc = (
        <NoJobsPDFTemplate day={day} />
      )
      jobLogDoc = (
        <NoJobsPDFTemplate day={day} />
      )
    }

    // update react-pdf/usePDF instances (bulk daily reports)
    if (isBreakdownPDF) {
      await updateBreakdownInstance(breakdownDoc)
    } else {
      await updateJobLogInstance(jobLogDoc)
    }

    setLoadingStarted(true)
  }

  const handleReset = () => {
    const today = new Date()
    setSelectedDate(today)
    setCurrentDay(today)
    setSelectedLocations([])
  }

  const handleSelectAll = () => {
    const allLocationIds = []
    for (const loc of locationDropdownOptions) {
      allLocationIds.push(loc.value)
    }
    setSelectedLocations(allLocationIds)
  }

  const handleDeselectAll = () => {
    setSelectedLocations([])
  }

  const handleLabelClick = (id) => {
    setSelectedLocations((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If already selected, remove from selected options
        return prevSelected.filter((optionId) => optionId !== id);
      } else {
        // Otherwise, add the id to selected options
        return [...prevSelected, id];
      }
    });
  };

  // triggers download once pdf is done loading
  useEffect(() => {
    if (isBreakdownPDF) {
      if (!breakdownInstance.loading && loadingStarted) {
        setLoadingStarted(false)
        const link = document.createElement('a');
        link.href = breakdownInstance.url;
        link.download = breakdownName; // Set the file name for the download
        link.click();
      }
    } else {
      if (!jobLogInstance.loading && loadingStarted) {
        setLoadingStarted(false)
        const link = document.createElement('a');
        link.href = jobLogInstance.url;
        link.download = jobLogName; // Set the file name for the download
        link.click();
      }
    }
  }, [breakdownInstance.loading, jobLogInstance.loading, loadingStarted])

  useEffect(() => {
    const newLocationOptions = []
    for (const loc of locationDropdownOptions) {
      newLocationOptions.push({
        label: loc.label,
        id: loc.value,
        selected: false
      })
    }
    setLocationOptions(newLocationOptions)
  }, [locationDropdownOptions])

  useEffect(() => {
    // enable download button on selection change
    setIsDownloadDisabled(false)
  }, [selectedDate, isBreakdownPDF, selectedLocations])

  return (
    <div>


      <h1 className="text-xl font-semibold text-dark-blue mb-6">
        Exports
      </h1>

      <section
        className={`flex flex-col w-full mb-6 items-start justify-between bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-2 min-[400px]:px-5 pt-4`}
      >
        <div className='flex w-full justify-between'>
          <h2 className="text-lg font-semibold text-dark-blue">
            Bulk PDF Download
          </h2>

          <div className='flex gap-2 p-1 rounded bg-white border'>
            <button
              onClick={() => {
                setIsBreakdownPDF(true)
              }}
              className={`rounded px-2 py-1 ${isBreakdownPDF ? 'bg-dark-blue text-white' : ''}`}
              disabled={loadingStarted}
            >
              Breakdowns
            </button>

            <button
              onClick={() => {
                setIsBreakdownPDF(false)
              }}
              className={`rounded px-2 py-1 ${!isBreakdownPDF ? 'bg-dark-blue text-white' : ''}`}
              disabled={loadingStarted}
            >
              Job Logs
            </button>
          </div>

        </div>
        <div className="flex flex-row w-full justify-around py-4 gap-4">
          <div className="flex flex-col w-[25%] items-center">
            <p className="text-dark-blue font-semibold text-sm mb-3">
              Select Date
            </p>

            <div className="flex flex-col w-fit border rounded">
              <DateSelector
                selectedDay={selectedDate}
                setSelectedDay={(date) => {
                  setSelectedDate(date)
                }}
                allowSelectPast={true}
                allowSelectFuture={false}
              />
            </div>
          </div>

          <div className="flex flex-col w-[50%] items-center">
            <p className="text-dark-blue font-semibold text-sm mb-3">
              Select Locations
            </p>

            <div className="flex flex-col w-full h-[215px] bg-white p-2 overflow-auto border rounded">
              {locationOptions.length === 0 ? <LoadingSpinner/> : locationOptions.map((location, i) => {
                return (
                  <div key={location.id} className="flex flex-col items-start gap-2">
                    <input
                      type="checkbox"
                      id={`checkbox-${location.id}`}
                      checked={selectedLocations.includes(location.id)}
                      onChange={() => {}}
                      style={{ display: 'none' }} // Hides the checkbox
                    />

                    <label
                      htmlFor={`checkbox-${location.id}`}
                      onClick={() => handleLabelClick(location.id)}
                      className={`
                        cursor-pointer
                        px-2 py-1 my-1
                        rounded-md
                        transition-colors
                        duration-300
                        text-sm
                        ${selectedLocations.includes(location.id) ? 'text-white' : 'text-dark-blue'}
                        ${selectedLocations.includes(location.id) ? 'bg-dark-blue' : 'bg-white'}
                        ${selectedLocations.includes(location.id) ? 'hover:bg-gunite-red' : 'hover:bg-dark-blue hover:text-white'}
                      `}
                    >
                      {location.label}
                    </label>
                  </div>
                )
              })}
            </div>

            <div className='flex gap-4 p-2'>
              <button
                type="button"
                className={`text-sm font-semibold py-[9px] px-3 rounded bg-white text-dark-blue border border-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue`}
                onClick={handleSelectAll}
                disabled={false}
              >
                Select All
              </button>

              <button
                type="button"
                className={`text-sm font-semibold py-[9px] px-3 rounded bg-white text-dark-blue border border-dark-blue hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue`}
                onClick={handleDeselectAll}
                disabled={false}
              >
                Deselect All
              </button>
            </div>
          </div>

        </div>
        <div className='flex w-full justify-center gap-4 pb-4'>
          <button
            type="button"
            className={`text-sm font-semibold py-2 px-3 h-[40px] rounded bg-white text-gunite-red border border-gunite-red hover:text-white hover:bg-gunite-red active:bg-white active:text-gunite-red`}
            onClick={handleReset}
            disabled={false}
          >
            Reset
          </button>
          <button
            onClick={handleDownload}
            type="button"
            className={`flex justify-center text-sm font-semibold py-2 px-3 w-[90px] h-[40px] rounded bg-white text-dark-blue border border-dark-blue ${isDownloadDisabled || selectedLocations.length === 0 ? 'text-opaque-job-details-gray bg-disabled-gray border-opaque-job-details-gray' : 'hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue'}`}
            disabled={isDownloadDisabled || selectedLocations.length === 0}
          >
            {loadingStarted ? <LoadingSpinner /> : 'Download'}
          </button>
        </div>
      </section>

      <div className='flex pt-6 justify-between'>

      </div>
    </div>
  );
}

export default AdminExports;
