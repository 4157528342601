import React, { useState } from 'react'
import _ from 'underscore';

const Selector = ({value, placeholder, options, onChange, disabled, reducedSize=false, whiteBackground=false, required=true}) => {
  const [touched, setTouched] = useState(false)
  const labelShown = !!value
  let isError = touched && !value
  if (!required) {
    isError = false
  }
  const selectStyles = () => {
    if (disabled && !reducedSize && !whiteBackground) {
      return `peer mt-0 block w-full px-3 border-0 border-b rounded-t bg-tertiary-blue/5 text-base pb-4 pt-4 text-black/20 border-black/50`
    } else if (!disabled && !reducedSize && !whiteBackground) {
      return `peer mt-0 block w-full px-3 border-0 border-b  focus:ring-0 rounded-t focus:border-black bg-tertiary-blue/5 text-base ${labelShown ? 'pt-6 pb-2 font-semibold' : 'pb-4 pt-4 text-black/60'} ${isError ? 'pb-4 pt-4 text-red-500 border-red-500' : 'border-black/42'}`
    } else if (disabled && reducedSize && !whiteBackground){
      return `peer mt-0 block w-full px-3 border-0 border-b rounded-t bg-tertiary-blue/5 text-base pb-4 pt-2 text-black/20 border-black/50`
    } else if (!disabled && reducedSize && !whiteBackground){
      return `peer mt-0 block w-full px-3 border-0 border-b focus:ring-0 rounded-t focus:border-black bg-tertiary-blue/5 text-base ${labelShown ? 'pt-2 pb-2 font-semibold' : 'pb-2 pt-2 text-black/60'} ${isError ? 'pb-2 pt-2 text-red-500 border-red-500' : 'border-black/42'}`
    } else if (whiteBackground && !disabled) {
      return `peer mt-0 block w-full px-3 border-0 border-b focus:ring-0 rounded-t focus:border-black bg-white text-base pt-6 pb-2 border-black/42`
    }
  }
  const uniqueId = `selector-${_.uniqueId()}`

  return (
    <div className='group relative w-full'>
      <select
        id={uniqueId}
        value={value}
        className={selectStyles()}
        onChange={(e) => {
          setTouched(false)
          onChange(e)}}
        onBlur={() => {
          setTouched(true)
        }}
        disabled={disabled}
      >
        {placeholder &&
          <option className="text-black/60" value="">{placeholder}</option>
        }
        
        {options.map((option, i) => (
          <option key={i} className='text-black/60' value={option}>{option}</option>
        ))}
      </select>
      <label
        htmlFor={uniqueId}
        className={`absolute left-3 top-2 text-xs text-black/60 block ${labelShown ? '' : "hidden"} ${reducedSize && 'hidden'}`}
      >{placeholder}</label>
    </div>
  )
}

export default Selector
