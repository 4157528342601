import React from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useModal } from '../../contexts/modal';
import JobLogPDFTemplate from '../JobLogPDFTemplate';
import UploadToOneDriveButton from '../UploadToOneDriveButton'

const JobLogPDFViewer = ({ calendarJob, selectedScheduler, selectedJob }) => {
  const { modalMethods } = useModal()

  return (
    <div className='flex justify-center items-center fixed w-screen h-screen top-0 left-0 bg-navy-light bg-opacity-60 z-10'>
      <div className="flex flex-col w-fit h-fit rounded-lg bg-white border border-navy-light p-6">
        <h2 className="text-lg font-bold text-dark-blue">Job Log Preview</h2>

        <div className='bg-[rgb(64,67,69)] p-1 rounded-md'>
          <PDFViewer className='h-[50vh] w-[420px]' showToolbar={false}>
              <JobLogPDFTemplate
                calendarJob={calendarJob}
              />
          </PDFViewer>
        </div>

        <div className="flex justify-center items-start gap-4 pt-4">

          <button
            onClick={modalMethods.close}
            className="bg-white text-dark-blue rounded-md border border-gray-300 py-2 px-4 hover:text-white hover:bg-dark-blue active:bg-white active:text-dark-blue active:border-gray-300"
          >
            Close
          </button>

          <PDFDownloadLink
            className="bg-white text-gunite-red border border-gunite-red rounded-md py-2 px-4 hover:text-white hover:bg-gunite-red active:bg-white active:text-gunite-red"
            fileName={`JobLog_${calendarJob.scheduledDate.substring(0,10)}_${calendarJob.job.jobName}_${calendarJob.id}`}
            document={
              <JobLogPDFTemplate
                calendarJob={calendarJob}
              />}
          >Download PDF</PDFDownloadLink>

          <UploadToOneDriveButton
            selectedScheduler={selectedScheduler}
            PDFTemplate={<JobLogPDFTemplate calendarJob={calendarJob} />}
            selectedJob={selectedJob}
            isBreakdown={false}
            selectedCalendarJob={calendarJob}
          />

        </div>
      </div>
    </div>
  )
};

export default JobLogPDFViewer;
