import React from "react";
import { useScheduler } from "../../contexts/scheduler";
import JobDetailsStatusCardCalendarJobData from '../JobDetailsStatusCardCalendarJobData'
import service from '../../service'

const JobDetailsStatusCardFlashing = () => {
  const { selectedJobId, selectedScheduler, setSelectedScheduler } = useScheduler();

  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)

  const jobHasFlashing = (selectedJob) => {
    for (const calendarJob of selectedJob.calendarJobs) {
      if (calendarJob.calendarJobType === 'FLASHING') {
        return true
      }
    }
    return false
  }

  const destructureFlashingCalendarJob = () => {
    const flashJobs = []
    for (const calendarJob of selectedJob.calendarJobs) {
      if (calendarJob.calendarJobType === 'FLASHING') {
        flashJobs.push(calendarJob)
      }
    }
    return flashJobs
  }

  const handleAddFlashing = async () => {
    const { updatedJob } = await service.createFlashingJob(selectedJobId)
    const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedJob.id)

    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        jobs: [
          ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
          updatedJob,
          ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
        ]
      }
    })
  }

  const markFlashDeleted = async (flashId) => {
    const { updatedJob } = await service.markCalendarJobDeletedFromPlanner({ ids: [flashId], jobId: selectedJobId, createActivityLog: true })
    const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedJob.id)

    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        jobs: [
          ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
          updatedJob,
          ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
        ]
      }
    })
  }

  return (
    <>

      {jobHasFlashing(selectedJob) && (
        <>
          {destructureFlashingCalendarJob().map((flash, i) => {
            return (
              <div className='p-3 bg-navy-bright/10 border-2 border-dark-blue' key={`flash${i}`}>
                <div className="mb-2 flex flex-row justify-between">
                  <div className='flex items-center'>
                    <img src='/JobDetails/flash_icon.svg' alt='Flash icon' />
                    <p className='ml-1 text-[14px] font-semibold text-dark-blue'>Flash</p>
                  </div>
                  {!flash?.jobLog?.submittedAt
                    ?
                    <button
                      className="text-gunite-red cursor-pointer"
                      onClick={() => { markFlashDeleted(flash.id) }}
                    >
                      <img
                        src='/JobDetails/trash_icon.svg'
                        alt='trashcan icon'
                        className='fill-gunite-red'
                      />
                    </button>
                    :
                    null
                  }
                </div>

                <JobDetailsStatusCardCalendarJobData calendarJob={flash} flashJob />
              </div>
            )
          })}
        </>
      )}
      <div className='flex items-center'>
        <img src='/JobDetails/flash_icon.svg' alt='Flash icon' />
        <div className="cursor-pointer flex" onClick={handleAddFlashing}>
          <img src='/JobDetails/add_icon.svg' alt='Add icon' />
          <p className='text-[14px] font-semibold text-dark-blue'> Add flash:</p>
        </div>
      </div>
    </>
  );
};

export default JobDetailsStatusCardFlashing;
