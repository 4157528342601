import React, { useState, useEffect, useRef } from "react";

const SearchColumn = ({ handleQuery, query, handleHideHeader, i }) => {
  const [searchActive, setSearchActive] = useState(false)

  const searchWrapper = useRef()
  const searchInput = useRef()

  const handleClickSearch = (e) => {
    if (!searchWrapper.current.contains(e.target) && searchInput.current.value === '') {
      setSearchActive(false)
      handleHideHeader(false)
    }
  }

  useEffect(() => {
    if (searchActive) searchInput.current.focus()
  },[searchActive])


  useEffect(() => {
    document.body.addEventListener('click', handleClickSearch)
    return () => {
      document.body.removeEventListener('click', handleClickSearch)
    }
  }, [])

  return (
    <div ref={searchWrapper} id={`searchWrapper${i}`} className={`flex items-center justify-between h-[32px] ${searchActive ? 'border w-[calc(100%-30px)] rounded-[4px]' : 'w-fit'}`}>
      <img
        src='/PlannerColumn/search_icon.svg'
        alt='search'
        className='py-[2px] px-[8px]'
        onClick={(e) => {
          if(!searchActive) {
            handleHideHeader(true)
            setSearchActive(true)
          }
        }}
      />

      <input
        ref={searchInput}
        className={`border-none focus:ring-0 h-full w-full ${searchActive ? '' : 'hidden'}`}
        value={query}
        onChange={(e) => {
          e.preventDefault()
          handleQuery(e.target.value)
        }}
      >
      </input>

      <img
        src='/PlannerColumn/close_icon.svg'
        alt='search'
        className={`close py-[2px] px-[8px] ${searchActive ? '' : 'hidden'}`}
        onClick={(e) => {
          handleQuery('')
          handleHideHeader(!searchActive)
          setSearchActive(!searchActive)
        }}
      />
    </div>
  )
}

export default SearchColumn;
