import React, { useState, useEffect, useRef } from "react";
import { MdOutlineClose } from 'react-icons/md';
import MiniCalendar from "../MiniCalendar";
import './index.scss'
import MiniCalendarDrivers from "../MiniCalendarDrivers";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import service from "../../service";
import MiniCalendarShiftSelector from "../MiniCalendarShiftSelector";
import MiniCalendarCrews from "../MiniCalendarCrews";
import { useAuth } from "../../contexts/auth";
import { useScheduler } from "../../contexts/scheduler";
import { useSchedulerJobsList } from "../../contexts/schedulerJobsList";

const MiniCalendarModal = () => {
  const { me } = useAuth()
  const { allDrivers, crews, selectedJobId, selectedScheduler, setSelectedScheduler } = useScheduler()
  const { showMiniCalendar, setShowMiniCalendar, setCurrentDay, today, isFlash, setIsFlash } = useJobDetailsModal()
  const { calendarJobs, plannerJobs } = useSchedulerJobsList()
  const [selectedDays, setSelectedDays] = useState([])
  const [isMultiDay, setIsMultiDay] = useState(false)
  const [rescheduleJob, setRescheduleJob] = useState(false)
  const [rescheduleFlash, setRescheduleFlash] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const isPlaster = selectedScheduler.location.service === 'PLASTER'

  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)

  const [toggle, setToggle] = useState(false)

  const availability = useRef({})
  const scheduleInfo = useRef({})
  const oldScheduleInfo = useRef({
    days: {}
  })
  const oldFlashScheduleInfo = useRef({
    days: {}
  })

  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  const handleScheduling = async () => {
    if ( (!isFlash && !rescheduleJob) || (isFlash && !rescheduleFlash && oldFlashScheduleInfo.current.blankFlashIds.length < 1) ) {
      const scheduledTime = Object.keys(scheduleInfo.current)[0]
      const jobDetails = {
        //used for activity log
        metadata: {
          scheduledDateTime: scheduledTime
        },
        performedById: me.id,

        //used for updating job
        days: {...scheduleInfo.current},
        isFlash,

        //used for both
        jobId: selectedJobId
      }
      const { updatedJob } = await service.createCalendarJobFromMiniCal(jobDetails)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedJob.id)

      setSelectedScheduler({
        ...selectedScheduler,
        location: {
          ...selectedScheduler.location,
          jobs: [
            ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
            updatedJob,
            ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)

          ]
        }
      })
      setShowMiniCalendar(false)

    } else {
      // handle reschedule
      const createDays = []
      const checkDays = []
      const deleteDays = []
      const newScheduleDays = Object.keys(scheduleInfo.current)
      let oldScheduledDays = isFlash ? Object.keys(oldFlashScheduleInfo.current.days) : Object.keys(oldScheduleInfo.current.days)

      newScheduleDays.forEach((keyDate) => {
        const index = oldScheduledDays.indexOf(keyDate)
        if (index > -1) {
          // match, add day to checkDays, check for differences
          checkDays.push(keyDate)
          // remove day from oldScheduleDays
          oldScheduledDays.splice(index, 1)

        } else {
          // no match found, add day to createDays
          createDays.push(keyDate)
        }

      })

      if (oldScheduledDays.length) {
        // days left in oldScheduledDays are not in the new schedule
        deleteDays.push(...oldScheduledDays)
      }

      checkDays.forEach((day) => {
        let changeDetected = false
        const newShift = [...scheduleInfo.current[day].shift]
        const oldShift = isFlash ? [...oldFlashScheduleInfo.current.days[day].shift] : [...oldScheduleInfo.current.days[day].shift]

        if (oldShift.length === newShift.length) {
          newShift.forEach((shift) => {
            if (!changeDetected && !oldShift.includes(shift)) {
              // no match means change detected
              changeDetected = true
              // add day to createDays
              createDays.push(day)
              // add day to deleteDays
              deleteDays.push(day)
            }
          })
        } else if (!changeDetected) {
          // not same length means change detected
          changeDetected = true
          createDays.push(day)
          deleteDays.push(day)
        }

        const newCrew = [...scheduleInfo.current[day].crew]
        const oldCrew = isFlash ? [...oldFlashScheduleInfo.current.days[day].crew] : [...oldScheduleInfo.current.days[day].crew]

        if (!changeDetected && oldCrew.length === newCrew.length) {
          newCrew.forEach((crewId) => {
            if (!changeDetected && !oldCrew.includes(crewId)){
              changeDetected = true
              createDays.push(day)
              deleteDays.push(day)
            }
          })
        } else if (!changeDetected) {
          changeDetected = true
          createDays.push(day)
          deleteDays.push(day)
        }

        const newDrivers = [...scheduleInfo.current[day].drivers]
        const oldDrivers = isFlash ? [...oldFlashScheduleInfo.current.days[day].drivers] : [...oldScheduleInfo.current.days[day].drivers]

        if (!changeDetected && oldDrivers.length === newDrivers.length) {
          newDrivers.forEach((driverId) => {
            if (!changeDetected && !oldDrivers.includes(driverId)){
              changeDetected = true
              createDays.push(day)
              deleteDays.push(day)
            }
          })
        } else if (!changeDetected) {
          changeDetected = true
          createDays.push(day)
          deleteDays.push(day)
        }

        // if no changes detected, do nothing with day
      })

      // makes an array of calendarJob ids to delete
      const deleteIds = []
      deleteDays.forEach((day) => {
        const ids = isFlash ? [...oldFlashScheduleInfo.current.days[day].calendarJobId] : [...oldScheduleInfo.current.days[day].calendarJobId]
        deleteIds.push(...ids)
      })

      if (isFlash) {
        deleteIds.push(...oldFlashScheduleInfo.current.blankFlashIds)
      }

      // makes an object containing all info needed to schedule calendarJobs
      const createObj = {}
      createDays.forEach((day) => {
        createObj[day] = scheduleInfo.current[day]
      })

      const updateObj = {
        deleteIds: deleteIds, //used for updating job
        create: {
          days: createObj, //used for updating job
          jobId: selectedJobId //used for both
        },
        isFlash, //used for updating job

        //used for activity log
        performedById: me.id,
        metadata: {
          scheduledDateTime: newScheduleDays[0]
        }
      }

      const { updatedJob } = await service.rescheduleCalendarJobFromMiniCal(updateObj)
      const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedJob.id)

      setSelectedScheduler({
        ...selectedScheduler,
        location: {
          ...selectedScheduler.location,
          jobs: [
            ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
            updatedJob,
            ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)

          ]
        }
      })
      setShowMiniCalendar(false)
      setRescheduleJob(false)
      setRescheduleFlash(false)

    }
  }
  // builds oldScheduleInfo / oldFlashScheduleInfo from selected job's calendarJobs
  const buildOldScheduleRefs = () => {
    oldScheduleInfo.current.days = {}
    oldScheduleInfo.current.calendarJobs = {}

    oldFlashScheduleInfo.current.days = {}
    oldFlashScheduleInfo.current.calendarJobs = {}
    oldFlashScheduleInfo.current.blankFlashIds = []

    let isReschedule = false
    let isFlashReschedule = false

    plannerJobs.forEach((job) => {
      if (job.id === selectedJobId) {

        const allDays = []
        const allFlashDays = []

        if (job.calendarJobs.length > 0) {
          job.calendarJobs.forEach((calJob) => {
            const date = new Date(calJob.scheduledDate)

            if (calJob.calendarJobType === 'FLASHING' && calJob.scheduledDate === null) {
              oldFlashScheduleInfo.current.blankFlashIds.push(calJob.id)
              return
            }

            // exclude dates from before today and shifts with a job log submitted
            const today = Date.now()
            if (date.getUTCFullYear() < today.getFullYear || date.getUTCMonth() < today.getMonth || date.getUTCDate() < today.getDate || !!calJob.jobLog) {
              return
            }

            const driverIds = []
            calJob.drivers.forEach((driver) => {
              driverIds.push(driver.driver.id)
            })

            const calJobObj = {
              id: calJob.id,
              day: date.toUTCString(),
              crew: calJob.crews[0].crew.id,
              shift: calJob.shift,
              fshift: calJob.fshift,
              drivers: driverIds
            }

            if (calJob.calendarJobType === 'REGULAR') {
              oldScheduleInfo.current.calendarJobs[calJob.id] = calJobObj

              if (oldScheduleInfo.current.days[date.toUTCString()]) {
                oldScheduleInfo.current.days[date.toUTCString()].calendarJobId.push(calJob.id)

                if (!oldScheduleInfo.current.days[date.toUTCString()].shift.includes(calJob.shift)) {
                  oldScheduleInfo.current.days[date.toUTCString()].shift.push(calJob.shift)
                }

                if (!oldScheduleInfo.current.days[date.toUTCString()].crew.includes(calJob.crews[0].crew.id)) {
                  oldScheduleInfo.current.days[date.toUTCString()].crew.push(calJob.crews[0].crew.id)
                }
              } else {
                oldScheduleInfo.current.days[date.toUTCString()] = {
                  crew: [calJob.crews[0].crew.id],
                  drivers: [...driverIds],
                  shift: [calJob.shift],
                  calendarJobId: [calJob.id]
                }

                allDays.push(date.toUTCString())
                isReschedule = true
              }
            } else if (calJob.calendarJobType === 'FLASHING') {
              oldFlashScheduleInfo.current.calendarJobs[calJob.id] = calJobObj

              if (oldFlashScheduleInfo.current.days[date.toUTCString()]) {
                oldFlashScheduleInfo.current.days[date.toUTCString()].calendarJobId.push(calJob.id)

                if (!oldFlashScheduleInfo.current.days[date.toUTCString()].shift.includes(calJob.fshift)) {
                  oldFlashScheduleInfo.current.days[date.toUTCString()].shift.push(calJob.fshift)
                }

                if (!oldFlashScheduleInfo.current.days[date.toUTCString()].crew.includes(calJob.crews[0].crew.id)) {
                  oldFlashScheduleInfo.current.days[date.toUTCString()].crew.push(calJob.crews[0].crew.id)
                }
              } else {
                oldFlashScheduleInfo.current.days[date.toUTCString()] = {
                  crew: [calJob.crews[0].crew.id],
                  drivers: [...driverIds],
                  shift: [calJob.fshift],
                  calendarJobId: [calJob.id]
                }

                allFlashDays.push(date.toUTCString())
                isFlashReschedule = true
              }
            }
          })
        }

        if ((!isFlash && allDays.length > 1) || (isFlash && allFlashDays.length > 1)) {
          setIsMultiDay(true)
        }
        if (isFlash) {
          setSelectedDays(allFlashDays)
        } else {
          setSelectedDays(allDays)
        }

      }
    })

    setRescheduleJob(isReschedule)
    setRescheduleFlash(isFlashReschedule)

  }

  // builds an availability reference from all calendarJobs
  const buildAvailability = () => {
    availability.current = {}
    if (calendarJobs.length) {

      calendarJobs.forEach((job) => {
        // ignore past days
        const today = new Date(Date.now())
        const shift = new Date(job.scheduledDate)
        if (shift.getUTCFullYear() < today.getFullYear() || shift.getUTCMonth() < today.getMonth() || shift.getUTCDate() < today.getDate()) {
          return
        }
        if (!availability.current[shift.toUTCString()]) {
          availability.current[shift.toUTCString()] = {}
        }
        if (!availability.current[shift.toUTCString()][job.jobId]) {
          availability.current[shift.toUTCString()][job.jobId] = {}
        }
        if (!availability.current[shift.toUTCString()][job.jobId][job.id]) {
          availability.current[shift.toUTCString()][job.jobId][job.id] = {
            crews: {
              shoot1: [],
              shoot2: [],
              shoot3: []
            },
            drivers: {
              shoot1: [],
              shoot2: [],
              shoot3: []
            },
            jobType: ''
          }
        }

        if (job.shift === '1' || job.fshift === '1') {
          availability.current[shift.toUTCString()][job.jobId][job.id].crews.shoot1.push(job.crews[0].crew.id)

          const driverIds = []
          if (job.drivers.length) {
            job.drivers.forEach((driver) => {
              if (!availability.current[shift.toUTCString()][job.jobId][job.id].drivers.shoot1.includes(driver.driver.id)
                && !driverIds.includes(driver.driver.id)) {
                driverIds.push(driver.driver.id)
              }
            })
          }
          availability.current[shift.toUTCString()][job.jobId][job.id].drivers.shoot1.push(...driverIds)

        } else if (job.shift === '2' || job.fshift === '2') {
          availability.current[shift.toUTCString()][job.jobId][job.id].crews.shoot2.push(job.crews[0].crew.id)

          const driverIds = []
          if (job.drivers.length) {
            job.drivers.forEach((driver) => {
              if (!availability.current[shift.toUTCString()][job.jobId][job.id].drivers.shoot2.includes(driver.driver.id)
                && !driverIds.includes(driver.driver.id)) {
                driverIds.push(driver.driver.id)
              }
            })
          }
          availability.current[shift.toUTCString()][job.jobId][job.id].drivers.shoot2.push(...driverIds)

        } else if (job.shift === '3' || job.fshift === '3') {
          availability.current[shift.toUTCString()][job.jobId][job.id].crews.shoot3.push(job.crews[0].crew.id)

          const driverIds = []
          if (job.drivers.length) {
            job.drivers.forEach((driver) => {
              if (!availability.current[shift.toUTCString()][job.jobId][job.id].drivers.shoot3.includes(driver.driver.id)
                && !driverIds.includes(driver.driver.id)) {
                driverIds.push(driver.driver.id)
              }
            })
          }
          availability.current[shift.toUTCString()][job.jobId][job.id].drivers.shoot3.push(...driverIds)

        }

        availability.current[shift.toUTCString()][job.jobId][job.id].jobType = job.calendarJobType

      })
    }
  }

  useEffect(() => {
    setCurrentDay(today)
    buildOldScheduleRefs()
    buildAvailability()

    if (!showMiniCalendar) {
      setIsFlash(false)
      setRescheduleJob(false)
      setRescheduleFlash(false)
    }
  }, [showMiniCalendar, plannerJobs, isFlash])

  useEffect(() => {
    buildOldScheduleRefs()
    buildAvailability()
    setIsFlash(false)
    if ((selectedJob.estimatedIA === 0 && selectedJob.estimatedYards === 0) || !!selectedJob.workCompletedAt || !!selectedJob.completedAt || !!selectedJob.archivedAt) {
      setShowMiniCalendar(false)
    }
  }, [selectedJobId])



  useEffect(() => {
    // removes days no longer selected from scheduleInfo
    Object.keys(scheduleInfo.current).forEach((scheduleDay) => {
      let deleteKey = true
      selectedDays.forEach((day) => {
        if (day.toString() === scheduleDay) {
          deleteKey = false
        }
      })

      if (deleteKey) {
        delete scheduleInfo.current[scheduleDay]
      }
    })

    selectedDays.forEach((day) => {
      if (!scheduleInfo.current[day]) {
        scheduleInfo.current[day] = {
          crew: [],
          drivers: [],
          shift: []
        }
      }
    })

    buildAvailability()
    setToggle(!toggle)
  }, [selectedDays])

  // verifies that a day, shift and crew have been selected
  const validateSelection = () => {
    let isValid = true
    if (selectedDays.length > 0) {
      selectedDays.forEach((day) => {
        if (
          !scheduleInfo.current[day]?.shift?.length
          || !scheduleInfo.current[day]?.crew?.length
        ) {
          isValid = false
        }
      })
    } else {
      isValid = false
    }
    return isValid
  }

  useEffect(() => {
    setIsDisabled(!validateSelection())
  }, [toggle])

  return (
    <div
      className={`fixed md:static z-90 bg-white rounded-2xl overflow-hidden h-full w-full md:max-w-[276px] md:mx-5 flex flex-col justify-between border  shadow ${isFlash ? 'border-dark-blue' : 'border-navy-light'} ${showMiniCalendar ? '' : 'hidden'}`}
      onMouseDown={handleModalContentClick}
      onClick={handleModalContentClick}
    >

      <div className={`flex justify-between items-center p-4   border-b  ${isFlash ? 'bg-dark-blue' : 'bg-navy-bright bg-opacity-10 border-navy-light'}`}>
        <h2 className={`text-xl font-semibold ${isFlash ? 'text-white' : 'text-dark-blue'}`}>
          {`${(!rescheduleJob && !isFlash) || (!rescheduleFlash && isFlash) ? 'Schedule' : ''}${(rescheduleJob && !isFlash) || (rescheduleFlash && isFlash) ? 'Reschedule' : ''} ${isFlash ? 'flash' : 'job'}`}
        </h2>
        <button
          className="flex items-center justify-center text-black bg-transparent w-8 h-8 rounded-md"
          onClick={() => {
            setShowMiniCalendar(false)
          }}
        >
          <MdOutlineClose className={`cursor-pointer  text-md ${isFlash ? 'text-white' : 'text-black'}`}/>
        </button>
      </div>

      <div className='modalWrapper flex flex-col h-full overflow-auto flex-start'>
        <div className='flex flex-col w-full h-fit border-b p-3'>

          <div className='flex items-center pb-3'>
            <img className='bg-card-bg-hover rounded-2xl p-[3px] h-[28px] w-[28px] mr-2' alt="calendar icon" src='/JobDetails/calendar_month.svg' />
            <p>Date</p>
          </div>

          <MiniCalendar
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            isMultiDay={isMultiDay}
            availability={availability}
          />

          <div className='flex items-center text-dark-blue pl-2'>
            <input
              type='checkbox'
              id='multi-day-job'
              value={isMultiDay}
              className='text-secondary-blue rounded h-[16px] w-[16px] border-navy-light'
              onChange={(e) => {
                if (isMultiDay) {
                  const newSelectedDays = []
                  if (selectedDays[0]) {
                    newSelectedDays.push(selectedDays[0])
                  }
                  setSelectedDays(newSelectedDays)
                }
                setIsMultiDay(!isMultiDay);
              }}
              checked={isMultiDay}>
            </input>
            <label
              className='pl-2 text-[14px]'
              htmlFor='multi-day-job'
            >
              Multi-day job
            </label>
          </div>

        </div>

        {selectedDays.map((day, i) => {
          const isEven = (i % 2) === 0
          const dayObject = new Date(day)
          return (
            <div className={`${isEven ? '' : 'bg-subtle-grey'}`} key={`selectedDay${i}-${day}`}>
              <p className='flex justify-center pt-4 w-full text-dark-blue font-bold'>
                {`${dayObject.getUTCMonth() + 1}/${dayObject.getUTCDate()}/${dayObject.getUTCFullYear()}`}
              </p>
              <div className='flex flex-col w-full h-fit p-3'>
                <div className='flex items-center pb-1'>
                  <img className='bg-card-bg-hover rounded-2xl p-[4px] h-[28px] w-[28px] mr-2' alt="calendar icon" src='/JobDetails/shifts_icon.svg' />
                  <p>Shift</p>
                </div>

                <MiniCalendarShiftSelector
                  key={`shiftSelector${i}-${day}`}
                  day={day}
                  scheduleInfo={scheduleInfo}
                  oldScheduleInfo={oldScheduleInfo}
                  oldFlashScheduleInfo={oldFlashScheduleInfo}
                  toggle={toggle}
                  setToggle={setToggle}
                  availability={availability}
                  isFlash={isFlash}
                />

              </div>

              <div className='flex flex-col w-full h-fit p-3'>
                <div className='flex items-center pb-1'>
                  <img className='bg-card-bg-hover rounded-2xl p-[3px] h-[28px] w-[28px] mr-2' alt="calendar icon" src='/JobDetails/crews_icon.svg' />
                  <p>Crews</p>
                </div>

                <MiniCalendarCrews
                  day={day}
                  crews={crews}
                  // crewColors={crewColors}
                  scheduleInfo={scheduleInfo}
                  oldScheduleInfo={oldScheduleInfo}
                  oldFlashScheduleInfo={oldFlashScheduleInfo}
                  toggle={toggle}
                  setToggle={setToggle}
                  availability={availability}
                  isFlash={isFlash}
                />

              </div>

              <div className={`flex flex-col w-full h-fit p-3 border-b ${isPlaster ? 'hidden' : ''}`}>
                <div className='flex items-center pb-1'>
                  <img className='bg-card-bg-hover rounded-2xl p-[3px] h-[28px] w-[28px] mr-2' alt="calendar icon" src='/JobDetails/drivers_icon.svg' />
                  <p>Drivers</p>
                </div>

                <MiniCalendarDrivers
                  day={day}
                  allDrivers={allDrivers}
                  selectedDays={selectedDays}
                  scheduleInfo={scheduleInfo}
                  oldScheduleInfo={oldScheduleInfo}
                  oldFlashScheduleInfo={oldFlashScheduleInfo}
                  toggle={toggle}
                  setToggle={setToggle}
                  availability={availability}
                  isFlash={isFlash}
                />

              </div>
            </div>
          )
        })}

      </div>

      <div className="bg-opacity-10 bg-navy-bright border-t border-navy-light flex justify-end p-4 border-t">
        <button
          className="flex flex-row justify-center items-center border border-navy-light flex-grow bg-white text-dark-blue py-2 px-4 rounded mr-2"
          onClick={() => {
            setShowMiniCalendar(false)
          }}
        >
          Cancel
        </button>
        <div className='w-2'/>
        <button
          className={`flex flex-row justify-center items-center border border-navy-light flex-grow py-2 px-4 rounded ${isDisabled ? 'bg-subtle-grey text-card-accent-hover' : 'bg-dark-blue text-white'}`}
          onClick={() => {
            handleScheduling()
          }}
          disabled={isDisabled}
        >
          {rescheduleJob ? 'Reschedule' : 'Schedule'}
        </button>
      </div>

    </div>
  )
}

export default MiniCalendarModal;
