import React from "react";
import { MdOutlineClose } from "react-icons/md";
import { useScheduler } from "../../contexts/scheduler";
import { useSchedulerJobsList } from "../../contexts/schedulerJobsList";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import { useAuth } from "../../contexts/auth";
import serviceMethods from "../../service";
import JobDetailsTransferJobDropdown from "../JobDetailsTransferJobDropdown";

const JobDetailsHeaderBar = () => {
  const { me } = useAuth();
  const {
    selectedScheduler,
    selectedJobId,
    setSelectedJobId,
    setSelectedScheduler
  } = useScheduler();
  const { plannerJobs } = useSchedulerJobsList();
  const { handleCloseJobDetailsModal, selectedJobDetailsTab, isCostBreakdownChange, setShowCostBreakdownWarning } = useJobDetailsModal();

  const selectedJob = selectedScheduler.location.jobs.find(
    (item) => item.id === selectedJobId
  );

  const handleArchive = async () => {
    const jobDetails = {
      id: selectedJobId,
      activityType: 'archive',
      performedById: me.id
    }
    const { updatedJob } = await serviceMethods.updateJobStatus(jobDetails);
    const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedJob.id)

    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        jobs: [
          ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
          updatedJob,
          ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
        ]
      }
    })
  };

  const handleChangeJob = (direction) => {
    const selectedJobIndex = plannerJobs.findIndex(
      (job) => job.id === selectedJobId
    );

    if (selectedJobDetailsTab === 'Cost Breakdown' && isCostBreakdownChange.current) {
      setShowCostBreakdownWarning({
        show: true,
        continueFunction: () => { handleChangeJob(direction) }
      })
      return
    }

    if (direction == "prev") {
      let prevJob
      if (selectedJobIndex == 0) {
        prevJob = plannerJobs[plannerJobs.length - 1]
      } else {
        prevJob = plannerJobs[selectedJobIndex - 1]
      }
      setSelectedJobId(prevJob.id);
    } else if (direction == "next") {
      let nextJob;
      if (selectedJobIndex == plannerJobs.length - 1) {
        nextJob = plannerJobs[0];
      } else {
        nextJob = plannerJobs[selectedJobIndex + 1];
      }
      setSelectedJobId(nextJob.id);
    }
  };

  return (
    <div className="flex justify-between items-center p-4  bg-opacity-10 bg-navy-bright border-b border-navy-light">
      <h2 className="text-xl font-semibold text-dark-blue">
        {selectedJob?.jobName || ''}
      </h2>
      <div className="flex flex-row justify-center items-center">
        <button
          className="flex items-center justify-center h-full w-fit p-[8px] py-[5px] rounded-[4px] bg-white border border-navy-light"
          onClick={handleArchive}
        >
          <img
            src="/JobDetails/archived_icon.svg"
            alt="archived"
            className="mr-1.5 select-none w-[14px]"
          />
          <p className="text-[14px] font-[450] text-dark-blue select-none">Archive</p>
        </button>
        <JobDetailsTransferJobDropdown />
        <div className="ml-2 flex items-center justify-center h-full w-fit rounded-[4px] bg-white border border-navy-light">
          <div
            onClick={() => handleChangeJob("prev")}
            className="cursor-pointer h-full p-[8px] border-r-[1px] border-navy-light"
          >
            <img
              src="/JobDetails/chevron_left.svg"
              alt="back"
              className="select-none w-[9px]"
            />
          </div>
          <p className="text-[14px] font-[450] text-dark-blue select-none px-[8px]">
            Next job
          </p>
          <div
            onClick={() => handleChangeJob("next")}
            className="cursor-pointer h-full p-[8px]"
          >
            <img
              src="/JobDetails/chevron_right.svg"
              alt="forward"
              className="select-none w-[9px]"
            />
          </div>
        </div>
        <button
          onClick={() => {
            if (selectedJobDetailsTab === 'Cost Breakdown' && isCostBreakdownChange.current) {
              setShowCostBreakdownWarning({ show: true, continueFunction: () => { handleCloseJobDetailsModal() } })
              return
            }
            handleCloseJobDetailsModal()
          }}
          className="ml-2 flex items-center justify-center text-black bg-gunite-red w-8 h-8 rounded-md"
        >
          <MdOutlineClose
            // onClick={handleCloseJobDetailsModal}
            className="cursor-pointer text-white text-md"
          />
        </button>
      </div>
    </div>
  );
};

export default JobDetailsHeaderBar;
