export const FIFTY_STATES = [
  'Alaska',
  'Alabama',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

//this is used in <SelectorBackend>
export const FIFTY_STATES_AS_OBJECTS = [
  { text: 'Alaska', value: 'Alaska'},
  { text: 'Alabama', value: 'Alabama'},
  { text: 'Arizona', value: 'Arizona'},
  { text: 'Arkansas', value: 'Arkansas'},
  { text: 'California', value: 'California'},
  { text: 'Colorado', value: 'Colorado'},
  { text: 'Connecticut', value: 'Connecticut'},
  { text: 'Delaware', value: 'Delaware'},
  { text: 'Florida', value: 'Florida'},
  { text: 'Georgia', value: 'Georgia'},
  { text: 'Hawaii', value: 'Hawaii'},
  { text: 'Idaho', value: 'Idaho'},
  { text: 'Illinois', value: 'Illinois'},
  { text: 'Indiana', value: 'Indiana'},
  { text: 'Iowa', value: 'Iowa'},
  { text: 'Kansas', value: 'Kansas'},
  { text: 'Kentucky', value: 'Kentucky'},
  { text: 'Louisiana', value: 'Louisiana'},
  { text: 'Maine', value: 'Maine'},
  { text: 'Maryland', value: 'Maryland'},
  { text: 'Massachusetts', value: 'Massachusetts'},
  { text: 'Michigan', value: 'Michigan'},
  { text: 'Minnesota', value: 'Minnesota'},
  { text: 'Mississippi', value: 'Mississippi'},
  { text: 'Missouri', value: 'Missouri'},
  { text: 'Montana', value: 'Montana'},
  { text: 'Nebraska', value: 'Nebraska'},
  { text: 'Nevada', value: 'Nevada'},
  { text: 'New Hampshire', value: 'New Hampshire'},
  { text: 'New Jersey', value: 'New Jersey'},
  { text: 'New Mexico', value: 'New Mexico'},
  { text: 'New York', value: 'New York'},
  { text: 'North Carolina', value: 'North Carolina'},
  { text: 'North Dakota', value: 'North Dakota'},
  { text: 'Ohio', value: 'Ohio'},
  { text: 'Oklahoma', value: 'Oklahoma'},
  { text: 'Oregon', value: 'Oregon'},
  { text: 'Pennsylvania', value: 'Pennsylvania'},
  { text: 'Rhode Island', value: 'Rhode Island'},
  { text: 'South Carolina', value: 'South Carolina'},
  { text: 'South Dakota', value: 'South Dakota'},
  { text: 'Tennessee', value: 'Tennessee'},
  { text: 'Texas', value: 'Texas'},
  { text: 'Utah', value: 'Utah'},
  { text: 'Vermont', value: 'Vermont'},
  { text: 'Virginia', value: 'Virginia'},
  { text: 'Washington', value: 'Washington'},
  { text: 'West Virginia', value: 'West Virginia'},
  { text: 'Wisconsin', value: 'Wisconsin'},
  { text: 'Wyoming', value: 'Wyoming'},
];

export const FIFTY_STATES_OBJECT = {
  'Alaska': 'AK',
  'Alabama': 'AL',
  'Arizona': 'AZ',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY',
};

export const SERVICES = [
  'Gunite',
  'Plaster'
]

export const SERVICES_OBJECTS = [
  { text: 'Gunite', value: "Gunite"},
  { text: 'Plaster', value: 'Plaster'}
]