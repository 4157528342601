import React, { useContext, useState, useEffect } from 'react';
import JobsList from '../../../components/JobsList';
import serviceMethods from '../../../service'
import { useCustomerDrafts } from '../../../contexts/customerDrafts';
import { useAuth } from '../../../contexts/auth';
import HeaderBar from '../../../components/JobsListHeaderBar'
import NewJobButton from '../../../components/JobsListNewJobButton'
import DropdownMenu from '../../../components/CustomerDraftsDropdownRadio'

const CustomerDraftsView = () => {
  const { 
    currentPage, setCurrentPage, 
    searchTerm, setSearchTerm, 
    displayJobs, allDraftJobs,
    selectedStatuses, setSelectedStatuses, 
    jobType, setJobType,
    sortBy, setSortBy, RESULTS_PER_PAGE,
    ListItem, ColumnHeaders
  } = useCustomerDrafts();

  const { getProfile, isFetchingProfile } = useAuth();

  useEffect(()=>{
    if (!isFetchingProfile) getProfile();
  },[])

  return (
    <div className='flex flex-col items-center h-full w-full lg:mx-auto
    min-h-[calc(100vh-280px)] lg:min-h-[calc(100vh-176px)] p-8 lg:gap-8'>
      <div className='hidden lg:flex flex-row justify-between items-center w-full'>
        <h1 className='text-dark-blue bg-white text-3xl font-semibold '>Drafts</h1>
        <NewJobButton />
      </div>
      <JobsList
        displayJobs={displayJobs}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        jobType={jobType}
        setJobType={setJobType}
        sortBy={sortBy}
        setSortBy={setSortBy}
        allJobs={allDraftJobs}
        resultsPerPage={RESULTS_PER_PAGE}
        ListItem={ListItem}
        ColumnHeaders={ColumnHeaders}
        DropdownMenu={DropdownMenu}
      />
    </div>
  );
};

export default CustomerDraftsView;
