import { useState, useEffect, useRef } from 'react';

const DropdownMenu = ({ sortBy, setSortBy, i }) => {
    const [showSortMenu, setShowSortMenu] = useState(false);
    const dropdownWrapperRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(event.target)) {
          setShowSortMenu(false);
        }
      };

      document.body.addEventListener('click', handleClickOutside);

      return () => {
        document.body.removeEventListener('click', handleClickOutside);
      };
    }, [showSortMenu]);

    return (
      <div ref={dropdownWrapperRef} id={`dropdownRadioWrapper${i}`}>
        <img
          src='/PlannerColumn/sort_icon.svg'
          alt='sort'
          className="cursor-pointer min-w-[30px] py-[2px] px-[8px]"
          style={{ height: `18px` }}
          onClick={(e) => {
            e.preventDefault()
            setShowSortMenu(!showSortMenu)
          }}
        />

        <div
          className={`absolute flex flex-col justify-between h-fit w-fit p-[8px] bg-white border border-navy-light rounded-[8px] shadow-md text-[14px] ${showSortMenu ? '' : 'hidden'}`}
          style={{ right: `.1rem`, top: `3rem` }}
        >
        <div
            className='flex items-center w-[285px] h-[32px] p-[6px]'
            onClick={(e) => {
              e.preventDefault()
              setSortBy('submittedAt')
            }}
          >
            <img src={sortBy === 'submittedAt' ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px]' />
            <p className='pl-[10px]'>
              Form submit date: from newest
            </p>
          </div>

          <div
            className='flex items-center  w-[285px] h-[32px] p-[6px]'
            onClick={(e) => {
              e.preventDefault()
              setSortBy('requestedDate')
            }}
          >
            <img src={sortBy === 'requestedDate' ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px]' />
            <p  className='pl-[10px]'>
              Requested job date: from most urgent
            </p>
          </div>

          <div
            className='flex items-center  w-[285px] h-[32px] p-[6px]'
            onClick={(e) => {
              e.preventDefault()
              setSortBy('jobName')
            }}
          >
            <img src={sortBy === 'jobName' ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px]' />
            <p  className='pl-[10px]'>
              Job name: A to Z
            </p>
          </div>

          <div
            className='flex items-center  w-[285px] h-[32px] p-[6px]'
            onClick={(e) => {
              e.preventDefault()
              setSortBy('bizName')
            }}
          >
            <img src={sortBy === 'bizName' ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px]' />
            <p  className='pl-[10px]'>
              Poolbuilder: A to Z
            </p>
          </div>

        </div>
      </div>
    )
  }

  export default DropdownMenu;
