import React from 'react'
import { useAuth } from '../../contexts/auth'

const AccountPage = () => {
  const { me } = useAuth()

  return (
    <div className='overflow-auto w-full'>
      <div className='flex flex-col gap-3 w-full p-4 lg:p-0 lg:w-2/3 lg: mx-auto lg:my-4 lg:border lg:rounded-2xl lg:border-navy-light'>
        <div className='flex flex-col gap-3 lg:gap-0'>
          <div className='bg-navy-bright/30 hidden lg:inline rounded-t-2xl border-b border-navy-light p-6'>
            <p className='text-dark-blue text-xl font-semibold'>Account</p>
          </div>
          <div className='contents lg:flex lg:flex-col lg:gap-6 lg:p-6'>

            <p className='text-xl text-dark-blue font-bold'>Profile</p>
            <form className='grid grid-cols-2 gap-4'>
              <div className='flex flex-col gap-1'>
                <label
                  htmlFor='name'
                  className='text-sm text-gray-blue'
                >Name</label>
                <input
                  className='rounded'
                  value={me.name}
                  type='text'
                  id='name'
                  onChange={(e) => {
                    e.preventDefault()
                  }}
                />
              </div>

              <div className='flex flex-col gap-1'>
                <label
                  htmlFor='email'
                  className='text-sm text-gray-blue'
                >Email Address</label>
                <input
                  className='rounded bg-navy-light/50'
                  value={me.email}
                  type='text'
                  id='email'
                  onChange={(e) => {
                    e.preventDefault()
                  }}
                  disabled={true}
                />
              </div>

              <div className='flex flex-col gap-1'>
                <label
                  htmlFor='role'
                  className='text-sm text-gray-blue'
                >Role</label>
                <input
                  className='mt-0 block w-full pr-3 py-2 focus:ring-0 rounded placeholder-transparent text-opaque-job-details-gray border-0 bg-disabled-gray'
                  value={me.role}
                  type='text'
                  id='role'
                  onChange={(e) => {
                    e.preventDefault()
                  }}
                  disabled
                />

              </div>
              <div className={`flex flex-col gap-1 ${me.role === 'ADMIN' ? 'hidden' : ''}`}>
                <label
                  htmlFor='branch'
                  className='text-sm text-gray-blue'
                >Branch</label>
                <input
                  className='mt-0 block w-full pr-3 py-2 focus:ring-0 rounded placeholder-transparent text-opaque-job-details-gray border-0 bg-disabled-gray'
                  type='text'
                  id='branch'
                  onChange={(e) => {
                    e.preventDefault()
                  }}
                  disabled
                />
              </div>


              <div className={`flex flex-col gap-1 ${me.role !== 'SCHEDULER' ? 'hidden' : ''}`}>
                <label
                  htmlFor='phone'
                  className='text-sm text-gray-blue'
                >Phone Number</label>
                <input
                  className='rounded'
                  type='text'
                  id='phone'
                  onChange={(e) => {
                    e.preventDefault()
                  }}
                />
              </div>
              <div className='col-span-2'>
                <input
                  className='bg-dark-blue text-white font-medium py-2 px-3 rounded'
                  type='submit'
                />
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountPage
