import { useEffect, useState, useRef } from "react";
import MiniCalendar from "../MiniCalendar";
import { MdOutlineDateRange } from "react-icons/md";

const PlannerColumnMiniCalendarDropdown = ({selectedDays, setSelectedDays}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownWrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownWrapperRef} >
      <MdOutlineDateRange className="text-mini-calendar-gray" 
      color="pale-green"
      onClick={(e) => {
        e.preventDefault()
        setShowDropdown(!showDropdown)
        }}
      />

      {showDropdown && (
        <div className="absolute mt-1 right-0 bg-white border border-navy-light rounded-[8px] shadow-md px-3 pt-2 pb-1">
          <MiniCalendar
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            isMultiDay={true}
            allowSelectPast={true}
            rangeSelection={true}
          />
        </div>

      ) }
    </div>

  )
}

export default PlannerColumnMiniCalendarDropdown;