import React from "react"
import { useIntakeForm } from "../../../contexts/intakeForm"
import { useResponsive } from '../../../contexts/responsive';
import ProgressPaneDesktop from "../../../components/ProgressPaneDesktop";
import ProgressPaneMobile from "../../../components/ProgressPaneMobile";
import PlasterForm3c from "../../../components/PlasterForm3c";
import { useProgress } from '../../../contexts/progress'
import service from "../../../service"
import "./index.scss";
import Trashcan from "../../../components/Trashcan";

const PlasterIntake3c = () => {
  const { poolDetails, setPoolDetails, jobDetails } = useIntakeForm();
  const { navigateNext, navigatePrevious } = useProgress();
  const { isMobileView } = useResponsive();

  const updateJob3c = async (e) => {
    e.preventDefault()
    const res = await service.updateJob3c({
      jobs: jobDetails, //just here because the function expects it 
      pools: poolDetails
    })
    if (res.ok) {
      navigateNext()
    }
  }

  const isError = () => {
    let error = false
    poolDetails.forEach((pool) => {
      if (!pool.newOrReplaster
        || !pool.color
        || !pool.material
      ) {
        error = true
      }
    })
    return error
  }

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className="lg:w-1/3">
        {isMobileView ?
          <ProgressPaneMobile />
          :
          <ProgressPaneDesktop />
        }
      </div>
      <div className={`p-6 flex flex-col gap-12
          lg:p-20 lg:w-full lg:mb-0
          ${isMobileView &&
        'plaster_intake_3c_content_container_mobile'
        }
      `}>
        {/* Note: this div hidden in mobile */}
        <div className="hidden lg:flex flex-col gap-6">
        <button onClick={() => { navigatePrevious() }}>
            <div className="flex gap-2 items-center">
              <img alt="back" src="/vectors/redChevron.svg" className="h-[9px]" />
              <p className="font-semibold text-gunite-red">Back</p>
            </div>
          </button>
          <div className="flex flex-col gap-1">
            <p className="text-secondary-blue font-bold text-4xl">Job site information</p>
            <p className="text-secondary-blue/60 font-semibold text-base">Information about job site, address,
              pool plan, and job site supervisor.</p>
          </div>
          <div className="flex gap-[2px] items-center">
            {/* progress bar */}
            <div className="h-2 w-1/4 bg-primary-light" />
            <div className="h-2 w-1/4 bg-primary-light" />
            <div className="h-2 w-1/4 bg-gunite-red" />
            <div className="h-1 w-1/4 bg-black/50" />
          </div>
        </div>

        {poolDetails.length > 1
          ?
          poolDetails.map((pool, i) => (
            <>
              <Trashcan
                i={i}
                key={`trashcan${i}`}
              />
              <PlasterForm3c
                pool={pool}
                i={i}
                key={`PlasterForm3c${i}`}
                setPoolDetails={setPoolDetails}
                poolDetails={poolDetails}
              />
            </>
          ))
          :
          <PlasterForm3c
            pool={poolDetails[0]}
            i={0}
            key={`PlasterForm3c${0}`}
            setPoolDetails={setPoolDetails}
            poolDetails={poolDetails}
          />}


        <button
          className={`w-full rounded p-3 lg:py-3 lg:px-9 lg:w-auto self-start text-2xl font-bold ${isError() ? 'bg-black/10 text-black/40 cursor-not-allowed' : 'bg-gunite-red text-white  cursor-pointer'}`}
          disabled={isError()}
          onClick={updateJob3c}
        >Continue</button>
      </div>
    </div>
  )
}

export default PlasterIntake3c;
