import React, { useState, useEffect } from "react";
import PaginationFooterBar from '../PaginationFooterBar'
import PopupSearchBox from '../PopupSearchBox'
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { useAdmin } from "../../contexts/admin";
import NewAdminModal from "../NewAdminModal";
import { useModal } from "../../contexts/modal";

const BranchMgrsForm = () => {
  const { 
    newAdminSearchTerm, setNewAdminSearchTerm,
    newAdminFormSearching, setSelectedAdmin, RESULTS_PER_PAGE,
    needToUpdateAdmins, setNeedToUpdateAdmins,
    paginate, branchSpecificAdmins, setBranchSpecificAdmins, selectedLocation, getUpdatedAdminList,
    sort, setSortBy, allAdminUsers
  } = useAdmin();
  const { modal, modalMethods } = useModal();
  const { setIsEditing } = modal;

  const [adminsToDisplay, setAdminsToDisplay] = useState([]);
  const [filteredAdminsBeforePagination, setFilteredAdminsBeforePagination] = useState([])
  const [branchAdminCurrentPage, setBranchAdminCurrentPage] = useState(1);
  const [branchAdminSortAscending, setBranchAdminSortAscending] = useState(true);

  useEffect(() => {
    const adminsToPaginate = !newAdminFormSearching
      ? branchSpecificAdmins?.map(admin => ({
          name: admin.name,
          email: admin.email
        }))
      : branchSpecificAdmins
          ?.filter(item =>
            item.name.toLowerCase().includes(newAdminSearchTerm?.toLowerCase())
          )
          .map(admin => ({
            name: admin.name,
            email: admin.email
          }))
    
    const sortedAdmins = sort(adminsToPaginate)
    if (!branchAdminSortAscending) sortedAdmins.reverse()
    const updatedAdmins = paginate(branchAdminCurrentPage, sortedAdmins)
    setFilteredAdminsBeforePagination(adminsToPaginate)
    setAdminsToDisplay(updatedAdmins);
  }, [newAdminFormSearching, branchSpecificAdmins, newAdminSearchTerm, branchAdminSortAscending]);

  useEffect(()=>{
    setBranchAdminCurrentPage(1)
  },[newAdminSearchTerm, selectedLocation])

  useEffect(()=>{
    setSortBy('name')
    setBranchAdminSortAscending(true)
    setNeedToUpdateAdmins(false)
  },[])

  useEffect(()=>{
    if (!branchSpecificAdmins.length) return
    const adminsToPaginate = !newAdminFormSearching
      ? branchSpecificAdmins.map(admin => ({
          name: admin.name,
          email: admin.email
        }))
      : branchSpecificAdmins
          ?.filter(item =>
            item.name.toLowerCase().includes(newAdminSearchTerm?.toLowerCase())
          )
          .map(admin => ({
            name: admin.name,
            email: admin.email
          }))
    const sortedAdmins = sort(adminsToPaginate)
    if (!branchAdminSortAscending) sortedAdmins.reverse()
    const updatedAdmins = paginate(branchAdminCurrentPage, sortedAdmins)
    setFilteredAdminsBeforePagination(adminsToPaginate)
    setAdminsToDisplay(updatedAdmins)
  },[branchAdminCurrentPage, branchSpecificAdmins])

  const toggleNameSorting = (e) => {
    e.preventDefault() //without this the Name column click will behave erratically
    if (branchAdminSortAscending) {
      const adminsToPaginate = !newAdminFormSearching
      ? branchSpecificAdmins.map(admin => ({
          name: admin.name,
          email: admin.email
        }))
      : branchSpecificAdmins
          ?.filter(item =>
            item.name.toLowerCase().includes(newAdminSearchTerm?.toLowerCase())
          )
          .map(admin => ({
            name: admin.name,
            email: admin.email
        }))
      setBranchAdminSortAscending(false)
      const sortedAdmins = sort(adminsToPaginate)
      sortedAdmins.reverse();
      const updatedAdmins = paginate(branchAdminCurrentPage, sortedAdmins)
      setAdminsToDisplay(updatedAdmins)
      setFilteredAdminsBeforePagination(adminsToPaginate)
    } else {
      const adminsToPaginate = !newAdminFormSearching
        ? branchSpecificAdmins.map(admin => ({
            name: admin.name,
            email: admin.email
          }))
        : branchSpecificAdmins
            ?.filter(item =>
              item.name.toLowerCase().includes(newAdminSearchTerm?.toLowerCase())
            )
            .map(admin => ({
              name: admin.name,
              email: admin.email
            }))
      setBranchAdminSortAscending(true)
      sort(adminsToPaginate)
      const updatedAdmins = paginate(branchAdminCurrentPage, adminsToPaginate)
      setFilteredAdminsBeforePagination(adminsToPaginate)
      setAdminsToDisplay(updatedAdmins)
    }
  }

  return (
    <>
      <div className={`bg-white rounded-2xl my-6 flex flex-col border border-navy-light relative z-0`}>
        <div className="h-16 relative flex justify-between items-center px-6 rounded-t-xl border-b border-navy-light bg-opacity-20 bg-navy-bright">
          <h1 className="text-xl font-semibold text-dark-blue">Branch Managers</h1>
          <PopupSearchBox searchTerm={newAdminSearchTerm} setSearchTerm={setNewAdminSearchTerm} />
        </div>
        <div className="flex py-2 px-3 border-navy-light bg-opacity-20 bg-navy-bright justify-between">
          <div className='flex flex-row w-1/2 mr-4 items-center' >
            <button
              onClick={(e)=>{toggleNameSorting(e)}}
              className='text-gray-blue text-sm whitespace-nowrap pl-3 pr-1'
            >
              Name
            </button>
            {branchAdminSortAscending ?
              <button>
                <FaArrowUp className="text-gray-blue text-xs pl-1" onClick={(e)=>{toggleNameSorting(e)}} />
              </button>
              :
              <button>
                <FaArrowDown className="text-gray-blue text-xs pl-1" onClick={(e)=>{toggleNameSorting(e)}} />
              </button>
            }
          </div>
        </div>

        {adminsToDisplay?.map((admin, index) => (
          <div className="flex flex-row py-2 px-6 border-b border-navy-light" key={`adminNamesNewAdminForm-${index}`}>
            <div className='w-1/2 mr-4 flex-shrink-0'>
              <p className="cursor-pointer text-dark-blue font-medium text-md w-full truncate"
                onClick={(e)=>{
                  const matchingAdmin = branchSpecificAdmins.find(user => user.email === admin.email)
                  e.preventDefault()
                  setIsEditing(true)
                  const adminToSendToModal = { 
                    id: matchingAdmin.id, name: admin.name, email: admin.email,
                    locationId: selectedLocation?.id
                  }
                  setSelectedAdmin(adminToSendToModal)
                  modalMethods.setContent(
                    <NewAdminModal 
                      selectedAdmin={adminToSendToModal}
                      setSelectedAdmin={setSelectedAdmin}
                      branchSpecificAdmins={branchSpecificAdmins}
                      setBranchSpecificAdmins={setBranchSpecificAdmins}
                      needToUpdateAdmins={needToUpdateAdmins}
                      setNeedToUpdateAdmins={setNeedToUpdateAdmins}
                      getUpdatedAdminList={getUpdatedAdminList}
                      allAdminUsers={allAdminUsers}
                    />
                  )
                  modalMethods.open()
                }}>
                {admin?.name}
              </p>
            </div>
          </div>
        ))}

        <PaginationFooterBar 
          currentPage={branchAdminCurrentPage}
          setCurrentPage={setBranchAdminCurrentPage}
          allItems={filteredAdminsBeforePagination}
          resultsPerPage={RESULTS_PER_PAGE}
        />
      </div>

      <div className='flex-row'>
        <button
          className='w-full border rounded border-navy-light px-2 py-3 flex items-center justify-center'
          onClick={(e)=>{
            e.preventDefault()
            const adminToSendToModal = {
              locationId: selectedLocation?.id
            }
            modalMethods.setContent(
              <NewAdminModal
                selectedAdmin={adminToSendToModal}
                setSelectedAdmin={setSelectedAdmin}
                branchSpecificAdmins={branchSpecificAdmins}
                setBranchSpecificAdmins={setBranchSpecificAdmins}
                needToUpdateAdmins={needToUpdateAdmins}
                setNeedToUpdateAdmins={setNeedToUpdateAdmins}
                getUpdatedAdminList={getUpdatedAdminList}
                allAdminUsers={allAdminUsers}
              />
            )
            modalMethods.open();
          }}
        >
          <img src='/plus_sign.svg' className='mb-[2px]'/>
          <div className='text-dark-blue font-semibold text-sm'>
            Add Branch Manager
          </div>
        </button>
      </div>
    </>
  );
}

export default BranchMgrsForm;


