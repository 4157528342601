import JobStatusIcon from "../JobStatusIcon";
import { useScheduler } from "../../contexts/scheduler";
import JobDetailsStatusCardFlashing from "../JobDetailsStatusCardFlashing";
import JobDetailsStatusCardCalendarJobData from "../JobDetailsStatusCardCalendarJobData";
import service from '../../service';

const JobDetailsStatusCard = () => {
  const { selectedJobId, selectedScheduler, setSelectedScheduler } = useScheduler();

  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)
  let firstCalJob = {}

  const areMultipleRegularCalendarJobs = () => {
    var count = 0
    firstCalJob = {}
    for (const calendarJob of selectedJob.calendarJobs) {
      if (calendarJob.calendarJobType === 'REGULAR') {
        if (count === 0) {
          firstCalJob = calendarJob
        }
        count += 1
      }
    }
    return count > 1
  }

  const markCalendarJobDeleted = async (cjId) => {
    let total = 0
    for (const calJob of selectedJob.calendarJobs) {
      if (calJob.calendarJobType === 'REGULAR') {
        total++
      }
    }

    const { updatedJob } = await service.markCalendarJobDeletedFromPlanner({ ids: [cjId], jobId: selectedJobId, createActivityLog: true })
    const selectedJobIndex = selectedScheduler.location.jobs.findIndex(job => job.id === updatedJob.id)

    setSelectedScheduler({
      ...selectedScheduler,
      location: {
        ...selectedScheduler.location,
        jobs: [
          ...selectedScheduler.location.jobs.slice(0, selectedJobIndex),
          updatedJob,
          ...selectedScheduler.location.jobs.slice(selectedJobIndex + 1)
        ]
      }
    })
  }

  return (
    <div className='mb-8 p-4 w-[calc(100%)] lg:w-[calc(50%-12px)] rounded-xl border border-navy-light'>
      <p className='text-xl mb-2 font-bold text-secondary-blue'>Status</p>
      <div className='flex flex-col gap-3'>
        <div className='flex gap-2 items-center'>
          <img src='/JobDetails/status_icon.svg' alt='Status icon' />
          <p className='text-[14px] text-gray-blue'>Job Status:</p>
          <JobStatusIcon job={selectedJob} px={'px-2'} py={'py-1'} />
          {/* The following block displays the trashcan icon when the job has only 1 regular calendar job scheduled */}
          {!areMultipleRegularCalendarJobs()
            && Object.keys(firstCalJob).length > 0
            && !firstCalJob?.jobLog?.submittedAt
            ? <div className='grow flex justify-end'>
              <button
                className="text-gunite-red cursor-pointer"
                onClick={() => { markCalendarJobDeleted(firstCalJob.id) }}
              >
                <img
                  src='/JobDetails/trash_icon.svg'
                  alt='trashcan icon'
                />
              </button>
            </div>
            : ''
          }
        </div>
        {!areMultipleRegularCalendarJobs() //this ternary operator displays the scheduling information for either a single shift if there is just 1 regular calendar job, or for multple shifts in separate cards if there are more than 1 regular calendar jobs
          ?
          <div key={`statusCard-single-0`} >
            <JobDetailsStatusCardCalendarJobData
              calendarJob={firstCalJob}
            />
          </div>
          :
          selectedJob.calendarJobs.filter((calendarJob) => (
            calendarJob.jobType !== 'REGULAR'
          )).map((calendarJob, index) => (
            <div key={`statusCard-${index}`} className='p-3 bg-navy-bright/10 border border-navy-light'>
              <div className="mb-2 flex flex-row justify-between">
                <div className='flex items-center'>
                  <img src='/PlannerColumn/ready_for_calendar_icon.svg' alt='Job icon' />
                  <p className='ml-1 text-[14px] font-semibold text-dark-blue'>Scheduled Shoot</p>
                </div>
                {!calendarJob?.jobLog?.submittedAt
                  ?
                  <button
                    className="cursor-pointer"
                    onClick={() => { markCalendarJobDeleted(calendarJob.id) }}
                  >
                    <img
                      src='/JobDetails/trash_icon.svg'
                      alt='trashcan icon'
                    />
                  </button>
                  :
                  <></>
                }
              </div>



              <JobDetailsStatusCardCalendarJobData calendarJob={calendarJob} />
            </div>
          )
          )
        }
        {/* This displays either the "add flash" button or a flash job scheduling card if one is scheduled */}
        {(selectedScheduler?.location?.service === 'GUNITE') ? (<JobDetailsStatusCardFlashing />) : null}
      </div>
    </div>
  );
};

export default JobDetailsStatusCard;
