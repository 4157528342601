import { useEffect, useState, useRef } from "react";
import MiniCalendar from "../MiniCalendar";

const MiniCalendarDropdown = ({selectedDays, setSelectedDays}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownWrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownWrapperRef} >
    <button
    className="ml-2 flex items-center justify-center h-full w-fit p-[8px] py-[5px] rounded-[4px] bg-white border border-navy-light"
    onClick={(e) => {
      e.preventDefault()
      setShowDropdown(!showDropdown)
      }}
    >
      <p className="text-[14px] font-[450] text-dark-blue select-none ">Dates</p>
      <img src={showDropdown ? '/show_less_arrow.svg' : '/show_more_arrow.svg'} alt='show filters' className="lg:pl-[3px] h-[20px] hidden lg:block" />
    </button>

      {showDropdown && (
        <div className="absolute top-[63px] left-8 bg-white border border-navy-light rounded-[8px] shadow-md px-3 pt-2 pb-1">
          <MiniCalendar
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            isMultiDay={true}
            allowSelectPast={true}
            rangeSelection={true}
          />
        </div>

      ) }
    </div>

  )
}

export default MiniCalendarDropdown;