import React, { useState, useEffect } from "react";

const DropdownCheckboxGeneral = ({ columnDetails, title, selectedStatuses, setSelectedStatuses }) => {
  const [showDropdown, setShowDropdown] = useState(false);


  useEffect(() => {
    const handleOutsideClick = (e) => {
      const wrapper = document.getElementById('dropdownWrapper');
      if (showDropdown && wrapper && !wrapper.contains(e.target)) {
        setShowDropdown(false);
      }
    };

    document.body.addEventListener('click', handleOutsideClick);
    return () => document.body.removeEventListener('click', handleOutsideClick);
  }, [showDropdown]);

  const handleOptionClick = (e, itemId) => {
    e.stopPropagation();
    const updatedItemsList = selectedStatuses.includes(itemId)
      ? selectedStatuses.filter((item) => item !== itemId)
      : [...selectedStatuses, itemId];
    setSelectedStatuses(updatedItemsList);
  };

  return (
    <div className='relative' id='dropdownWrapper'>
      <button
        className='flex items-center justify-center h-[40px] w-[40px] lg:h-full lg:w-fit p-[8px] lg:pl-[12px] lg:pr-[8px] lg:py-[5px] rounded-[6px] lg:rounded-[4px] bg-white border border-navy-light'
        onClick={(e) => {
          setShowDropdown(!showDropdown)
        }}
      >
        <p className='text-[14px] font-[600] select-none hidden lg:block'>
          {title}
        </p>

        <img src={showDropdown ? '/show_less_arrow.svg' : '/show_more_arrow.svg'} alt='show filters' className="lg:pl-[6px] h-[20px] hidden lg:block" />

        <img src='/filter_icon.svg' alt='show filters' className="px-[1px] h-[15px] lg:hidden" />

      </button>
      <div className={`absolute top-11 md:right-0 transform -translate-x-32 md:-translate-x-0 z-10 lg:lef flex flex-col h-fit w-[190px] rounded-[8px] border border-navy-light p-[8px] text-[14px] bg-white shadow-md ${showDropdown ? '' : 'hidden'}`}>
      {columnDetails.map((column) => (
          <div
            key={column.id}
            className='flex w-full items-center p-[8px] cursor-pointer'
            onClick={(e) => handleOptionClick(e, column.id)}
          >
            <div className={`flex w-[16px] h-[16px] rounded-[4px] select-none ${selectedStatuses.includes(column.id) ? 'bg-dark-blue' : 'bg-white border'}`}>
              {selectedStatuses.includes(column.id) && <img src='/check.svg' alt='check' className="w-full" />}
            </div>

            <p className='pl-[12px] select-none'>
              {column.title}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownCheckboxGeneral;