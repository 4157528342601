import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import { useModal } from "../../contexts/modal";
import { useScheduler } from "../../contexts/scheduler";
import { formatDate, getJobStatus, allStatusDisplayOptions } from "../../utils"

const JobDetailsStatusCardCalendarJobData = ({calendarJob, flashJob}) => {
  const { selectedJobId, selectedScheduler } = useScheduler();
  const { setShowMiniCalendar, setIsFlash, setSelectedCalendarJobId } = useJobDetailsModal()
  const selectedJob = selectedScheduler.location.jobs.find(item => item.id === selectedJobId)
  const { modalMethods } = useModal()

  const status = getJobStatus(selectedJob, allStatusDisplayOptions);

  const handleScheduleJob = () => {
    if (selectedJob.jobType === 'plaster' ? selectedJob.estimatedIA === 0 : selectedJob.estimatedYards === 0) {
      openHintModal()
      return
    }
    setSelectedCalendarJobId(calendarJob.id)
    if (flashJob) {
      setIsFlash(true)
    } else {
      setIsFlash(false)
    }
    setShowMiniCalendar(true);
  }

  const openHintModal = () => {
    const estimateLabel = selectedJob.jobType === 'plaster' ? 'IA' : 'yards'
    modalMethods.setContent(
      <div className='flex flex-col justify-center items-center bg-white rounded-lg p-8 text-secondary-blue'>
        <p
          className='pb-8 font-semibold text-xl'
        >Estimated {estimateLabel} must be calculated first.</p>
        <button
          className='flex w-fit border-2 border-light-blue rounded px-4 py-1 text-lg font-semibold bg-white'
          onClick={() => {
            modalMethods.close()
          }}
        >OK</button>
      </div>
    )
    modalMethods.open()
  }

  const WordBubble = ({ word }) => (
    <div className='bg-navy-light/50 rounded-full px-2 py-1 flex justify-center items-center gap-1 mb-1 mr-1'>
      <p className='text-dark-blue font-medium text-xs select-none whitespace-nowrap'>{word}</p>
    </div>
  )

  const destructureCrewMemberNames = () => {
    var names = []
    if (calendarJob?.crews?.length) {
      const crew = calendarJob.crews[0].crew;
      const foremanName = crew.foreman?.user.name;
      names.push(foremanName);
    }
    return names;
  }

  const destructureDriverNames = () => {
    var names = []
    if (calendarJob?.drivers?.length) {
      const drivers = calendarJob.drivers;
      for (const driver of drivers) {
        names.push(driver.driver?.user.name);
      }
    }
    return names;
  }


  const makeDateRow = () => {
    if (selectedJob.archivedAt) {
      return (
        <>
          <p className='text-[14px] text-gray-blue'>Completed date:</p>
          <p className='text-[14px] text-gray-blue'>{formatDate(selectedJob.completedAt)}</p>
        </>
      );
    } else if (selectedJob.completedAt) {
      return (
        <>
          <p className='text-[14px] text-gray-blue'>Completed date:</p>
          <p className='text-[14px] text-gray-blue'>{formatDate(selectedJob.completedAt)}</p>
        </>
      );
    } else if (selectedJob.scheduledAt || (calendarJob.calendarJobType === 'FLASHING' && calendarJob.scheduledDate !== null)) {
      return (
        <>
          <p className='text-[14px] text-gray-blue whitespace-nowrap'>Scheduled date:</p>
          <div className='flex flex-wrap'>
            <p className='text-[14px] text-gray-blue'>{calendarJob.scheduledDate ? `${formatDate(calendarJob.scheduledDate)}` : ''}</p>
            <p className='text-[14px] text-gray-blue pl-2'>
              {calendarJob.shift ? `Shoot ${ calendarJob.shift }` : ''}
              {calendarJob.fshift ? `Shoot ${ calendarJob.fshift }` : ''}
            </p>
          </div>
          <EditButton />
        </>
      );
    } else if (selectedJob.readyAt) {
      return (
        <>
          <p className='text-[14px] text-gray-blue'>Requested date:</p>
          <p className='text-[14px] text-gray-blue'>{formatDate(selectedJob.requestedDate)}</p>
          <div className="cursor-pointer flex" onClick={handleScheduleJob}>
            <img src='/JobDetails/calendar_month.svg' alt='Calendar month icon' />
            <p className='text-[14px] font-semibold text-dark-blue'> Schedule job</p>
          </div>

        </>
      );
    } else if (selectedJob.backloggedAt) {
      return (
        <>
          <p className='text-[14px] text-gray-blue'>Requested date:</p>
          <p className='text-[14px] text-gray-blue'>{formatDate(selectedJob.requestedDate)}</p>
          <div className="cursor-pointer flex" onClick={handleScheduleJob}>
            <img src='/JobDetails/calendar_month.svg' alt='Calendar month icon' />
            <p className='text-[14px] font-semibold text-dark-blue'> Schedule job</p>
          </div>
        </>
      );
    } else if (selectedJob.submittedAt) {
      return (
        <>
          <p className='text-[14px] text-gray-blue'>Requested date:</p>
          <p className='text-[14px] text-gray-blue'>{formatDate(selectedJob.requestedDate)}</p>
          <div className="cursor-pointer flex" onClick={handleScheduleJob}>
            <img src='/JobDetails/calendar_month.svg' alt='Calendar month icon' />
            <p className='text-[14px] font-semibold text-dark-blue'> Schedule job</p>
          </div>
        </>
      );
    }
  }

  const EditButton = () => (
      <img
        src='/JobDetails/edit_icon.svg' alt='Edit'
        className='w-[18px] cursor-pointer'
        onClick={handleScheduleJob}
      />
  )


  return (
    <div>
      <div className='flex gap-2 items-start'>
        <img src='/JobDetails/calendar_icon.svg' alt='Calendar icon' />
        {makeDateRow()}
      </div>
      {calendarJob?.crews?.length > 0 && (
        <div className='flex gap-2 items-start py-2'>
          <img src='/JobDetails/crew_icon.svg' alt='Crew icon' />
          <p className='text-[14px] text-gray-blue'>Crew:</p>

          <div className='flex flex-row flex-wrap'>
            {destructureCrewMemberNames().map((name, i) => (
              <WordBubble word={name} key={`${name}-${i}`} />
            ))}
          </div>

          {(status === 'Scheduled') && <EditButton /> }
        </div>
      )} {calendarJob?.drivers?.length > 0 && (
        <div className='flex gap-2 items-start'>
          <img src='/JobDetails/driver_icon.svg' alt='Driver icon' />
          <p className='text-[14px] text-gray-blue'>Drivers:</p>

          <div className='flex flex-row flex-wrap'>
            {destructureDriverNames().map((name, i) => (
              <WordBubble word={name} key={`${name}-${i}`} />
            ))}
          </div>

          {(status === 'Scheduled') && <EditButton /> }
        </div>
      )}
    </div>
  );
};

export default JobDetailsStatusCardCalendarJobData;
