import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { useModal } from "../../contexts/modal";
import SignUpModal from "../SignUpModal";
import SignInModal from '../SignInModal';
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useProgress } from "../../contexts/progress";
import { useResponsive } from "../../contexts/responsive";
import { RxHamburgerMenu } from "react-icons/rx";

function TopBar() {
  const { modalMethods } = useModal()
  const { isLoggedIn, logout } = useAuth()
  const { navigateToStart } = useProgress()
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { isMobileView } = useResponsive();
  const [showMenuItems, setShowMenuItems] = useState(false);

  const handleToggleIntakeHamburger = () => {
    setShowMenuItems(!showMenuItems)
  }

  useEffect(()=>{
    if (!isMobileView){
      setShowMenuItems(false)
    }
  },[isMobileView])

  const menuItemsLoggedIn = {
    'My Jobs': '/customer/myjobs',
    'Drafts': '/customer/drafts',
    'Account': '/customer/account',
    'Logout': '/'
  }

  const OpenHamburgerMenu = () => {
    const [hoveredButton, setHoveredButton] = useState(null);
    const handleMouseEnter = (key) => {
      setHoveredButton(key);
    };
    const handleMouseLeave = () => {
      setHoveredButton(null);
    };

    return (
      showMenuItems && (
        <div className='flex flex-col px-6 py-4 bg-dark-blue'>
          {isLoggedIn ? (
            <>
              {Object.entries(menuItemsLoggedIn).map(([key, value]) => (
                <button
                  key={key}
                  className={`flex justify-center text-white text-[20px] font-medium
                    ${key !== 'Logout' ? 'mb-[24px]' : 'rounded border border-white opacity-100'}`}
                    onClick={() => {
                      handleToggleIntakeHamburger();
                      if (key === 'Logout') {
                        navigate('/');
                        logout();
                      } else {
                        navigate(value);
                      }
                    }}
                  onMouseEnter={() => handleMouseEnter(key)}
                  onMouseLeave={handleMouseLeave}
                >
                  {key}
                </button>
              ))}
            </>
          ) : (
            <div></div>
          )}
        </div>
      )
    );
  };

  return (
    <>
      <div className="flex justify-between px-6 lg:px-20 topbar_outer_container gap-2">
        <div className="topbar_main_logo">
          <button onClick={() => navigateToStart()}>
            <img src="/main-logo.svg" alt="Main Logo" />
          </button>
        </div>

          {isLoggedIn ? (
            <>
              {!isMobileView ? 
              <>
                <div className="flex gap-12">
                  <button
                    className="text-white text-xl font-medium"
                    onClick={()=>navigate('/customer/myjobs')}  
                  >
                    My jobs
                  </button>
                  <button
                    className="text-white text-xl font-medium"
                    onClick={()=>navigate('/customer/drafts')}
                  >
                    Drafts
                  </button>
                  <button 
                    className="text-white text-xl font-medium"
                    onClick={()=>navigate('/customer/account')}
                  >
                    Account
                  </button>
                </div>
                  
                <button className="bg-white font-medium text-00154D
                border border-white rounded py-2 px-1 lg:px-6 lg:py-3 lg:text-xl"
                  onClick={() => {
                    if (!pathname.includes('choose-location')) {
                      navigateToStart()
                    }
                    logout()
                  }}>
                  Sign Out
                </button>            
              </>
              :
              <div onClick={handleToggleIntakeHamburger} className='cursor-pointer overflow-hidden'>
                {!showMenuItems ? (
                  <RxHamburgerMenu onClick={handleToggleIntakeHamburger} className='text-3xl text-white'/>
                ) : (
                  <img src='/x_icon.svg' className='cursor-pointer' />
                )}
              </div>
              }
            </>
          ) : (
            <div className="flex flex-row gap-5">
              <button className="bg-00154D font-medium text-white
                border border-white min-w-[71px] rounded border-2
                py-2 px-1 lg:px-6 lg:py-3 lg:text-xl" onClick={() => {
                  modalMethods.setContent(<SignUpModal />)
                  modalMethods.open()
                }}>
                Sign Up
              </button>
              <button className="bg-white font-medium text-00154D
                border border-white rounded py-2 px-1 lg:py-3 lg:px-6 lg:text-xl"
                onClick={() => {
                  modalMethods.setContent(<SignInModal />)
                  modalMethods.open()
                }}>
                Sign In
              </button>
            </div>
          )}
      </div>
      <div className="relative">
        <OpenHamburgerMenu />
      </div>
    </>
  )
}

export default TopBar;
