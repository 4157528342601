import React, { useState, useEffect } from "react";
import CostBreakdownSelector from "../CostBreakdownSelector";
import { useScheduler } from "../../contexts/scheduler";

const CostBreakdownAddDriver = ({ setAddingActive, add, driversUsed = [], defaultDriver }) => {
  const { allDrivers } = useScheduler()
  const [newMember, setNewMember] = useState('')
  const [displayMembers, setDisplayMembers] = useState([])
  const [disableAdd, setDisableAdd] = useState(false)

  useEffect(() => {
    const unavailable = driversUsed.map((member) => member.name)
    const displayMembers = []
    for (const driver of allDrivers) {
      if (!unavailable.includes(driver.user.name)) {
        const memberObj = {
          ...defaultDriver,
          name: driver.user.name,
          poolRate: driver.poolRate,
        }
        displayMembers.push(memberObj)
      }
    }
    setDisplayMembers(displayMembers)
  }, [])

  const updateState = (e) => {
    const newMember = displayMembers.find((member) => member.name === e.target.value)

    if (!newMember) {
      setNewMember(defaultDriver)
    } else {
      setNewMember(newMember)
    }
  }

  useEffect(() => {
    if (newMember?.name === '' || !newMember?.name) {
      setDisableAdd(true)
    } else {
      setDisableAdd(false)
    }
  }, [newMember?.name])

  return (

    <div className='flex flex-col md:flex-row w-full p-4 mt-4 justify-between items-center rounded border bg-subtle-grey gap-4'>
      <div className='flex flex-col w-full md:w-1/2 gap-4'>
        <div className='flex w-full md:w-3/4 mt-[-8px]'>
          <CostBreakdownSelector
            value={newMember?.name}
            options={displayMembers.map((member) => member.name)}
            placeholder='Driver'
            onChange={(e) => {
              updateState(e)
            }}
          />
        </div>
      </div>

      <div className='flex w-full md:w-1/2 justify-end h-fit gap-4'>
        <button
          className='border border-navy-light rounded bg-white py-2 px-3 font-semibold text-dark-blue hover:text-white hover:bg-gunite-red'
          onClick={() => {
            setAddingActive(false)
          }}
        >
          Cancel
        </button>
        <button
          className={`border border-navy-light rounded py-2 px-4 font-semibold  ${disableAdd ? 'text-opaque-job-details-gray bg-disabled-gray' : 'bg-white text-dark-blue hover:bg-dark-blue hover:text-white'}`}
          disabled={disableAdd}
          onClick={() => {
            setAddingActive(false)
            add(newMember)
          }}
        >
          Add
        </button>
      </div>

    </div>
  );
};

export default CostBreakdownAddDriver;
