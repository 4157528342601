import React, { useState, useEffect, useRef } from "react";

const DropdownRadio = ({ sortBy, setSortBy, i, filterIconHeight = 14, menuTop = 1.75, menuRight = 0 }) => {
  const [showSortMenu, setShowSortMenu] = useState(false);
  const dropdownWrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownWrapperRef.current && !dropdownWrapperRef.current.contains(event.target)) {
        setShowSortMenu(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [showSortMenu]);

  return (
    <div ref={dropdownWrapperRef} id={`dropdownRadioWrapper${i}`}>
      <img
        src='/PlannerColumn/sort_icon.svg'
        alt='sort'
        className="cursor-pointer min-w-[30px] py-[2px] px-[8px]"
        style={{ height: `${filterIconHeight}px` }}
        onClick={(e) => {
          e.preventDefault()
          setShowSortMenu(!showSortMenu)
        }}
      />

      <div
        className={`absolute flex flex-col justify-between h-fit w-fit p-[8px] bg-white border border-navy-light rounded-[8px] shadow-md text-[14px] ${showSortMenu ? '' : 'hidden'}`}
        style={{ right: `${menuRight}rem`, top: `${menuTop}rem` }}
      >
      <div
          className='flex items-center w-[285px] h-[32px] p-[6px]'
          onClick={(e) => {
            e.preventDefault()
            setSortBy('submit-date')
          }}
        >
          <img src={sortBy === 'submit-date' ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px]' />
          <p className='pl-[10px]'>
            Form submit date: from newest
          </p>
        </div>

        <div
          className='flex items-center  w-[285px] h-[32px] p-[6px]'
          onClick={(e) => {
            e.preventDefault()
            setSortBy('request-date')
          }}
        >
          <img src={sortBy === 'request-date' ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px]' />
          <p  className='pl-[10px]'>
            Requested job date: from most urgent
          </p>
        </div>

        <div
          className='flex items-center  w-[285px] h-[32px] p-[6px]'
          onClick={(e) => {
            e.preventDefault()
            setSortBy('job-name')
          }}
        >
          <img src={sortBy === 'job-name' ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px]' />
          <p  className='pl-[10px]'>
            Job name: A to Z
          </p>
        </div>

        <div
          className='flex items-center  w-[285px] h-[32px] p-[6px]'
          onClick={(e) => {
            e.preventDefault()
            setSortBy('builder-name')
          }}
        >
          <img src={sortBy === 'builder-name' ? '/PlannerColumn/radio_checked_icon.svg' : '/PlannerColumn/radio_unchecked_icon.svg'} alt='search' className='h-[16px] w-[16px]' />
          <p  className='pl-[10px]'>
            Poolbuilder: A to Z
          </p>
        </div>

      </div>
    </div>
  )
}

export default DropdownRadio;
