import { useState, useEffect } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useScheduler } from "../../contexts/scheduler";
import { formatDate } from "../../utils";
import { useJobDetailsModal } from "../../contexts/jobDetailsModal";
import { FIFTY_STATES } from '../../constants';
import Selector from "../Selector";
import { useIntakeForm } from "../../contexts/intakeForm";
import DateSelector from "../DateSelector";
import { asrBranches } from "../../utils";
import PhoneInputBackend from "../PhoneInputBackend";

const DataSection = ({
  detailsTabErrorMessages,
  title,
  SectionContents,
  selectedJob,
  selectedScheduler,
  updatedJob,
  setUpdatedJob,
  updatedPool,
  setUpdatedPool,
  hiddenFileInput,
  savePlanToState,
  uploadedFile,
  updatedCustomer,
  setUpdatedCustomer,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mt-4 mb-6">
      <div className="flex justify-between mb-2 mx-2">
        <h3 className="text-md font-bold text-dark-blue">{title}</h3>
        {isExpanded ? (
          <FaChevronUp
            onClick={handleToggleExpand}
            className="cursor-pointer text-dark-blue"
          />
        ) : (
          <FaChevronDown
            onClick={handleToggleExpand}
            className="cursor-pointer text-dark-blue"
          />
        )}
      </div>
      {isExpanded && (
        <SectionContents
          detailsTabErrorMessages={detailsTabErrorMessages}
          updatedJob={updatedJob}
          setUpdatedJob={setUpdatedJob}
          updatedPool={updatedPool}
          setUpdatedPool={setUpdatedPool}
          selectedJob={selectedJob}
          selectedScheduler={selectedScheduler}
          hiddenFileInput={hiddenFileInput}
          savePlanToState={savePlanToState}
          uploadedFile={uploadedFile}
          updatedCustomer={updatedCustomer}
          setUpdatedCustomer={setUpdatedCustomer}
        />
      )}
    </div>
  );
};

const GeneralInformationSection = ({
  selectedJob,
  selectedScheduler,
  updatedJob,
  setUpdatedJob,
}) => {
  const [dateSelectorOpen, setDateSelectorOpen] = useState(false)

  return (
    <div className="flex flex-col divide-y divide-navy-light bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-3 py-1">
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px] rounded-tl-lg rounded-tr-lg`}>
        <span className="text-dark-blue py-1">Service</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[2px] text-gray-blue
          border-navy-light bg-navy-light bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          disabled={true}
          value={selectedJob.jobType === "gunite" ? "Gunite" : "Plaster"}
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">Service Provider</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[2px] text-gray-blue
          border-navy-light bg-navy-light bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          disabled={true}
          value={selectedScheduler.location.label || ""}
        />
      </div>
      {selectedJob.jobType === "gunite" &&
        (asrBranches.includes(selectedJob.locationId)) && (
          <>
            <div className="flex w-full items-center p-[8px] py-[16px] cursor-pointer">
              <input
                type='checkbox'
                id={`asrCertSchedulerJobDetailsEditable`}
                value={!!updatedJob?.guniteType}
                onChange={(e) => {
                  if (updatedJob?.guniteType !== 'ASR Certification') {
                    setUpdatedJob({
                      ...updatedJob,
                      guniteType: 'ASR Certification'
                    })
                  } else {
                    setUpdatedJob({
                      ...updatedJob,
                      guniteType: null
                    })
                  }
                }}
                checked={updatedJob?.guniteType === 'ASR Certification' || false}
              />
              <label className='pl-[12px] text-dark-blue' htmlFor={`asrCertSchedulerJobDetailsEditable`}>
                ASR Certification
              </label>
            </div>


          </>
        )
      }



      <div className={`flex flex-row justify-between items-center py-[11px] rounded-bl-lg rounded-br-lg`}>
        <span className="text-dark-blue py-1">Requested Date</span>
        <div
          className="cursor-pointer flex flex-row items-center justify-center"
          onClick={() => {
            setDateSelectorOpen(!dateSelectorOpen)
          }}
        >
          <span className="text-dark-blue py-1">
            {formatDate(updatedJob.requestedDate || selectedJob.requestedDate) || ""}
          </span>
          <img
            src="/JobDetails/calendar_month.svg"
            alt="calendar"
            className="ml-1 w-[21px]"
          />
        </div>
      </div>

      {dateSelectorOpen && (
        <div className='flex border-none w-full justify-end'>
          <div className='flex flex-col w-[200px]'>
            <DateSelector
              askIfContinueDate={(date) => {
                setUpdatedJob({ ...updatedJob, requestedDate: date })
              }}
              selectedDay={new Date(updatedJob.requestedDate)}
              setSelectedDay={(date) => {
                setUpdatedJob({ ...updatedJob, requestedDate: date })
              }}
            />

            <div className='flex gap-4'>
              <button
                className='mt-[8px] p-[8px] py-[5px] rounded-[4px] bg-white border border-navy-light flex flex-row justify-center items-center w-full'
                onClick={() => {
                  setUpdatedJob({ ...updatedJob, requestedDate: selectedJob.requestedDate })
                  setDateSelectorOpen(false)
                }}
              >
                Cancel
              </button>

              <button
                className='mt-[8px] p-[8px] py-[5px] rounded-[4px] bg-white border border-navy-light flex flex-row justify-center items-center w-full'
                onClick={() => {
                  setDateSelectorOpen(false)
                }}
              >
                OK
              </button>

            </div>
          </div>
        </div>
      )}

    </div>
  );
};

const BuilderInformationSection = ({ selectedJob, updatedCustomer, setUpdatedCustomer }) => {
  return (
    <div className="flex flex-col divide-y divide-navy-light bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-3 py-1">
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px] rounded-tl-lg rounded-tr-lg`}>
        <span className="text-dark-blue py-1">First name</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedCustomer.firstName || ""}
          onChange={(e) => {
            setUpdatedCustomer({ ...updatedCustomer, firstName: e.target.value });
          }}
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">Last name</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedCustomer.lastName || ""}
          onChange={(e) => {
            setUpdatedCustomer({ ...updatedCustomer, lastName: e.target.value });
          }}
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">Business name</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedCustomer.bizName || ""}
          onChange={(e) => {
            setUpdatedCustomer({ ...updatedCustomer, bizName: e.target.value });
          }}
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">Business address</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedCustomer.bizAddress || ""}
          onChange={(e) => {
            setUpdatedCustomer({ ...updatedCustomer, bizAddress: e.target.value });
          }}
        />
      </div>
    </div>
  );
};

const JobSiteInformationSection = ({ updatedJob, setUpdatedJob, detailsTabErrorMessages }) => {
  return (
    <div className="flex flex-col divide-y divide-navy-light bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-3 py-1">
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px] rounded-tl-lg rounded-tr-lg`}>
        <div className="flex flex-row justify-start items-center w-full">
          <p className="text-dark-blue py-1">Job name *</p>
          {detailsTabErrorMessages.find(msg => msg.jobName) && (
            <p className="text-dark-blue py-1 pl-3 text-gunite-red font-medium text-sm">
              {detailsTabErrorMessages.find(msg => msg.jobName).jobName}
            </p>
          )}
        </div>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedJob?.jobName || ""}
          onChange={(e) => {
            setUpdatedJob({ ...updatedJob, jobName: e.target.value });
          }}
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">Job site Address</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedJob?.address || ""}
          onChange={(e) =>
            setUpdatedJob({ ...updatedJob, address: e.target.value })
          }
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">City</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedJob?.city || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (/^[a-zA-Z\s]*$/.test(value)) {
              setUpdatedJob({ ...updatedJob, city: value });
            }
          }}
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">State</span>
        <Selector
          value={updatedJob?.state || ''}
          placeholder={'State *'}
          options={FIFTY_STATES}
          whiteBackground={true}
          onChange={(e) => {
            setUpdatedJob({
              ...updatedJob,
              state: e.target.value
            })
          }}
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">Zip Code</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedJob?.zipCode || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d{0,5}$/.test(value)) {
              setUpdatedJob({ ...updatedJob, zipCode: value });
            }
          }}
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">Gate Code</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedJob?.gateCode || ""}
          onChange={(e) =>
            setUpdatedJob({ ...updatedJob, gateCode: e.target.value })
          }
        />
      </div>
      <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1">Job site notes</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedJob?.notes || ""}
          onChange={(e) =>
            setUpdatedJob({ ...updatedJob, notes: e.target.value })
          }
        />
      </div>
      <div className="flex flex-col justify-between items-start pb-[10px] pt-[3px]">
        <span className="text-dark-blue py-1">Type of job</span>
        <select
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          value={updatedJob?.residentialOrCommercial || ""}
          onChange={(e) =>
            setUpdatedJob({
              ...updatedJob,
              residentialOrCommercial: e.target.value,
            })
          }
        >
          <option value="">Select Type</option>
          <option value="residential">Residential</option>
          <option value="commercial">Commercial</option>
        </select>
      </div>
      <div className="flex w-full items-center p-[8px] py-[16px]">
        <input
          type='checkbox'
          id={'isHudJobSchedulerJobDetailsEditable'}
          value={!!updatedJob?.isHudJob}
          onChange={(e) => {
            setUpdatedJob({
              ...updatedJob,
              isHudJob: !updatedJob?.isHudJob
            })
          }}
          checked={updatedJob?.isHudJob}
        />
        <label className='pl-[12px] text-dark-blue' htmlFor={'isHudJobSchedulerJobDetailsEditable'}>
          Is HUD Job?
        </label>
      </div>
      <div className="flex w-full items-center p-[8px] py-[16px]">
        <input
          type='checkbox'
          id={'electricityCheckboxJobDetailsEditable'}
          value={!!updatedJob?.electricityOnSite}
          onChange={(e) => {
            setUpdatedJob({
              ...updatedJob,
              electricityOnSite: !updatedJob?.electricityOnSite
            })
          }}
          checked={updatedJob?.electricityOnSite}
        />
        <label className='pl-[12px] text-dark-blue' htmlFor={'electricityCheckboxJobDetailsEditable'}>
          Electricity On Site?
        </label>
      </div>
      <div className="flex w-full items-center p-[8px] py-[16px]">
        <input
          type='checkbox'
          id={'waterOnSiteCheckboxJobDetailsEditable'}
          value={!!updatedJob?.waterOnSite}
          onChange={(e) => {
            setUpdatedJob({
              ...updatedJob,
              waterOnSite: !updatedJob.waterOnSite
            })
          }}
          checked={updatedJob?.waterOnSite}
        />
        <label className='pl-[12px] text-dark-blue' htmlFor={'waterOnSiteCheckboxJobDetailsEditable'}>
          Water On Site?
        </label>
      </div>
    </div>
  );
};

const PoolSection = ({ updatedPool, setUpdatedPool, hiddenFileInput,
  savePlanToState, selectedJob, uploadedFile, updatedJob, setUpdatedJob }) => {
  return (
    <div className="flex flex-col divide-y divide-navy-light bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-3 py-1">
      <div className={`flex flex-row justify-between items-center py-[11px] rounded-bl-lg rounded-br-lg`}>
        <span className="text-dark-blue py-1">Pool plan</span>
        {(updatedPool.planUri == null && !uploadedFile) && (
          <span className="cursor-pointer flex flex-row items-center justify-center">
            <img
              src="/JobDetails/add_icon.svg"
              alt="check"
              className="ml-1 w-[21px]"
            />
            <input type='file' className='hidden' onChange={savePlanToState} ref={hiddenFileInput} />
            <p
              onClick={() => {
                hiddenFileInput.current.click()
              }}
              className="ml-1.5 text-dark-blue">
              Add pool plan</p>
          </span>
        )}
        {(updatedPool.planUri != null || uploadedFile) && (
          <span className="flex flex-row items-center justify-center">
            <span className="flex flex-row items-center justify-center ml-1.5 text-dark-blue py-1">
              <img
                src="/JobDetails/draft.svg"
                alt="check"
                className="ml-1 w-[13px]"
              />

              <p className="ml-2 text-dark-blue">{uploadedFile ? uploadedFile.name : updatedPool.planUri}</p>
            </span>

            <input type='file' className='hidden' onChange={savePlanToState} ref={hiddenFileInput} />
            <p
              onClick={() => {
                hiddenFileInput.current.click()
              }}
              className="cursor-pointer ml-4 text-gunite-red">Replace</p>
          </span>
        )}
      </div>

      <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1 lg:mt-[10px]">Square Footage</span>
        <input
          className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
          bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
          type="text"
          placeholder="ft"
          value={updatedPool?.squareFt || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(e.target.value)) {
              setUpdatedPool({ ...updatedPool, squareFt: value });
            }
          }}
        />
      </div>

      <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
        <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Perimeter</span>
        <input
          className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
          bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
          type="text"
          placeholder="ft"
          value={updatedPool?.perimeterFt || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              setUpdatedPool({ ...updatedPool, perimeterFt: value })
            }
          }}
        />
        <input
          className='w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light
            bg-white bg-opacity-90 rounded-lg px-2 py-2 mt-[10px]'
          type="text"
          placeholder="inches"
          value={updatedPool?.perimeterInches || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*\.?\d{0,1}$/.test(value)) {
              setUpdatedPool({ ...updatedPool, perimeterInches: value })
            }
          }}
        />
      </div>
      {selectedJob?.jobType === 'gunite' &&
        <>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px]">Depth</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="ft"
              value={updatedPool?.depth || ""}
              onChange={(e) => {
                setUpdatedPool({
                  ...updatedPool,
                  depth: e.target.value
                });
              }}
            />
          </div>
          <div className="flex w-full items-center p-[8px] py-[16px]">
            <input
              type='checkbox'
              id={'negativeEdgeCheckboxJobDetailsEditable'}
              value={!!updatedPool?.negativeEdge}
              onChange={(e) => {
                setUpdatedPool({
                  ...updatedPool,
                  negativeEdge: !updatedPool?.negativeEdge
                })
              }}
              checked={updatedPool?.negativeEdge}
            />
            <label className='pl-[12px] text-dark-blue' htmlFor={'negativeEdgeCheckboxJobDetailsEditable'}>
              Negative Edge
            </label>
          </div>
          <div className="flex w-full items-center p-[8px] py-[16px]">
            <input
              type='checkbox'
              id={'raisedBeamCheckboxJobDetailsEditable'}
              value={!!updatedPool?.raisedBeam}
              onChange={(e) => {
                setUpdatedPool({
                  ...updatedPool,
                  raisedBeam: !updatedPool?.raisedBeam
                })
              }}
              checked={updatedPool?.raisedBeam}
            />
            <label className='pl-[12px] text-dark-blue' htmlFor={'raisedBeamCheckboxJobDetailsEditable'}>
              Raised Beam
            </label>
          </div>
          <div className="flex w-full items-center p-[8px] py-[16px]">
            <input
              type='checkbox'
              id={'retainingWallsCheckboxJobDetailsEditable'}
              value={!!updatedPool?.retainingWalls}
              onChange={(e) => {
                setUpdatedPool({
                  ...updatedPool,
                  retainingWalls: !updatedPool?.retainingWalls
                })
              }}
              checked={updatedPool?.retainingWalls}
            />
            <label className='pl-[12px] text-dark-blue' htmlFor={'retainingWallsCheckboxJobDetailsEditable'}>
              Retaining Walls
            </label>
          </div>
          <div className="flex w-full items-center p-[8px] py-[16px]">
            <input
              type='checkbox'
              id={'notchCheckboxJobDetailsEditable'}
              value={!!updatedPool?.notch}
              onChange={(e) => {
                setUpdatedPool({
                  ...updatedPool,
                  notch: !updatedPool?.notch
                })
              }}
              checked={updatedPool?.notch}
            />
            <label className='pl-[12px] text-dark-blue' htmlFor={'notchCheckboxJobDetailsEditable'}>
              Notch
            </label>
          </div>

          {asrBranches.includes(selectedJob.locationId) && (
          <>
            <div className="flex w-full items-center p-[8px] py-[16px] cursor-pointer">
              <input
                type='checkbox'
                id={`waterproofingCheckboxJobDetailsTabEditable`}
                value={!!updatedJob?.waterproofing}
                onChange={(e) => {
                  setUpdatedJob({
                    ...updatedJob,
                    waterproofing: !updatedJob?.waterproofing
                  })
                }}
                checked={updatedJob?.waterproofing}
              />
              <label className='pl-[12px] text-dark-blue' htmlFor={`waterproofingCheckboxJobDetailsTabEditable`}>
                Waterproofing
              </label>
            </div>


          </>
        )
      }
        </>
      }

      {selectedJob?.jobType === 'plaster' &&
        <>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Length</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="ft"
              value={updatedPool?.lengthFt || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, lengthFt: value })
                }
              }}
            />
            <input
              className='w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light
                bg-white bg-opacity-90 rounded-lg px-2 py-2 mt-[10px]'
              type="text"
              placeholder="inches"
              value={updatedPool?.lengthInches || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, lengthInches: value })
                }
              }}
            />
          </div>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px] `}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Width</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="ft"
              value={updatedPool?.widthFt || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, widthFt: value })
                }
              }}
            />
            <input
              className='w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light
                bg-white bg-opacity-90 rounded-lg px-2 py-2 mt-[10px]'
              type="text"
              placeholder="inches"
              value={updatedPool?.widthInches || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, widthInches: value })
                }
              }}
            />
          </div>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Deep End</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="ft"
              value={updatedPool?.deepEndFt || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, deepEndFt: value })
                }
              }}
            />
            <input
              className='w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light
                bg-white bg-opacity-90 rounded-lg px-2 py-2 mt-[10px]'
              type="text"
              placeholder="inches"

              value={updatedPool?.deepEndInches || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, deepEndInches: value })
                }
              }}
            />
          </div>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Shallow End</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="ft"
              value={updatedPool?.shallowEndFt || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, shallowEndFt: value })
                }
              }}
            />
            <input
              className='w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light
                bg-white bg-opacity-90 rounded-lg px-2 py-2 mt-[10px]'
              type="text"
              placeholder="inches"
              value={updatedPool?.shallowEndInches || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, shallowEndInches: value })
                }
              }}
            />
          </div>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px]">Tanning Ledge</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="ft"
              value={updatedPool?.tanningLedgeFt || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, tanningLedgeFt: value })
                }
              }}
            />
            <input
              className='w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light
                bg-white bg-opacity-90 rounded-lg px-2 py-2 mt-[10px]'
              type="text"
              placeholder="inches"
              value={updatedPool?.tanningLedgeInches || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, tanningLedgeInches: value })
                }
              }}
            />
          </div>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Bench</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="ft"
              value={updatedPool?.benchFt || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, benchFt: value })
                }
              }}
            />
            <input
              className='w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light
                bg-white bg-opacity-90 rounded-lg px-2 py-2 mt-[10px]'
              type="text"
              placeholder="inches"
              value={updatedPool?.benchInches || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, benchInches: value })
                }
              }}
            />
          </div>

          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Steps</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="number of steps"
              value={updatedPool?.steps || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, steps: e.target.value })
                }
              }
              }
            />
          </div>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Waterfall pad</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="number of waterfall pads"
              value={updatedPool?.waterfall || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, waterfall: e.target.value })
                }
              }
              }
            />
          </div>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Barstools</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="number of barstools"
              value={updatedPool?.barstools || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, barstools: e.target.value })
                }
              }
              }
            />
          </div>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Drain Covers</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="number of drain covers"
              value={updatedPool?.drainCovers || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, drainCovers: e.target.value })
                }
              }
              }
            />
          </div>
          <div className={`flex flex-col lg:flex-row justify-between items-start pb-[10px] pt-[3px]`}>
            <span className="text-dark-blue py-1 lg:mt-[10px] lg:min-w-[105px]">Fittings</span>
            <input
              className="w-full lg:w-1/3 border border-[1px] text-dark-blue border-navy-light bg-white
              bg-opacity-90 rounded-lg px-2 py-2 lg:mt-[10px]"
              type="text"
              placeholder="number of fittings"
              value={updatedPool?.fittings || ""}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d{0,1}$/.test(value)) {
                  setUpdatedPool({ ...updatedPool, fittings: e.target.value })
                }
              }
              }
            />
          </div>


          <div className="flex w-full items-center p-[8px] py-[16px]">
            <input
              type='checkbox'
              id={'basinCheckboxJobDetailsEditable'}
              value={!!updatedPool?.basin}
              onChange={(e) => {
                setUpdatedPool({
                  ...updatedPool,
                  basin: !updatedPool?.basin
                })
              }}
              checked={updatedPool?.basin}
            />
            <label className='pl-[12px] text-dark-blue' htmlFor={'basinCheckboxJobDetailsEditable'}>
              Basin
            </label>
          </div>
        </>
      }

      <div className="flex w-full items-center p-[8px] py-[16px]">
        <input
          type='checkbox'
          id={'spaCheckboxJobDetailsEditable'}
          value={!!updatedPool?.spa}
          onChange={(e) => {
            setUpdatedPool({
              ...updatedPool,
              spa: !updatedPool?.spa
            })
          }}
          checked={updatedPool?.spa}
        />
        <label className='pl-[12px] text-dark-blue' htmlFor={'spaCheckboxJobDetailsEditable'}>
          Spa
        </label>
      </div>
    </div>
  );
};

const PlasterSection = ({ updatedPool, setUpdatedPool, selectedJob }) => {
  const { materialDropDownList, setColorList, colorMap } = useIntakeForm();
  useEffect(() => {
    if (updatedPool?.material) {
      const productNameWords = updatedPool?.material?.split(' -- ')
      const productName = productNameWords[productNameWords.length - 1]
      const colorChoicesArray = colorMap[productName]
      setColorList(colorChoicesArray)
    }
  }, [])

  const filteredColorMapOptions = (colorMap, searchString) => {
    let flattenedValues;
    if (searchString) {
      const productNameWords = searchString.split(' -- ')
      const productName = productNameWords[productNameWords.length - 1]
      const filteredEntries = Object.entries(colorMap).filter(([key]) => key.includes(productName));
      const filteredValues = filteredEntries.map(([_, value]) => value);
      flattenedValues = filteredValues.flat();
      flattenedValues.push('Other')
    } else {
      flattenedValues = [];
    }
    return flattenedValues;
  }

  return (
    <>
      <div className="flex flex-col divide-y divide-navy-light bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-3 py-1">
        {selectedJob.jobType === 'gunite' &&
          <div className="flex flex-col mt-6 mb-6">
            <div>
              <input
                type="radio"
                id={`noPlasterJobDetailsTabEditable`}
                className="mt-[-2px]"
                value={!!updatedPool?.guniteWPlaster}
                onChange={(e) => {
                  setUpdatedPool({
                    ...updatedPool,
                    guniteWPlaster: false,
                    newOrReplaster: null,
                    material: null,
                    materialAddIns: null,
                    color: null,
                    estimateNeeded: false
                  })
                }}
                checked={!updatedPool?.guniteWPlaster}
              />
              <label htmlFor={`noPlasterJobDetailsTabEditable`} className="text-secondary-blue/60 text-base font-semibold ml-[10px]">
                Do not add plaster service
              </label>
            </div>
            <div>
              <input
                type="radio"
                id={`addPlasterJobDetailsTabEditable`}
                className="mt-[-2px]"
                value={!!updatedPool?.guniteWPlaster}
                onChange={(e) => {
                  setUpdatedPool({
                    ...updatedPool,
                    guniteWPlaster: true,
                  })
                }}
                checked={!!updatedPool?.guniteWPlaster}
              />
              <label htmlFor={`addPlasterJobDetailsTabEditable`} className="text-secondary-blue/60 text-base font-semibold ml-[10px]">
                Add Plaster Service
              </label>
            </div>
          </div>
        }

        {(updatedPool?.guniteWPlaster || selectedJob?.jobType === 'plaster') &&
          <div className='pb-2'>
            <Selector
              value={updatedPool?.newOrReplaster || ''}
              placeholder={'New Pool or Re-Plaster?'}
              options={['New Pool', 'Re-Plaster']}
              whiteBackground={true}
              onChange={(e) => {
                if (!e.target.value) {
                  setUpdatedPool({
                    ...updatedPool,
                    newOrReplaster: ''
                  })
                } else {
                  setUpdatedPool({
                    ...updatedPool,
                    newOrReplaster: e.target.value
                  })
                }
              }}
            />
          </div>
        }

        <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
          <Selector
            value={updatedPool?.material || ''}
            placeholder='Material *'
            options={materialDropDownList}
            whiteBackground={updatedPool.newOrReplaster}
            onChange={(e) => {
              setUpdatedPool({
                ...updatedPool,
                material: e.target.value
              });
              const productNameWords = e.target.value.split(' -- ')
              const productName = productNameWords[productNameWords.length - 1]
              const colorChoicesArray = colorMap[productName]
              setColorList(colorChoicesArray)
              if (!e.target.value) {
                setUpdatedPool({
                  ...updatedPool,
                  material: null,
                  color: null
                })
                setColorList([])
              }
            }}
            disabled={!updatedPool.newOrReplaster}
          />
        </div>
        <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
          <Selector
            value={updatedPool?.color || ''}
            placeholder={'Color *'}
            options={filteredColorMapOptions(colorMap, updatedPool?.material)}
            whiteBackground={updatedPool?.material}
            onChange={(e) => {
              setUpdatedPool({
                ...updatedPool,
                color: e.target.value
              });
            }}
            disabled={!updatedPool?.material || !updatedPool?.newOrReplaster}
          />
        </div>

        <div className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}>
          <span className="text-dark-blue py-1">Material Add Ins</span>
          <input
            className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
            border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
            type="text"

            value={updatedPool?.materialAddIns || ""}
            onChange={(e) =>
              setUpdatedPool({ ...updatedPool, materialAddIns: e.target.value })
            }
          />
        </div>

        <div className="flex w-full items-center p-[8px] py-[16px]">
          <input
            type='checkbox'
            id={'estimateCheckboxJobDetailsEditable'}
            value={!!updatedPool?.estimateNeeded}
            onChange={(e) => {
              setUpdatedPool({
                ...updatedPool,
                estimateNeeded: !updatedPool?.estimateNeeded
              })
            }}
            checked={!!updatedPool?.estimateNeeded}
          />
          <label className='pl-[12px] text-dark-blue' htmlFor={'estimateCheckboxJobDetailsEditable'}>
            Estimate Needed
          </label>
        </div>

        {selectedJob?.jobType === 'plaster' && (
          <div className="flex w-full items-center p-[8px] py-[16px]">
            <input
              type='checkbox'
              id={'weWillPrepPoolCheckboxJobDetailsEditable'}
              value={!!updatedPool?.weWillPrepPool}
              onChange={(e) => {
                setUpdatedPool({
                  ...updatedPool,
                  weWillPrepPool: !updatedPool?.weWillPrepPool
                })
              }}
              checked={!!updatedPool?.weWillPrepPool}
            />
            <label className='pl-[12px] text-dark-blue' htmlFor={'weWillPrepPoolCheckboxJobDetailsEditable'}>
              Pool Prep Needed
            </label>
          </div>
        )}

        {selectedJob?.jobType === 'gunite' && (
          <div className="flex w-full items-center p-[8px] py-[16px]">
            <input
              type='checkbox'
              id={'poolIsDugOutCheckboxJobDetailsEditable'}
              value={!!updatedPool?.poolIsDugOut}
              onChange={(e) => {
                setUpdatedPool({
                  ...updatedPool,
                  poolIsDugOut: !updatedPool?.poolIsDugOut
                })
              }}
              checked={!!updatedPool?.poolIsDugOut}
            />
            <label className='pl-[12px] text-dark-blue' htmlFor={'poolIsDugOutCheckboxJobDetailsEditable'}>
              Pool is dug out
            </label>
          </div>
        )}

        {selectedJob?.jobType === 'gunite' && (
          <div className="flex w-full items-center p-[8px] py-[16px]">
            <input
              type='checkbox'
              id='plumbingIsDoneCheckboxJobDetailsEditable'
              value={!!updatedPool?.plumbingIsDone}
              onChange={(e) => {
                setUpdatedPool({
                  ...updatedPool,
                  plumbingIsDone: !updatedPool?.plumbingIsDone
                })
              }}
              checked={!!updatedPool?.plumbingIsDone}
            />
            <label className='pl-[12px] text-dark-blue' htmlFor={'plumbingIsDoneCheckboxJobDetailsEditable'}>
              Pool plumbing is done
            </label>
          </div>
        )}

      </div>
    </>
  );
};

const SupervisorSection = ({ updatedJob, setUpdatedJob }) => {
  return (
    <div className="flex flex-col divide-y divide-navy-light bg-opacity-10 bg-navy-bright rounded-lg border border-navy-light px-3 py-1">
      <div
        className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}
      >
        <span className="text-dark-blue py-1">First name</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedJob?.supervisorFName || ""}
          onChange={(e) =>
            setUpdatedJob({ ...updatedJob, supervisorFName: e.target.value })
          }
        />
      </div>
      <div
        className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}
      >
        <span className="text-dark-blue py-1">Last name</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedJob?.supervisorLName || ""}
          onChange={(e) =>
            setUpdatedJob({ ...updatedJob, supervisorLName: e.target.value })
          }
        />
      </div>
      <div
        className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}
      >
        <span className="text-dark-blue py-1">Phone number</span>
        <PhoneInputBackend
          value={updatedJob?.supervisorPhone || ""}
          placeholder={''}
          onChange={(e) => {
            setUpdatedJob({
              ...updatedJob,
              supervisorPhone: e.target.value
            })
          }}
          jobDetails={true}
        />
      </div>
      <div
        className={`flex flex-col justify-between items-start pb-[10px] pt-[3px]`}
      >
        <span className="text-dark-blue py-1">Email</span>
        <input
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          type="text"
          value={updatedJob?.supervisorEmail || ""}
          onChange={(e) =>
            setUpdatedJob({ ...updatedJob, supervisorEmail: e.target.value })
          }
        />
      </div>

      <div className="flex flex-col justify-between items-start pb-[10px] pt-[3px]">
        <span className="text-dark-blue py-1">Notification method</span>
        <select
          className="focus:border-navy-light focus:ring-0 w-full border border-[1px] text-dark-blue
          border-navy-light bg-white bg-opacity-90 rounded-lg px-2 py-2"
          value={updatedJob?.notificationMethod || ""}
          onChange={(e) =>
            setUpdatedJob({
              ...updatedJob,
              notificationMethod: e.target.value,
            })
          }
        >
          <option value="">Select Method</option>
          <option value="Phone">Phone</option>
          <option value="Email">Email</option>
          <option value="SMS">SMS</option>
        </select>
      </div>

    </div>
  );
};

const JobDetailsDetailsTabEditable = () => {
  const { selectedJobId, selectedScheduler } = useScheduler();
  const selectedJob = selectedScheduler.location.jobs.find(
    (item) => item.id === selectedJobId
  );
  const { hiddenFileInput, savePlanToState, uploadedFile, handleSaveDetailsTab, handleCancelDetailsTab, detailsTabErrorMessages, detailsTabEditing, updatedJob, setUpdatedJob, updatedPool, setUpdatedPool, updatedCustomer, setUpdatedCustomer } = useJobDetailsModal();

  useEffect(() => {
    if (!detailsTabEditing) {
      setUpdatedJob({
        residentialOrCommercial: selectedJob.residentialOrCommercial,
        jobName: selectedJob.jobName,
        address: selectedJob.address,
        city: selectedJob.city,
        state: selectedJob.state,
        zipCode: selectedJob.zipCode,
        gateCode: selectedJob.gateCode,
        notes: selectedJob.notes,
        isHudJob: selectedJob.isHudJob,
        electricityOnSite: selectedJob.electricityOnSite,
        waterOnSite: selectedJob.waterOnSite,
        supervisorFName: selectedJob.supervisorFName,
        supervisorLName: selectedJob.supervisorLName,
        supervisorPhone: selectedJob.supervisorPhone,
        supervisorEmail: selectedJob.supervisorEmail,
        guniteType: selectedJob.guniteType,
        waterproofing: selectedJob.waterproofing
      });

      const pool = selectedJob.pools?.[0];

      setUpdatedPool({
        planUri: pool?.planUri,
        guniteWPlaster: pool?.guniteWPlaster,
        perimeterFt: pool?.perimeterFt,
        perimeterInches: pool?.perimeterInches,
        lengthFt: pool?.lengthFt,
        lengthInches: pool?.lengthInches,
        widthFt: pool?.widthFt,
        widthInches: pool?.widthInches,
        deepEndFt: pool?.deepEndFt,
        deepEndInches: pool?.deepEndInches,
        shallowEndFt: pool?.shallowEndFt,
        shallowEndInches: pool?.shallowEndInches,
        squareFt: pool?.squareFt,
        depth: pool?.depth,
        tanningLedgeFt: pool?.tanningLedgeFt,
        tanningLedgeInches: pool?.tanningLedgeInches,
        benchFt: pool?.benchFt,
        benchInches: pool?.benchInches,
        barstools: pool?.barstools,
        drainCovers: pool?.drainCovers,
        fittings: pool?.fittings,
        steps: pool?.steps,
        spa: pool?.spa,
        basin: pool?.basin,
        raisedBeam: pool?.raisedBeam,
        retainingWalls: pool?.retainingWalls,
        notch: pool?.notch,
        negativeEdge: pool?.negativeEdge,
        newOrReplaster: pool?.newOrReplaster,
        material: pool?.material,
        color: pool?.color,
        estimateNeeded: pool?.estimateNeeded,
        materialAddIns: pool?.materialAddIns,
        poolIsDugOut: pool?.poolIsDugOut,
        plumbingIsDone: pool?.plumbingIsDone,
        weWillPrepPool: pool?.weWillPrepPool,
        waterfall: pool?.waterfall
      });

      setUpdatedCustomer({
        id: selectedJob?.owner?.customer?.id,
        firstName: selectedJob?.owner?.customer?.firstName,
        lastName: selectedJob?.owner?.customer?.lastName,
        bizName: selectedJob?.owner?.customer?.bizName,
        bizAddress: selectedJob?.owner?.customer?.bizAddress
      })
    }
  }, [selectedJobId, selectedScheduler]);

  return (
    <>
      <div className="flex flex-row justify-end items-center">
        <button
          onClick={() => handleCancelDetailsTab()}
          className="ml-2 p-[8px] py-[5px] rounded-[4px] bg-white border border-navy-light flex flex-row justify-center items-center w-full"
        >
          <p className="text-[12px] font-[450] text-dark-blue select-none">
            Cancel
          </p>
        </button>
        <button
          onClick={() => handleSaveDetailsTab({ updatedJob, updatedPool, updatedCustomer })}
          className="ml-2 bg-dark-blue p-[8px] py-[5px] rounded-[4px] border border-navy-light flex flex-row justify-center items-center w-full"
        >
          <p className="text-[12px] font-[450] text-white select-none">
            Save
          </p>
        </button>
      </div>

      {Object.keys(selectedJob).length > 0 && (
        <>
          <DataSection
            detailsTabErrorMessages={detailsTabErrorMessages}
            selectedJob={selectedJob}
            updatedJob={updatedJob}
            setUpdatedJob={setUpdatedJob}
            updatedPool={updatedPool}
            setUpdatedPool={setUpdatedPool}
            title="General information"
            SectionContents={GeneralInformationSection}
            selectedScheduler={selectedScheduler}
            hiddenFileInput={hiddenFileInput}
            savePlanToState={savePlanToState}
            uploadedFile={uploadedFile}
            updatedCustomer={updatedCustomer}
            setUpdatedCustomer={setUpdatedCustomer}
          />
          <DataSection
            detailsTabErrorMessages={detailsTabErrorMessages}
            selectedJob={selectedJob}
            updatedJob={updatedJob}
            setUpdatedJob={setUpdatedJob}
            updatedPool={updatedPool}
            setUpdatedPool={setUpdatedPool}
            title="Builder information"
            SectionContents={BuilderInformationSection}
            hiddenFileInput={hiddenFileInput}
            savePlanToState={savePlanToState}
            uploadedFile={uploadedFile}
            updatedCustomer={updatedCustomer}
            setUpdatedCustomer={setUpdatedCustomer}
          />
          <DataSection
            detailsTabErrorMessages={detailsTabErrorMessages}
            selectedJob={selectedJob}
            updatedJob={updatedJob}
            setUpdatedJob={setUpdatedJob}
            updatedPool={updatedPool}
            setUpdatedPool={setUpdatedPool}
            title="Job site information"
            SectionContents={JobSiteInformationSection}
            hiddenFileInput={hiddenFileInput}
            savePlanToState={savePlanToState}
            uploadedFile={uploadedFile}
          />
          <DataSection
            detailsTabErrorMessages={detailsTabErrorMessages}
            selectedJob={selectedJob}
            updatedJob={updatedJob}
            setUpdatedJob={setUpdatedJob}
            updatedPool={updatedPool}
            setUpdatedPool={setUpdatedPool}
            title="Pool"
            SectionContents={PoolSection}
            hiddenFileInput={hiddenFileInput}
            savePlanToState={savePlanToState}
            uploadedFile={uploadedFile}
          />
          <DataSection
            detailsTabErrorMessages={detailsTabErrorMessages}
            selectedJob={selectedJob}
            updatedJob={updatedJob}
            setUpdatedJob={setUpdatedJob}
            updatedPool={updatedPool}
            setUpdatedPool={setUpdatedPool}
            title="Plaster"
            SectionContents={PlasterSection}
            hiddenFileInput={hiddenFileInput}
            savePlanToState={savePlanToState}
            uploadedFile={uploadedFile}
          />
          <DataSection
            detailsTabErrorMessages={detailsTabErrorMessages}
            selectedJob={selectedJob}
            updatedJob={updatedJob}
            setUpdatedJob={setUpdatedJob}
            updatedPool={updatedPool}
            setUpdatedPool={setUpdatedPool}
            title="Supervisor"
            SectionContents={SupervisorSection}
            hiddenFileInput={hiddenFileInput}
            savePlanToState={savePlanToState}
            uploadedFile={uploadedFile}
          />
        </>
      )}
      <div className="flex flex-row justify-end items-center">
        <button
          onClick={() => handleCancelDetailsTab()}
          className="ml-2 p-[8px] py-[5px] rounded-[4px] bg-white border border-navy-light flex flex-row justify-center items-center w-full"
        >
          <p className="text-[12px] font-[450] text-dark-blue select-none">
            Cancel
          </p>
        </button>
        <button
          onClick={() => handleSaveDetailsTab({ updatedJob, updatedPool, updatedCustomer })}
          className="ml-2 bg-dark-blue p-[8px] py-[5px] rounded-[4px] border border-navy-light flex flex-row justify-center items-center w-full"
        >
          <p className="text-[12px] font-[450] text-white select-none">
            Save
          </p>
        </button>
      </div>
      <div className='flex pt-1 justify-end'>
        <div className='text-gunite-red font-medium text-xs'>
          {detailsTabErrorMessages.length > 0 && '*Some fields need to be edited'}
        </div>
      </div>
    </>
  );
};

export default JobDetailsDetailsTabEditable;
